import _ from 'lodash';
import noticeIcn from 'assets/module/icn-notice@3x.png';
import productGroupIcn from 'assets/module/icn-group@3x.png';
import lotteryIcn from 'assets/module/icn-lottery@3x.png'
import wechatIcn from 'assets/module/icn-wechat-friend@3x.png'
import bannerIcn from 'assets/module/icn-banner@3x.png'
import liveIcn from 'assets/module/icn-live@3x.png'
import ticketIcn from 'assets/module/icn-ticket@3x.png'
import goodsIcn from 'assets/module/icn-all-goods@3x.png'
import categoryIcon from 'assets/module/icn-sort@3x.png';
import videoIcon from 'assets/module/icn-video@3x.png';
import shareIcon from 'assets/module/icn-share@3x.png';
import cutdownIcon from 'assets/module/icn-cutdown@3x.png';
import memberIcon from 'assets/module/icn-vip@3x.png';
import countdownIcon from 'assets/module/icn-cutdown-limit@3x.png';
const countdownPreviewIcn = new Image();
countdownPreviewIcn.src = '/modules/icn-cutdown-limit@3x.png';
const memberPreviewIcn = new Image();
memberPreviewIcn.src = '/modules/icn-list-vip@3x.png';
const cutdownPrevIcn = new Image();
cutdownPrevIcn.src = '/modules/icn-list-cutdown@3x.png';
const noticePrevIcn = new Image();
noticePrevIcn.src = '/modules/icn-list-notice@3x.png';
const recommendPrevIcn = new Image();
recommendPrevIcn.src = '/modules/icn-list-recommend@3x.png';
const lotteryPrevIcn = new Image();
lotteryPrevIcn.src = '/modules/icn-list-lottery@3x.png';
const wechatPrevIcn = new Image();
wechatPrevIcn.src = '/modules/icn-list-addwechat@3x.png';
const videoPrevIcn = new Image();
videoPrevIcn.src = '/modules/icn-list-video@3x.png';
const sharePrevIcn = new Image();
sharePrevIcn.src = '/modules/icn-list-share@3x.png';
const bannerPrevIcn = new Image();
bannerPrevIcn.src = '/modules/icn-list-banner@3x.png';
const livePrevIcn = new Image();
livePrevIcn.src = '/modules/icn-list-live@3x.png';
const ticketPrevIcn = new Image();
ticketPrevIcn.src = '/modules/icn-list-tickets@3x.png';
const goodsPrevIcn = new Image();
goodsPrevIcn.src = '/modules/icn-list-allgoods@3x.png';
const categoryPrevIcon = new Image();
categoryPrevIcon.src = '/modules/icn-list-sort@3x.png';

export const MODULES = [
  {
    id: 'banner',
    icn: bannerIcn,
    prevIcn: bannerPrevIcn,
    name: "Banner",
    defaultForm: {
      type: 1,
      banners: [{
        image: '',
        type: 'product',
      }],
      up_banners: [{
        banners:[{
          image: '',
          type: 'product',
        }],
      }],
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'product_group',
    icn: productGroupIcn,
    prevIcn: recommendPrevIcn,
    name: '商品组',
    defaultForm: {
      products: [],
      type: 1, // 1 宫格展示 2 排行榜展示
      title: '店长推荐',
      style: {
        background_color: '#ffffff'
      },
      config: {
        per_line_num: 2, // 每行展示数量
        line_num: 2, // 首页展示行数 0 为 无限制
        show_buy: false, // 展示购买按钮
        show_rank: true, // 展示排行标签
      }
    }
  },
  {
    id: 'category',
    icn: categoryIcon,
    prevIcn: categoryPrevIcon,
    name: '商品分类',
    defaultForm: {
      type: 2,
      styleType: 't1',
      data: {
        categories: [{
          name: "",
          image: "",
          products: [],
          navigation: {
            type: 'products',
            data: ''
          }
        }]
      },
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'add_wechat',
    icn: wechatIcn,
    prevIcn: wechatPrevIcn,
    name: "加微信好友",
    defaultForm: {
      desc: '店主想和你成为好友',
      content: '加好友进群有专属福利呦~',
      contact: {
        text: '',
        qrcode: ''
      },
      type: 1,
      _method: 'text',
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'video',
    icn: videoIcon,
    prevIcn: videoPrevIcn,
    name: "视频",
    defaultForm: {
      url: '',
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'notice',
    icn: noticeIcn,
    prevIcn: noticePrevIcn,
    name: "公告栏",
    defaultForm: {
      style: 'default',
      content: '',
      module_style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'share',
    icn: shareIcon,
    prevIcn: sharePrevIcn,
    name: "分享",
    defaultForm: {
      share_cover: 'https://resource.nocode.com/upload/20210121/396aac16-abf6-40d4-9f40-e49b26cbaf23',
      desc: '本店刚刚上新，欢迎大家进店选购~',
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'lottery',
    icn: lotteryIcn,
    prevIcn: lotteryPrevIcn,
    name: "福利抽奖",
    category: 2,
    defaultForm: {
      draw_type: 1,
      image: 'https://ministore-cdn.nocode.com/prod/goods/upload/81bf2vGUtFH_ACVFR_1606374356.png',
      latest_draw_time: '',
      par_cnt: 1,
      share_par_cnt: 5,
      prizes: [{
        type: 1,
        name: '',
        level: 1,
        prize_count: undefined,
        win_rate: undefined
      }],
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'flash_cut',
    icn: cutdownIcon,
    prevIcn: cutdownPrevIcn,
    name: '福利砍价',
    category: 2,
    defaultForm: {
      title: '福利砍价',
      end_at: '',
      share_cnt: 2, // 邀请再砍一刀人数 允许值[0, 2]
      coupon_day: 3, // 优惠券有效天数
      products: [{
        max_cut: 0
      }],
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'member',
    icn: memberIcon,
    prevIcn: memberPreviewIcn,
    name: "会员",
    category: 2,
    defaultForm: {
      title: '店主邀你成为会员，享以下权益：',
      desc: '',
      auto_pass: false,
      valid_day: '',
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'discount',
    icn: countdownIcon,
    prevIcn: countdownPreviewIcn,
    name: '限时抢购',
    category: 2,
    defaultForm: {
      products: [],
      style: {
        module_color: '#ffffff'
      }
    }
  },
  {
    id: 'coupon',
    icn: ticketIcn,
    prevIcn: ticketPrevIcn,
    name: "优惠券",
    category: 2,
    defaultForm: {
      coupons: [{
        type: 1,
        trigger: '',
        cut: ''
      }],
      style: {
        background_color: '#ec4b30',
        module_color: '#ffffff'
      }
    }
  },
  {
    id: 'live',
    icn: liveIcn,
    prevIcn: livePrevIcn,
    name: "店铺直播",
    category: 2,
    defaultForm: {
      end_at: '',
      started_at: '',
      style: {
        background_color: '#ffffff'
      }
    }
  },
  {
    id: 'products',
    prevIcn: goodsPrevIcn,
    icn: goodsIcn,
    name: "全部商品",
    defaultForm: {
      style: {
        background_color: '#ffffff'
      }
    }
  }
];

let moduleId = 1;

export function newModule(id) {
  const m = MODULES.find((item) => {
    return item.id === id;
  })
  if (!m) {
    throw new Error(`module ${id} not found.`)
  }
  const module = {
    module: m,
    id: `m-${moduleId++}`,
    formData: m.defaultForm ? _.cloneDeep(m.defaultForm) : {},
    defaultForm: m.defaultForm,
    saved: false,
    hasUnsaved: false,
    editData: {},
    active: false
  }
  return module;
}

export function checkFormDataError(id, formData) {
  let error = '';
  if (id === 'discount') {
    if (_.get(formData, 'products', []).length === 0) {
      error = '请添加商品'
    }
  }
  if (id === 'notice') {
    if (!formData.content) {
      error = '请输入公告内容';
    }
  }
  if (id === 'video') {
    if (!formData.url) {
      error = '请上传视频';
    }
  }
  if (id === 'lottery') {
    if (!formData.latest_draw_time) {
      error = '请选择结束时间';
    }
    if (!formData.image) {
      error = '请添加奖品封面';
    }
    let rate = 0;
    formData.prizes.forEach((prize) => {
      if (!prize.prize_count) {
        error = '请输入奖品数量';
      }
      if (!prize.win_rate) {
        error = '请输入中奖率';
      } else {
        rate += prize.win_rate;
      }
      if (prize.type === 1) {
        if (!_.get(prize, 'prize_info.coupon')) {
          error = '请绑定优惠券'
        }
      } else if (prize.type === 2) {
        if (!_.get(prize, 'prize_info.wechat')) {
          error = '请输入您的微信号'
        }
      }
    })
    if (rate > 100) {
      error = '综合中奖率不能超过 100%'
    }
    if (formData.prizes.length === 0) {
      error = '奖品不能为空'
    }
    if (formData.prizes.length > 3) {
      error = '奖项不能超过 3 个'
    }
  }
  if (id === 'recommend') {
    if (formData.products.length === 0) {
      error = '请勾选要推荐的商品'
    }
  }
  if (id === 'add_wechat') {
    if (formData.type === 1) {
      if (formData._method === 'text' && !formData.contact.text) {
        error = '请填写微信号'
      }
      if (formData._method === 'qrcode' && !formData.contact.qrcode) {
        error = '请上传二维码图片'
      }
    }
    if (formData.type === 2) {
      if (!formData.contact.qrcode) {
        error = '请上传二维码图片'
      }
    }
    if (formData.type === 3) {
      if (formData._method === 'text' && !formData.contact.text) {
        error = '请填写公众号'
      }
      if (formData._method === 'qrcode' && !formData.contact.qrcode) {
        error = '请上传二维码图片'
      }
    }
    if (formData.type === 4) {
      if (!formData.contact.text) {
        error = '请输入手机号'
      }
    }
  }
  if (id === 'banner') {
    if (formData.type === 2) {
      formData.up_banners.forEach((banner) => {
        if (_.some(_.get(banner, 'banners'), b => !b.image)) {
          return error = '请添加 Banner 图片';
        }
      });
      if (formData.up_banners.length===0){
        error = '请添加 Banner 广告';
      }
    } else {
      formData.banners.forEach((banner) => {
        if (!banner.image) {
          return error = '请添加 Banner 图片';
        }
      });
      if (formData.banners.length === 0) {
        error = '请添加 Banner 广告';
      }
    }
  }
  if (id === 'coupon') {
    formData.coupons.forEach((item) => {
      if (!item.type) {
        error = '请选择优惠卷';
      }
      if (item.type === 1) {
        if (!item.trigger) {
          error = '请设置满 * 件';
        }
        if (!item.cut) {
          error = '请设置打 * 折'
        }
      }
      if (item.type === 2) {
        if (!item.trigger) {
          error = '请设置满 * 元';
        }
        if (!item.cut) {
          error = '请设置减 * 元'
        }
      }
      if (item.type === 102) {
        if (!item.cut) {
          error = '请设置直减 * 元'
        }
      }
      if (item.type === 103) {
        if (!item.cut) {
          error = '请设置 * 折'
        }
      }
    })
  }
  if (id === 'category') {
    const type = formData.styleType;
    _.get(formData, 'data.categories', []).forEach((item) => {
      const navType = item.navigation.type;
      if (navType === 'products' && item.products.length === 0) {
        error = '请添加商品'
      }
      if (navType === 'coupon' && !item.navigation.data) {
        error = '请选择跳转优惠券'
      }
      if (navType === 'page' && !item.navigation.data) {
        error = '请选择跳转页面'
      }
      if (type === 't1') {
        if (!item.image) {
          error = '请添加封面'
        }
      }
      if (!item.name) {
        error = '请填写分类名称'
      }
    })
  }
  if (id === 'share') {
    if (!formData.share_cover) {
      error = '请上传分享图封面'
    }
    if (!formData.desc) {
      error = '请填写分享引导文案'
    }
  }
  if (id === 'flash_cut') {
    if (!formData.end_at) {
      error = '请设置活动结束时间'
    } else if (new Date() > new Date(formData.end_at)) {
      error = '活动结束时间不能小于当前时间'
    }
    if (formData.products.length === 0) {
      error = '请添加商品';
    }
    formData.products.forEach((product) => {
      if (!product.id) {
        error = '请添加商品';
        return;
      }
      if (!product.max_cut) {
        error = '请输入砍价金额';
        return;
      }
      const amount = parseFloat(product.max_cut)
      if (amount > parseFloat(product.price / 100)) {
        error = '最多砍价金额需小于商品价格';
      }
      if (amount < 0.5) {
        error = '最多砍价金额需大于 0.5';
      }
    })
    if (!formData.title) {
      error = '请输入模块标题'
    }
  }
  if (id === 'member') {
    if (!formData.title) {
      error = '请添加引导文案'
    }
    if (!formData.desc) {
      error = '请添加权益描述'
    }
    if (!formData.valid_day) {
      error = '请添加会员有效期'
    }
  }
  return error;
}


export function setActiveModule(modules, id) {
  modules.forEach((item) => {
    item.active = item.id === id;
  })
}
