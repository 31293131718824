import axios from 'axios';
import _ from 'lodash';

const instance = axios.create({
  baseURL: 'https://minastore.nocode.com/api'
});

instance.defaults.timeout = 30000;

instance.interceptors.response.use((response) => {
  return response;
}, (error, a) => {
  if (error.message === 'Request failed with status code 401') {
    window.location.pathname = '/logout'
  }
  const errorMsg = _.get(error, 'response.data.error.message');
  if (errorMsg) {
    throw new Error(errorMsg);
  }
  return error;
})

export default instance;