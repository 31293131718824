import React from "react";
import classnames from 'classnames';

import './style.css';

export default function Member({ module }) {
  const formData = module.formData || {};
  const { title, desc, style = {} } = formData;

  return <div className={classnames('preview-member')} style={{
    backgroundColor: style.background_color
  }}>
    <div className="content">
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
      <div className="block">
        <img className="vip-icn" src="/icn-vip-text@3x.png"/>
        <div className="pending"></div>
        <div className="btn">成为会员</div>
      </div>
    </div>
  </div>
}
