import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import Rodal from "rodal";
import { RightOutlined } from "@ant-design/icons";
import './style.css';


function FeedbackBar() {
  const { width, height } = useWindowSize();
  const [visible, setVisible] = useState(false);
  const dialogWidth = Math.min(width * 0.8, 594);
  const dialogHeight = Math.min(height * 0.8, 682);

  return [<div className="feedback-bar" onClick={() => {
    setVisible(true)
  }}>
    <img src="/icn-feedback.svg" className="icn"></img>
    <div className="messages">
      <div className="message-wraper">
        <div>限时专属福利：评价服务，返还服务费</div>
        <div>现金返还您 1 个月服务费（20 元）</div>
        <div>限时专属福利：评价服务，返还服务费</div>
      </div>
    </div>
    <div className="pending"></div>
    <RightOutlined style={{
      color: '#ffffff',
      height: '0.6em',
      marginTop: -4
    }} />
  </div>, <Rodal
    visible={visible}
    onClose={() => {
      setVisible(false)
    }}
    width={dialogWidth}
    height={dialogHeight}
    showCloseButton={false}
  >
    <div className='feedback-bar-dialog'>
      <img style={{
        width: '100%'
      }} src="/pic-feedback-detail@3x.png"/>  
    </div>
  </Rodal>]
}

export default FeedbackBar;