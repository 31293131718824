import React from "react";

import styles from "./Manual.module.css";

function Manual() {
  return (
    <div className={styles.manual}>
      <div className={styles.main}>
        <div className={styles.card}>
            <img src='/steps/01.jpg' alt="" loading="eager"/>
            <img src='/steps/02.jpg' alt="" loading="lazy"/>
            <img src='/steps/03.jpg' alt="" loading="lazy"/>
            <img src='/steps/04.jpg' alt="" loading="lazy"/>
            <img src='/steps/05.jpg' alt="" loading="lazy"/>
            <img src='/steps/06.jpg' alt="" loading="lazy"/>
            <img src='/steps/07.jpg' alt="" loading="lazy"/>
        </div>
      </div>
    </div>
  );
}

export default Manual;
