import React from "react";
import classnames from 'classnames';
import {MODULES, newModule, setActiveModule} from "services/module";
import {message} from 'antd';
import { useModules } from 'services/home';
import './style.css';

function DragableModule({module, onClick}) {
  function onDragStart(e) {
    e.dataTransfer.setData("drag_module", JSON.stringify(module));
    e.dataTransfer.setDragImage(module.prevIcn, 187, 34);
  }

  return <div className='item' onDragStart={onDragStart} draggable key={module.id} onClick={onClick}>
    <img src={module.icn}/>
    <div>{module.name}</div>
  </div>
}

export default function ModuleSelector() {
  const {addModule, template} = useModules();
  const isDisable = !template.id;

  const normalModules = MODULES.filter((m) => {
    return m.category !== 2;
  })

  const marketModules = MODULES.filter((m) => {
    return m.category === 2;
  })

  return [<div className="app-module-selector">
    <h2 className="header">点击添加模块</h2>
    <div className={classnames('list', {
      'disable': isDisable
    })}>
      {normalModules.map((module) => {
        return <DragableModule module={module} onClick={() => {
          addModule(module.id);
        }}></DragableModule>
      })}
    </div>
  </div>, <div className="app-module-selector" style={{
    marginTop: 16
  }}>
    <h2 className="header">营销模块</h2>
    <div className={classnames('list', {
      'disable': isDisable
    })}>
      {marketModules.map((module) => {
        return <DragableModule module={module} onClick={() => {
          addModule(module.id);
        }}></DragableModule>
      })}
    </div>
  </div>]
}