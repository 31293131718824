import React from "react";
import classnames from 'classnames';
import { Carousel } from 'antd';

import './style.css';

export default function Banner({ module }) {
  const formData = module.formData || {};
  const {type, up_banners = [], banners = [], style = {}} = formData;

  if (type === 2) {
    return <div className="preview-splicing-banner" style={{
      backgroundColor: style.background_color
    }}>
      {up_banners.map((item, index) => {
        return <div className={classnames('item', `type-${item.banners.length}`)} key={index}>
          {item.banners.map((item, index) => {
            if (!item.image) {
              return <div className={classnames('block', 'empty', `block-${index}`)} key={index}>
                <img src='/icn-banner-default@3x.png' className="icn" />
              </div>
            }
            return <div className={classnames('block', `block-${index}`)} key={index}>
              <img src={item.image} className="block-item" />
            </div>
          })}
        </div>
      })}
    </div >
  }

  return <div className="preview-banner" style={{
    backgroundColor: style.background_color
  }}>
    <Carousel autoplay dots={{
      className: 'carousel-dots'
    }}>
      {banners.map((item, index) => {
        if (!item.image) {
          return <div className={classnames('swipe-item')} key={index}>
            <div className="empty">
              <img src='/icn-banner-default@3x.png' className="icn" />
            </div>
          </div>
        }
        return <div className='swipe-item' key={index} >
          <img src={item.image} />
        </div>
      })}
    </Carousel>
  </div>
}
