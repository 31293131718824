import React from "react";
import _ from 'lodash';
import { useModules } from 'services/home';
import classnames from 'classnames';
import { Input, Select, Dropdown, Button } from 'antd';
import './style.css';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';


const { Option } = Select;
const { TextArea } = Input;

export default function Notice() {
  const { activeModule, changeModule } = useModules();

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  return <div className="config-notice">
    <div className="common-config-block">
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-select-item">
        <Select value={activeModule.formData.style} onChange={(value) => {
          changeValue(`style`, value)
        }}>
          <Option value={'scroll'}>左右滚动样式</Option>
          <Option value={'default'}>经典样式</Option>
          <Option value={'style1'}>紧凑样式</Option>
        </Select>
      </div>
    </div>
    <div className="common-config-block" >
      <div className="header">
        <div className="label">填写内容</div>
      </div>
      <div className="config-item config-textarea">
        <TextArea style={{ maxWidth: 360 }} allowClear value={activeModule.formData.content} autoSize={{
            minRows: 4
          }} onChange={(e) => {
            changeValue('content', e.currentTarget.value)
          }} />
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.module_style.background_color} onChange={(color) => {
            changeValue(`module_style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.module_style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
