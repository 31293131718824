import React from "react";
import classnames from 'classnames';
import {amountStr, detectTextColor, isColorDark} from 'utils/tools';

import './style.css';

export default function FlashCut({ module }) {
  const formData = module.formData || {};
  const {products, end_at, style = {}, title = ''} = formData;

  const textColor = detectTextColor(style.background_color);
  const isDark = isColorDark(style.background_color);

  const validProducts = products.filter((p) => {
    return !!p.id;
  })

  const countdown = {
    hour: '00',
    minute: '00',
    second: '00'
  }

  return <div className={classnames('preview-flash-cut', {
    dark: isDark
  })} style={{
    backgroundColor: style.background_color
  }}>
    <div className="header">
      <span className="label" style={{
        color: textColor
      }}>{title}</span>
      {end_at && <div className="countdown" style={{
        color: textColor
      }}>
        <div className="text">距结束</div>
        <div className="value">{ countdown.hour }</div>
        <div className="text">时</div>
        <div className="value">{ countdown.minute }</div>
        <div className="text">分</div>
        <div className="value">{ countdown.second }</div>
        <div className="text">秒</div>
      </div>}
    </div>
    <div className="list">
      {validProducts.length === 0 && <img style={{
        width: '336px'
      }} src="/icn-cutdown-default.svg"/>}
      {validProducts.map((product, index) => {
        return <div className="item" key={index}>
          <img className="cover" src={product.image}/>
          <div className="block">
            <div className="title">{product.title}</div>
            <div className="meta">
              <div className="tag">
                <span className="value">限时砍价</span>
              </div>
            </div>
            <div className="flex-block">
              <div className="price">￥{(product.price / 100).toFixed(2)}</div>
              <div className="btn">
                <img className="icn" src="/icn-quick-cutdown-small.svg"/>
                <span>立即砍价</span>
              </div>
            </div>
          </div>
        </div>
      })}
    </div>
  </div>
}
