import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import moment from 'moment';
import _ from 'lodash';
import './style.css';
import Rodal from "rodal";
import { useSendedCoupons } from 'services/member';
import { Button, Table, Popconfirm } from 'antd';


export default function GoodsSelector({visible = false, onClose = () => { }}) {
  const { width, height } = useWindowSize();
  const { coupons, loading, hasMore, page, nextCoupons, prevCoupons, updateCoupons, invalid} = useSendedCoupons();
  const dialogWidth = Math.min(width * 0.85, 1004);
  const dialogHeight = Math.min(height * 0.85, 619);
  
  useEffect(() => {
    if (visible) {
      updateCoupons()
    }
  }, [visible])

  const columns = [{
    title: '优惠券名称',
    dataIndex: 'name',
    key: 'name',
    render: (name) => {
      return name;
    }
  }, {
    title: '批次号',
    dataIndex: 'coupon_id',
    key: 'coupon_id',
    render: (coupon_id) => {
      return coupon_id;
    }
  }, {
    title: '券类型',
    dataIndex: 'coupon_type',
    key: 'coupon_type',
    render: (coupon_type) => {
      return coupon_type;
    }
  }, {
    title: '发放至',
    dataIndex: 'member_rank',
    key: 'member_rank',
    render: (member_rank) => {
      return member_rank;
    }
  }, {
    title: '券状态',
    dataIndex: 'state',
    key: 'state',
    render: (state) => {
      return state === 1 ? '生效中' : '已失效'
    }
  }, {
    title: '券有效期',
    key: 'expired_at',
    dataIndex: 'expired_at',
    render: (expired_at, record) => {
      const {created_at} = record;
      return `${moment(created_at).format('YYYY/MM/DD')}至${moment(expired_at).format('YYYY/MM/DD')}`
    }
  }, {
    title: '剩余/发放',
    key: 'last_num',
    dataIndex: 'last_num',
    render: (last_num, record) => {
      const {total_num} = record;
      return `${last_num}/${total_num}`;
    }
  }, {
    title: '操作',
    key: 'action',
    render: (__, record) => {
      if (record.state === -1) {
        return null;
      }
      return <div className="action-block">
        <Popconfirm title="确认作废" okText="是" cancelText="否" onConfirm={(e) => {
          e.stopPropagation();
          invalid(record.id)
        }}><a>作废</a></Popconfirm>
      </div>
    }
  }]

  return <Rodal
    visible={visible}
    onClose={onClose}
    width={dialogWidth}
    height={dialogHeight}
    showCloseButton={false}
  >
    <div className='coupon-manage-dialog'>
      <div className="coupons">
        <Table columns={columns} loading={loading} dataSource={coupons} pagination={false} rowClassName={(record) => {
          if (record.state === -1) {
            return 'disable'
          }
          return '';
        }}/>
      </div>
      <div style={{flex: '1 1 auto'}}> </div>
      <div className="footer">
        <div className="pag">
          <Button className="prev" disabled={page === 1} onClick={prevCoupons}>上一页</Button>
          <div className="num">第 {page} 页</div>
          <Button className="next" disabled={!hasMore} onClick={nextCoupons}>下一页</Button>
        </div>
      </div>
    </div>
  </Rodal>
}