import React from "react";
import classnames from 'classnames';
import _ from 'lodash';
import { useModules } from 'services/home';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import {
  Dropdown, Button
} from "antd";


import './style.css';

export default function Goods() {
  const { activeModule, changeModule } = useModules();

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  return <div className="config-goods">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">商品排序</div>
      </div>
      <div className="config-item config-cards">
        <div className="goods-not-support">暂未开放，当前默认按价格排序</div>
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
