import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash';
import { useModules, useCoupon } from 'services/home';
import { toZhDigit } from "utils/tools";
import {
  Input,
  Select,
  DatePicker,
  Upload,
  message,
  Dropdown, Button
} from "antd";
import ImgCrop from "antd-img-crop";
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';


import './style.css';

const { Option } = Select;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  console.log('size', file.size)
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function newDefaultPrize() {
  return {
    type: 1,
    name: '',
    level: 1,
    prize_count: undefined,
    win_rate: undefined
  };
}

export default function Lottery() {
  const { activeModule, changeModule } = useModules();
  const [loading, setLoading] = useState(false);
  const [coupons, updateCoupon] = useCoupon();

  const parCntList = [1, 2, 3, 5, 8, 10];
  const shareParCntList = [1, 2, 3, 5, 8, 10];

  if (!activeModule) {
    return null;
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      setLoading(false);
      changeValue('image', url);
    } else if (info.file.status === 'error') {
      setLoading(false);
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  function addPrize() {
    const formData = activeModule.formData;
    formData.prizes.push(newDefaultPrize());
    changeModule(activeModule);
  }

  function removePrize(index) {
    const formData = activeModule.formData;
    formData.prizes.splice(index, 1);
    if (formData.prizes.length === 0) {
      formData.prizes = [newDefaultPrize()];
    }
    changeModule(activeModule);
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  const notFoundContent = <div>暂无优惠券，请到小商店管理后台进行创建</div>

  return <div className="config-lottery">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-cards">
        <div className="config-cards-item">
          <div className="desc">大转盘</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className="config-cards-item disable">
          <div className="desc">更多模式即将开放</div>
        </div>
      </div>
    </div>
    {activeModule.formData.prizes.map((prize, index) => {
      return <div className="common-config-block" key={index}>
        <div className="header">
          <div className="label">添加奖项</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removePrize(index);
          }}>移除</div>
        </div>
        <div className="config-item config-label-input-1">
          <div className="label">{toZhDigit(index + 1)}等奖</div>
          <Input value={prize.name} placeholder="填写奖品名称" onChange={(e) => {
            changeValue(`prizes[${index}].name`, e.currentTarget.value)
          }} />
        </div>
        <div className="config-item config-label-input-2">
          <div className="label">中奖设置</div>
          <Input
            type="number"
            placeholder="中奖率"
            value={prize.win_rate}
            min={0}
            max={100}
            onChange={(e) => {
              let rate = e.currentTarget.value;
              if (rate) {
                rate = parseFloat(rate);
                if (isNaN(rate)) {
                  rate = 0;
                }
              }
              if (rate > 100) {
                rate = 100;
              }
              changeValue(`prizes[${index}].win_rate`, rate)
            }}
            suffix="%"
          />
          <Input
            type="number"
            value={prize.prize_count}
            placeholder="输入数量"
            onChange={(e) => {
              let value = e.currentTarget.value;
              if (value) {
                value = parseFloat(value);
                if (isNaN(value)) {
                  value = 0;
                }
              }
              changeValue(`prizes[${index}].prize_count`, value)
            }}
            min={0}
            max={100000000}
            suffix="份"
          />
        </div>
        <div className="config-item config-label-select-1">
          <div className="label">发奖方式</div>
          <Select value={prize.type} onChange={(value) => {
            changeValue(`prizes[${index}].type`, value)
          }}>
            <Option value={1}>发放优惠券</Option>
            <Option value={2}>加店主微信兑换</Option>
          </Select>
        </div>
        {prize.type === 1 && <div className="config-item config-label-select-1">
          <div className="label">绑定优惠</div>
          <Select
            placeholder="绑定对应优惠券"
            notFoundContent={notFoundContent}
            onClick={updateCoupon}
            value={_.get(prize, 'prize_info.coupon')}
            onChange={(value) => {
              changeValue(`prizes[${index}].prize_info.coupon`, value)
            }}
          >
            {coupons.map((item) => {
              return <Option key={item.id} value={item.id}>{item.name}</Option>
            })}
          </Select>
        </div>}
        {prize.type === 2 && <div className="config-item config-label-input-1">
          <div className="label">店主微信</div>
          <Input value={_.get(prize, 'prize_info.wechat')} placeholder="请输入您的微信号" onChange={(e) => {
            changeValue(`prizes[${index}].prize_info.wechat`, e.currentTarget.value)
          }} />
        </div>}
      </div>
    })}
    {activeModule.formData.prizes.length < 3 && <div className="common-config-btn" onClick={addPrize}>
      <img className="icn" src="/icn-add-big-green@3x.png" />
      <span className="desc">新增奖项</span>
    </div>}
    <div className="common-config-block" style={{
      marginTop: '30px'
    }}>
      <div className="header">
        <div className="label">抽奖结束时间</div>
      </div>
      <div className="config-item config-datetime">
        <DatePicker format="YYYY年MM月DD日 HH:mm" value={activeModule.formData.latest_draw_time ? moment(activeModule.formData.latest_draw_time) : ''} showTime onChange={(e) => {
          changeValue('latest_draw_time', e.format('YYYY-MM-DD HH:mm:ss'))
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">用户参与设置</div>
      </div>
      <div className="config-item config-label-select-1">
        <div className="label">参与限制</div>
        <Select value={activeModule.formData.par_cnt} onChange={(value) => {
          changeValue(`par_cnt`, value)
        }}>
          {parCntList.map((v, index) => {
            return <Option key={index} value={v}>每个用户可参与 {v} 次此抽奖</Option>
          })}
        </Select>
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">邀请好友裂变</div>
      </div>
      <div className="config-item config-label-select-1">
        <div className="label">分享裂变</div>
        <Select value={activeModule.formData.share_par_cnt} onChange={(value) => {
          changeValue(`share_par_cnt`, value)
        }}>
          <Option value={0}>不开启</Option>
          <Option value={activeModule.formData.share_par_cnt || 5}>开启每邀请 1 人可额外参与 1 次</Option>
        </Select>
      </div>
      {activeModule.formData.share_par_cnt !== 0 && <div className="config-item config-label-select-1">
        <div className="label">参与限制</div>
        <Select value={activeModule.formData.share_par_cnt} onChange={(value) => {
          changeValue(`share_par_cnt`, value)
        }}>
          {shareParCntList.map((v, index) => {
            return <Option key={index} value={v}>最多可额外参与 {v} 次</Option>
          })}
        </Select>
      </div>}
    </div>
    <div className="common-config-block" style={{
      marginTop: '30px'
    }}>
      <div className="header">
        <div className="label">奖品封面</div>
      </div>
      <div className="config-lottery-cover">
        <img className="cover" src={activeModule.formData.image || "https://ministore-cdn.nocode.com/prod/goods/upload/81bf2vGUtFH_ACVFR_1606374356.png"} />
        <ImgCrop aspect={2} quality={0.6}>
          <Upload
            name="file"
            style={{ width: 300 }}
            listType="picture-card"
            className="uploader-btn"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{
              Authorization: 'Bearer ' + window.__token
            }}
          >
            <div>更换封面</div>
          </Upload>
        </ImgCrop>
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
