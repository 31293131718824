
import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import { Button, Select, Input, DatePicker, message, Modal } from 'antd'
import moment from 'moment';
import GoodsSelector from 'components/GoodsSelector';
import _ from 'lodash';
import { checkFormError, sendCoupon, updateMemberCouponTask, useMemberCoupons, removeMemberCouponTask, rankMap } from 'services/member';

import './task.css';
import { useSingleExec } from "utils/functional";

const { Option } = Select;

const defaultFormData = {
  frequency: 1,
  start_at: '',
  end_at: '',
  member_rank: '',
  coupon: {
    type: 2,
    name: '',
    discount_info: {

    },
    total_num: '',
    limit: 1
  }
};

export default function MemberCouponTask({ isShow = false, onChange = () => { } }) {
  const [publishing, setPublish] = useState(false);
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);
  const [formData, setFormData] = useState(_.cloneDeep(defaultFormData));
  const memberCoupon = useMemberCoupons();
  const [isDelModalVisible, setDelModalVisible] = useState(false);

  useEffect(() => {
    if (isShow) {
      memberCoupon.load().then((list) => {
        if (list[0]) {
          memberCoupon.changeActiveId(list[0].id);
        }
      })
    }
  }, [isShow])

  useEffect(() => {
    if (memberCoupon.coupon) {
      setFormData(memberCoupon.coupon.data);
    } else {
      setFormData(_.cloneDeep(defaultFormData));
    }
  }, [memberCoupon.coupon])

  function changeForm(path, value) {
    const form = { ...formData };
    _.set(form, path, value);
    setFormData(form);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  function deleteProduct(productIndex) {
    const products = _.cloneDeep(formData.coupon.discount_info.products);
    products.splice(productIndex, 1);
    changeForm('coupon.discount_info.products', products);
  }

  async function publish(isPublish) {
    if (publishing) {
      return;
    }
    const data = {
      type: 2,
      data: _.cloneDeep(formData)
    }
    const error = checkFormError(data);
    if (error) {
      return message.error(error);
    }
    setPublish(true)
    try {
      delete data.data.coupon.limit;
      if (data.data.coupon.type === 3) {
        data.data.coupon.discount_info.cut = data.data.coupon.discount_info.cut / 10;
      }
      if (memberCoupon.activeId) {
        await updateMemberCouponTask(memberCoupon.activeId, data.data);
        await memberCoupon.load();
      } else {
        const resp = await sendCoupon(data);
        memberCoupon.setActiveId(resp.data.data.id);
        await memberCoupon.load();
      }
      message.success('已保存');
    } catch (e) {
      console.error(e);
      message.error(e.message);
    } finally {
      setPublish(false)
    }
  }

  const deleteCoupon = useSingleExec(async (id) => {
    await removeMemberCouponTask(id);
    const list = await memberCoupon.load();
    memberCoupon.changeActiveId(_.get(list, '[0].id', ''));
  }, {}, []);

  return <div className={classnames('member-coupon-task', {
    'show': isShow
  })}>
    <div className="cover" onClick={() => {
      onChange(false);
    }}></div>
    <div className="config-block">
      <div className="left-block">
        <div className="header">定时发放会员优惠券</div>
        <div className="list">
          {memberCoupon.coupons.map((coupon, index) => {
            return <div key={index} className={classnames('item', {
              active: memberCoupon.activeId === coupon.id
            })} onClick={() => {
              memberCoupon.changeActiveId(coupon.id)
            }}>
              <img className="delete-btn" src="/icn-delete-popup@3x.png" onClick={() => {
                setDelModalVisible(true);
              }}/>
              <div className="block">
                <div className="name">{coupon.name}</div>
                {coupon.type === 2 && <div className="info">
                  <span className='value'>{coupon.cut}</span>
                  <span className='desc'>元</span>
                </div>}
                {coupon.type === 3 && <div className="info">
                  <span className='value'>{coupon.cut}</span>
                  <span className='desc'>折</span>
                </div>}
                {coupon.type === 4 && <div className="info">
                  <span className='value'>{coupon.cut}</span>
                  <span className='desc'>元</span>
                </div>}
              </div>
              <img className="arrow" src="/icn-arrow-down@3x.png"/>
            </div>
          })}
        </div>
        <div className="new-btn" onClick={() => {
          memberCoupon.changeActiveId('');
        }}>
          <img className="icn" src="/icn-add-big-green@3x.png" />
          <div className="desc">新增优惠券</div>
        </div>
      </div>
      <div className="right-block">
        <div className="module-config-panel">
          <div className="common-config-block">
            <div className="header">
              <div className="label">优惠券名称</div>
            </div>
            <div className="config-item config-input-1">
              <Input placeholder="输入名称" maxLength={10} value={formData.coupon.name} onChange={(e) => {
                changeForm('coupon.name', e.currentTarget.value)
              }} />
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">优惠券类型</div>
            </div>
            <div className="config-item  config-label-select-1">
              <Select value={formData.coupon.type} onChange={(value) => {
                changeForm(`coupon.discount_info.cut`, undefined);
                changeForm(`coupon.discount_info.trigger`, undefined);
                changeForm('coupon.type', value);
              }}>
                <Option value={2}>满减</Option>
                <Option value={3}>折扣券</Option>
                <Option value={4}>直减券</Option>
              </Select>
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">优惠详情</div>
            </div>
            {formData.coupon.type === 3 && <div className="config-item config-input-1">
              <Input
                type="number"
                min={1}
                max={9.9}
                prefix="打"
                suffix="折"
                value={formData.coupon.discount_info.cut}
                onChange={(e) => {
                  let v = e.currentTarget.value;
                  if (v) {
                    v = parseFloat(v);
                    if (isNaN(v)) {
                      v = "";
                    } else if (v > 9.9) {
                      v = 9.9;
                    } else {
                      v = Math.floor(v * 10) / 10
                    }
                  }
                  changeForm(`coupon.discount_info.cut`, v);
                }}
              />
            </div>}
            {formData.coupon.type === 2 && <div className="config-item config-input-2">
              <Input
                type="number"
                min={0}
                prefix="满"
                suffix="元"
                value={formData.coupon.discount_info.trigger}
                onChange={(e) => {
                  let v = e.currentTarget.value;
                  if (v) {
                    v = parseInt(v);
                    if (isNaN(v)) {
                      v = "";
                    }
                  }
                  changeForm(
                    `coupon.discount_info.trigger`,
                    v
                  );
                }}
              />
              <Input
                type="number"
                min={0}
                prefix="减"
                suffix="元"
                value={formData.coupon.discount_info.cut}
                onChange={(e) => {
                  let v = e.currentTarget.value;
                  if (v) {
                    v = parseInt(v);
                    if (isNaN(v)) {
                      v = "";
                    }
                  }
                  changeForm(`coupon.discount_info.cut`, v);
                }}
              />
            </div>}
            {formData.coupon.type === 4 && <div className="config-item config-label-select-1">
              <Input
                type="number"
                min={0}
                prefix="直减"
                suffix="元"
                value={formData.coupon.discount_info.cut}
                onChange={(e) => {
                  let v = e.currentTarget.value;
                  if (v) {
                    v = parseInt(v);
                    if (isNaN(v)) {
                      v = "";
                    }
                  }
                  changeForm(`coupon.discount_info.cut`, v);
                }}
              />
            </div>}
          </div>
          <div className="common-column-2">
            <div className="common-config-block">
              <div className="header">
                <div className="label">发放频次</div>
              </div>
              <div className="config-item config-select-item">
                <Select value={formData.frequency} onChange={(value) => {
                  changeForm(`frequency`, value)
                }}>
                  <Option value={1}>每天发放一次</Option>
                  <Option value={2}>每 2 天发放一次</Option>
                  <Option value={3}>每 3 天发放一次</Option>
                  <Option value={4}>每 1 周发放一次</Option>
                  <Option value={5}>每 1 月发放一次</Option>
                </Select>
              </div>
            </div>
            <div className="common-config-block">
              <div className="header">
                <div className="label">每次发放总量</div>
              </div>
              <div className="config-item config-input-1">
                <Input type="number" placeholder="" maxLength={10} value={formData.coupon.total_num} suffix="张" onChange={(e) => {
                  changeForm('coupon.total_num', parseInt(e.currentTarget.value))
                }} />
              </div>
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">初次发放日期</div>
            </div>
            <div className="config-item config-datetime">
              <DatePicker format="YYYY年MM月DD日 HH:mm" value={formData.start_at ? moment(formData.start_at) : ''} showTime onChange={(e) => {
                changeForm('start_at', e.format('YYYY-MM-DD HH:mm:ss'))
              }} />
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">截止发放日期</div>
            </div>
            <div className="config-item config-datetime">
              <DatePicker format="YYYY年MM月DD日 HH:mm" value={formData.end_at ? moment(formData.end_at) : ''} showTime onChange={(e) => {
                changeForm('end_at', e.format('YYYY-MM-DD HH:mm:ss'))
              }} />
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">使用限制</div>
            </div>
            <div className="config-item  config-label-select-1">
              <Select value={formData.coupon.limit} onChange={(value) => {
                changeForm('coupon.limit', value);
                if (value === 1) {
                  changeForm(`coupon.discount_info.products`, undefined);
                } else {
                  changeForm(`coupon.discount_info.products`, []);
                }
              }}>
                <Option value={1}>全部商品可用</Option>
                <Option value={2}>指定商品可用</Option>
              </Select>
            </div>
          </div>
          <div className="common-config-block">
            <div className="header">
              <div className="label">选择要发送的分组</div>
            </div>
            <div className="config-item  config-label-select-1">
              <Select value={formData.member_rank || ''} defaultValue="" onChange={(value) => {
                changeForm(`member_rank`, value);
              }}>
                <Option value="">全部</Option>
                <Option value="LEVEL1">{rankMap.LEVEL1}</Option>
                <Option value="LEVEL2">{rankMap.LEVEL2}</Option>
                <Option value="LEVEL3">{rankMap.LEVEL3}</Option>
              </Select>
            </div>
          </div>
          {formData.coupon.limit === 2 && <div className="common-config-block">
            <div className="header">
              <div className="label">绑定可用的商品</div>
            </div>
            <div className='config-products'>
              {formData.coupon.discount_info.products.map((value, productIndex) => (
                <div className={classnames('config-product')}>
                  <div className="main">
                    <img className="cover" src={value.image} />
                    <div className="name">{value.title}</div>
                  </div>
                  <img className="del-icon highlight" src="/icn-delete-popup@3x.png" onClick={() => {
                    deleteProduct(productIndex)
                  }} />
                </div>
              ))}
              <div className='add-product-btn highlight' onClick={() => {
                setProductsDialogVisible(true);
              }}>
                <img src="/icn-add-grey-small@2x.png" />
                <span>添加商品</span>
              </div>
            </div>
          </div>}
        </div>
        <div className="footer">
          <Button className="opt" onClick={() => {
            onChange(false)
          }}>取消</Button>
          <Button className="opt primary" type="primary" loading={publishing} onClick={publish}>保存</Button>
        </div>
      </div>
    </div>
    <GoodsSelector visible={isProductsDialogVisible} defaultGoods={_.get(formData, 'coupon.discount_info.products', [])} onConfirm={(goods) => {
      changeForm(`coupon.discount_info.products`, goods)
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
     <Modal title="操作提示" visible={isDelModalVisible} onOk={() => {
      deleteCoupon(memberCoupon.activeId)
      setDelModalVisible(false)
    }} onCancel={() => {
      setDelModalVisible(false)
    }}>
      <p>确认删除优惠券？</p>
    </Modal>
  </div>
}