import React from "react";
import classnames from 'classnames';
import {amountStr, detectTextColor} from 'utils/tools';

import './style.css';
import rankRedIcon from '../images/icn-rank-red-bg@3x.png';
import rankGreenIcon from '../images/icn-rank-grey-bg@3x.png';

import ProductCard from '../common/ProductCard';

export default function Recommend({ module }) {
  const formData = module.formData || {};
  const { type, title = '热销推荐', products = [], style = {},  config = {}} = formData;
  let {per_line_num, line_num, show_buy, show_rank} = config;

  let textColor = detectTextColor(style.background_color);

  if (line_num === 0) {
    line_num = Number.MAX_SAFE_INTEGER;
  }

  if (type === 1) {
    const lines = [];
    let lineIndex = 0;
    let showedCount = 0;
    for (let i = 0, len = products.length; i < len; i++) {
      if (lineIndex > line_num - 1) {
        break;
      }
      if (lineIndex === line_num - 1) {
        const currentLine = lines[lineIndex];
        if (currentLine && currentLine.length >= per_line_num) {
          break;
        }
      }
      if (!lines[lineIndex]) {
        lines[lineIndex] = [];
      }
      const product = products[i];
      if ((lines[lineIndex].length >= per_line_num) || (product.set_big && lines[lineIndex].length !== 0)) {
        lineIndex++;
        lines[lineIndex] = [];
      }
      lines[lineIndex].push(product);
      if (product.set_big) {
        lineIndex++;
      }
      showedCount++;
    }
    const showMoreBtn = showedCount !== products.length;
    return <div className="preview-product-group" style={{
      backgroundColor: style.background_color
    }}>
      <div className="header">
        <span className="label" style={{
          color: textColor
        }}>{title}</span>
      </div>
      <div className="list">
        {products.length === 0 && <img style={{
          width: '336px'
        }} src="/icn-recommend-default-2@3x.png"/>}
        {lines.map((line, index) => {
          return <div className={classnames('line', `line-${per_line_num}`)} key={index}>
            {line.map((product, productIndex) => {
              return <div className={classnames('item', {
                big: product.set_big
              })} key={productIndex}>
                <ProductCard product={product} show_buy={show_buy} per_line_num={per_line_num}/>
              </div>
            })}
          </div>
        })}
      </div>
      {showMoreBtn && <div className='more-btn'>查看更多</div>}
    </div>
  }

  if (type === 2) {
    const showMoreBtn = products.length > line_num;
    return <div className="preview-product-group-rank" style={{
      backgroundColor: style.background_color
    }}>
      <div className="header">
        <span className="label" style={{
          color: textColor
        }}>{title}</span>
      </div>
      <div className="list">
        {products.length === 0 && <img style={{
          width: '336px'
        }} src="/icn-recommend-default-1@3x.png"/>}
        {products.slice(0, line_num).map((item, index) => {
          return <>
            <div className="item" key={`prod-${index}`}>
              <img className="cover" src={item.image} mode="aspectFill" />
              <div className="content">
                <div className="name">{item.title}</div>
                <div className="pending"></div>
                <div className="p">
                  {!show_buy && <div className="pending"></div>}
                  <div className="price">￥{amountStr(item.price)}</div>
                  {show_buy && [<div className="pending"></div>, <div className="buy-btn">立即购买</div>]}
                </div>
              </div>
              {show_rank && index < 3 && <div className="rank">
                {index < 3 ? <img className="icn" src={rankRedIcon} /> : <img className="icn" src={rankGreenIcon} />}
                <div className="value">{index + 1}</div>
              </div>}
            </div>
          </>
        })}
      </div>
      {showMoreBtn && <div className='more-btn'>查看更多</div>}
    </div>
  }

  return 'not support';
}
