import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules, useCoupon, useShopCats, processShopCats, useRenovationPages } from 'services/home';
import { message, Upload, Select, Cascader, Dropdown, Button } from 'antd';
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import GoodsSelector from 'components/GoodsSelector';
import { ChromePicker } from 'react-color';

import './style.css';

const { Option } = Select;

function newDefaultBanner() {
  return {
    image: "",
    type: "product",
  };
}

function newDefaultBannerUpDownStl() {
  return {
    banners: [
      {
        image: "",
        type: "product",
      },
    ],
  };
}

function newDefaultBannerUpDownStlHalf() {
  return {
    banners: [
      {
        image: "",
        type: "product",
      },
      {
        image: "",
        type: "product",
      },
    ],
  };
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  console.log('file.size', file.size);
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function getCatPath(cid, cats = [], paths = []) {
  for(let i = 0, len = cats.length; i < len; i++) {
    let cat = cats[i];
    let currentPaths = paths.concat(cat.shopcat_id);
    if (cat.shopcat_id === cid) {
      return currentPaths;
    }
    const res = getCatPath(cid, cat.children || [], currentPaths);
    if (res) {
      return res;
    }
  }
}

function BannerNav({ banner, coupons, cats, onChangeType, updateCoupon, onChangeCoupon, onChangeCat, onChangeGood, upBannerIndex, index }) {
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);
  const {pages} = useRenovationPages();
  let defaultCatValue;
  if (banner.type === 'category') {
    defaultCatValue = getCatPath(banner.data, cats, []);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  return <div className="config-item  config-label-select-1 config-banner-nav">
    <Select
      value={banner.type}
      onChange={(value) => {
        onChangeType(value, index, upBannerIndex)
      }}
    >
      <Option value="product">商品</Option>
      <Option value="coupon">优惠券</Option>
      <Option value="live">直播</Option>
      <Option value="category">分类</Option>
      <Option value="page">页面</Option>
      <Option value="none">无跳转</Option>
    </Select>
    {banner.type === 'category' && <Cascader defaultValue={defaultCatValue} onChange={(value) => {
      onChangeCat(value, index, upBannerIndex)
    }} displayRender={(label) => {
      return label[label.length - 1];
    }} options={cats} changeOnSelect placeholder="选择小商店分类" notFoundContent={<div>暂无分类，请到小商店管理后台进行创建</div>}/>}
    {banner.type === "coupon" && <Select
      value={banner.data}
      placeholder="选择优惠券"
      onClick={updateCoupon}
      notFoundContent={<div>暂无优惠券，请到小商店管理后台进行创建</div>}
      onChange={(value) => {
        onChangeCoupon(value, index, upBannerIndex)
      }}
    >
      {coupons.map((coupon, index) => {
        return (
          <Option key={index} value={coupon.id}>
            {coupon.name}
          </Option>
        );
      })}
    </Select>}
    {banner.type === "product" && <Select
      placeholder="选择商品"
      onClick={() => {
        setProductsDialogVisible(true);
      }}
      value={banner.data ? banner.data.id : ''}
      notFoundContent={null}
      open={false}
    >
      {banner.data ? <Option  value={banner.data.id}>
        {banner.data.title}
      </Option> : null}
    </Select>}
    {banner.type === 'page' && <Select
      value={banner.data}
      placeholder="选择页面"
      notFoundContent={<div>暂无页面，请到模版中心进行创建</div>}
      onChange={(value) => {
        onChangeCoupon(value, index, upBannerIndex)
      }}
    >
      {pages.filter((p) => {
        return p.state !== 1
      }).map((page, index) => {
        return (
          <Option key={index} value={page.id}>
            {page.title}
          </Option>
        );
      })}
    </Select>}
    <GoodsSelector single={true} visible={isProductsDialogVisible} defaultGoods={banner.data ? [banner.data] : []} onConfirm={(goods) => {
      closeProductsDialog()
      onChangeGood(goods[0], index, upBannerIndex)
      console.log(goods[0])
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}

export default function Banner() {
  const { activeModule, changeModule } = useModules();
  const [aspects, setAspect] = useState([]);
  let [cats] = useShopCats();
  const [coupons, updateCoupon] = useCoupon();

  if (!activeModule) {
    return null;
  }

  const styleType = activeModule.formData.type || 1;

  const banners = activeModule.formData.banners || [];

  const bannersUpDownStl = activeModule.formData.up_banners || [];

  cats = processShopCats(cats);

  function addBanner() {
    const _banners = [...banners];
    _banners.push(newDefaultBanner());
    activeModule.formData.banners = _banners;
    changeModule(activeModule);
  }

  function addBannerUpDownStl() {
    const _banners = [...bannersUpDownStl];
    _banners.push(newDefaultBannerUpDownStl());
    activeModule.formData.up_banners = _banners;
    changeModule(activeModule);
  }

  function addBannerUpDownStlHalf() {
    const _banners = [...bannersUpDownStl];
    _banners.push(newDefaultBannerUpDownStlHalf());
    activeModule.formData.up_banners = _banners;
    changeModule(activeModule);
  }

  function removeBanner(index) {
    let _banners = [...banners];
    _banners.splice(index, 1);
    if (_banners.length === 0) {
      _banners = [newDefaultBanner()];
      setAspect([])
    }
    activeModule.formData.banners = _banners;
    changeModule(activeModule);
  }

  function removeBannerUpDownStl(index) {
    let _banners = [...bannersUpDownStl];
    _banners.splice(index, 1);
    if (_banners.length === 0) {
      _banners = [newDefaultBannerUpDownStl()];
      setAspect([])
    }
    activeModule.formData.up_banners = _banners;
    changeModule(activeModule);
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function handleUploadChange(info, index) {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      const _banners = [...banners];
      _banners[index].image = url;
      activeModule.formData.banners = _banners;
      changeModule(activeModule);
    } else if (info.file.status === 'error') {
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  function handleUpBannerUploadChange(info, bannerIndex, index) {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      let _banners = [...bannersUpDownStl];
      _banners[bannerIndex].banners[index].image = url;
      activeModule.formData.up_banners = _banners;
      changeModule(activeModule);
    } else if (info.file.status === 'error') {
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  function onChangeType(value, index, upBannerIndex) {
    if (typeof upBannerIndex === 'undefined') {
      changeValue(`banners[${index}].type`, value);
      changeValue(`banners[${index}].data`, '');
    } else {
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].type`, value);
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].data`, '');
    }
  }

  function onChangeCoupon(value, index, upBannerIndex) {
    if (typeof upBannerIndex === 'undefined') {
      changeValue(`banners[${index}].data`, value);
    } else {
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].data`, value);
    }
  }

  function onChangeCat(value, index, upBannerIndex) {
    const catId = value[value.length - 1];
    if (typeof upBannerIndex === 'undefined') {
      changeValue(`banners[${index}].data`, catId);
    } else {
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].data`, catId);
    }
  }

  function onChangeGood(value, index, upBannerIndex) {
    console.log(value)
    if (typeof upBannerIndex === 'undefined') {
      changeValue(`banners[${index}].data`, value);
      changeValue(`banners[${index}].source`, value.source || 1);
    } else {
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].data`, value);
      changeValue(`up_banners[${upBannerIndex}].banners[${index}].source`, value.source || 1);
    }
  }

  return <div className="config-banner">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-cards">
        <div className={classnames('config-cards-item', {
          disable: styleType !== 1
        })} onClick={() => {
          if (styleType !== 1) {
            setAspect([]);
          }
          changeValue('type', 1)
        }}>
          <div className="desc">左右轮播展示</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', {
          disable: styleType !== 2
        })} onClick={() => {
          if (styleType !== 2) {
            setAspect([]);
          }
          changeValue('type', 2)
        }}>
          <div className="desc">上下拼接展示</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
      </div>
    </div>
    {styleType === 2 && bannersUpDownStl.map((upBanners, upBannerIndex) => {
      if (upBanners.banners.length === 1) {
        const banner = upBanners.banners[0];
        return <div className="common-config-block">
          <div className="header">
            <div className="label">添加 banner</div>
            <div className="pending"></div>
            <div className="opt" onClick={() => {
              removeBannerUpDownStl(upBannerIndex);
            }}>移除</div>
          </div>
          <div className="config-upload-large">
            <Upload
              name="file"
              listType="picture-card"
              className="large-avatar-uploader"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              headers={{
                Authorization: "Bearer " + window.__token,
              }}
              onChange={(info) => {
                handleUpBannerUploadChange(info, upBannerIndex, 0);
              }}
            >
              {banner.image ? <img style={{
                width: '340px',
                height: 'auto'
              }} src={banner.image} /> : <div>
                  {banner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>
          </div>
          <div className="header" style={{
            marginTop: 20
          }}>
            <div className="label light">跳转至</div>
          </div>
          <BannerNav banner={banner}  cats={cats} coupons={coupons} onChangeCat={onChangeCat} onChangeType={onChangeType} upBannerIndex={upBannerIndex} index={0} updateCoupon={updateCoupon} onChangeCoupon={onChangeCoupon}  onChangeGood={onChangeGood} />
        </div>
      }
      const leftBanner = upBanners.banners[0];
      const rightBanner = upBanners.banners[1];
      return <div className="common-config-block">
        <div className="header">
          <div className="label">添加半屏 banner</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removeBannerUpDownStl(upBannerIndex);
          }}>移除</div>
        </div>
        <div className="config-upload-half">
          {aspects[upBannerIndex] ? <ImgCrop aspect={aspects[upBannerIndex]} quality={0.8}>
            <Upload
              name="file"
              listType="picture-card"
              className="half-avatar-uploader"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              headers={{
                Authorization: "Bearer " + window.__token,
              }}
              onChange={(info) => {
                handleUpBannerUploadChange(info, upBannerIndex, 0);
              }}
            >
              {leftBanner.image ? <img style={{
                width: '165px',
                height: 'auto'
              }} src={leftBanner.image} /> : <div>
                  {leftBanner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>
          </ImgCrop> : <Upload
            name="file"
            listType="picture-card"
            className="half-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={(info) => {
              handleUpBannerUploadChange(info, upBannerIndex, 0);
            }}
          >
              {leftBanner.image ? <img style={{
                width: '165px',
                height: 'auto'
              }} onLoad={(e) => {
                aspects[upBannerIndex] = e.currentTarget.width / e.currentTarget.height;
                setAspect([...aspects]);
              }} src={leftBanner.image} /> : <div>
                  {leftBanner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>}
          {aspects[upBannerIndex] ? <ImgCrop aspect={aspects[upBannerIndex]} quality={0.6}>
            <Upload
              name="file"
              listType="picture-card"
              className="half-avatar-uploader"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              headers={{
                Authorization: "Bearer " + window.__token,
              }}
              onChange={(info) => {
                handleUpBannerUploadChange(info, upBannerIndex, 1);
              }}
            >
              {rightBanner.image ? <img style={{
                width: '165px',
                height: 'auto'
              }} src={rightBanner.image} /> : <div>
                  {rightBanner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>
          </ImgCrop> : <Upload
            name="file"
            listType="picture-card"
            className="half-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={(info) => {
              handleUpBannerUploadChange(info, upBannerIndex, 1);
            }}
          >
              {rightBanner.image ? <img onLoad={(e) => {
                aspects[upBannerIndex] = e.currentTarget.width / e.currentTarget.height;
                setAspect([...aspects]);
              }} style={{
                width: '165px',
                height: 'auto'
              }} src={rightBanner.image} /> : <div>
                  {rightBanner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>}
        </div>
        <div className="header" style={{
          marginTop: 20
        }}>
          <div className="label light">跳转至</div>
        </div>
        <BannerNav banner={leftBanner} cats={cats}  onChangeCat={onChangeCat} upBannerIndex={upBannerIndex} index={0} coupons={coupons} onChangeType={onChangeType} updateCoupon={updateCoupon} onChangeCoupon={onChangeCoupon}  onChangeGood={onChangeGood} />
        <BannerNav banner={rightBanner} cats={cats}  onChangeCat={onChangeCat} upBannerIndex={upBannerIndex} index={1} coupons={coupons} onChangeType={onChangeType} updateCoupon={updateCoupon} onChangeCoupon={onChangeCoupon}  onChangeGood={onChangeGood} />
      </div>
    })}
    {styleType === 1 && banners.map((banner, index) => {
      return <div className="common-config-block" key={index}>
        <div className="header">
          <div className="label">添加 banner</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removeBanner(index);
          }}>移除</div>
        </div>
        <div className="config-upload-large">
          {aspects[0] ? <ImgCrop aspect={aspects[0]} quality={0.6}>
            <Upload
              name="file"
              listType="picture-card"
              className="large-avatar-uploader"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              headers={{
                Authorization: "Bearer " + window.__token,
              }}
              onChange={(info) => {
                handleUploadChange(info, index);
              }}
            >
              {banner.image ? <img style={{
                width: '340px',
                height: 'auto'
              }} src={banner.image} /> : <div>
                  {banner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>
          </ImgCrop> : <Upload
            name="file"
            listType="picture-card"
            className="large-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={(info) => {
              handleUploadChange(info, index);
            }}
          >
              {banner.image ? <img style={{
                width: '340px',
                height: 'auto'
              }} src={banner.image} onLoad={(e) => {
                aspects[index] = e.currentTarget.width / e.currentTarget.height;
                setAspect([...aspects]);
              }} /> : <div>
                  {banner.loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>}
        </div>
        <div className="header" style={{
          marginTop: 20
        }}>
          <div className="label light">跳转至</div>
        </div>
        <BannerNav banner={banner} cats={cats} index={index}  coupons={coupons} onChangeCat={onChangeCat} onChangeType={onChangeType} updateCoupon={updateCoupon} onChangeCoupon={onChangeCoupon}  onChangeGood={onChangeGood} />
      </div>
    })}
    <div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
      {styleType === 1 && <div className="common-config-btn" onClick={addBanner}>
        <img className="icn" src="/icn-add-big-green@3x.png" />
        <span className="desc">新增 banner</span>
      </div>}
      {styleType === 2 && <div className="common-config-btn" onClick={addBannerUpDownStl}>
        <img className="icn" src="/icn-add-big-green@3x.png" />
        <span className="desc">新增 banner</span>
      </div>}
      {styleType === 2 && <div className="common-config-btn" onClick={addBannerUpDownStlHalf} style={{
        marginLeft: '16px'
      }}>
        <img className="icn" src="/icn-add-big-green@3x.png" />
        <span className="desc">新增半屏 banner</span>
      </div>}
    </div>
    {/* <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div> */}
  </div>
}
