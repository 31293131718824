import React from "react";

import './style.css';

function Contact() {
  return (
    <div className={'contact-page'}>
      <div className={'main'}>
        <div className="left-block">
          <div className='desc'>扫码添加客服微信</div>
          <div className="tip">欢迎提出宝贵建议</div>
          <img src="/pic-wechat-kf@3x.png"/>
        </div>
        <div className="right-block">
          <h2>常见问题</h2>
          <h3>Q：为什么点击发布上线后，我的小商店内没有生效？</h3>
          <p>1、首先确认您发布上线时，在手机微信内收到了启用通知（企业店铺会发到店长微信上）</p>
          <p>2、启用通知会发到微信聊天列表中：服务通知-装修模板启用</p>
          <p>3、进入启用通知后会看到「确认启用」按钮，需要您点击确认下</p>
          <p>4、启用成功时会提示在「30 分钟内生效」</p>
          <h3>Q：点击启用通知时提示「启用失败」怎么办？</h3>
          <p>1、启用通知需要再 30 分钟点击才会有效，启用失败是由于消息过期了</p>
          <p>2、您在此页面顶部点击「停用服务」后再点击「启用服务」，会重新收到启用通知</p>
          <h3>Q：试用版可以正常发布上线吗？</h3>
          <p>1、试用版可正常使用，功能点当前与专业版一致。试用满意可购买专业版，会更加稳定</p>
          <h3>Q：服务到期后会发生什么？</h3>
          <p>1、服务到期后会自动返回为小商店默认样式，建议提前购买服务时长</p>
          <p>2、旺铺助手后台为您保留了现有配置，服务再次购买后点击发布可一键发布上线</p>
          <h3>Q：我需要开具发票，该怎么申请？</h3>
          <p>1、可以开具发票，您这边在左侧扫码联系客服申请即可。</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
