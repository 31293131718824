
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useLocalStorage } from 'react-use';
import Rodal from "rodal";
import {Prompt} from 'react-router-dom';
import './style.css';

import ModuleSelector from './components/ModuleSelector';
import ModulePreview from './components/ModulePreview';
import ModuleConfig from './components/ModuleConfig';
import FeedbackBar from 'components/FeedbackBar'

import {useActivePageModule, useModules} from 'services/home';
import {useServiceStatus} from 'services/store';

const FEATURE_NAME = 'feature-7'

export default function RenovationHome() {
  const [storage, setStorage] = useLocalStorage('storage', {});
  const [isFeatureTipShow, changeFeatureTipShow] = useState(!storage[FEATURE_NAME]);
  const {activeModule, setTemplate} = useModules();
  const { info } = useServiceStatus();

  const showFeedback = _.get(info, 'feedback_tip', false);

  useActivePageModule();

  useEffect(() => {
    return () => {
      setTemplate({
        id: null,
        modules: []
      })
    }
  }, [])

  return <div className="renocation-home">
    <Prompt when={!!activeModule} message={() =>
      `离开当前页面将丢弃未发布的修改，确认离开？`
    }/>
    <div className="main-block">
      <div className="module-selector">
        <ModuleSelector />
      </div>
      <div className="module-preview">
        {showFeedback ? <FeedbackBar /> : null}
        <ModulePreview />
      </div>
    </div>
    <div className="config-block">
      <ModuleConfig />
    </div>
    <Rodal
      visible={isFeatureTipShow}
      onClose={() => {
        changeFeatureTipShow(false)
        storage[FEATURE_NAME] = true;
        setStorage(storage)
      }}
      width={600}
      height={520}
    >
      <div className='confirmDialog'>
        <img className='icn' src="/icn-new-function@2x.png" />
        <div className='title'>会员现已支持分组管理</div>
        <div className='desc'>
          <p>1、可根据会员等级设置不同权益</p>
          <p>2、可对已发放的权益进行查看和管理</p>
        </div>
        <div className='btns'>
          <div className='btn' onClick={() => {
            changeFeatureTipShow(false)
            storage[FEATURE_NAME] = true;
            setStorage(storage)
          }}>确定</div>
        </div>
      </div>
    </Rodal>
  </div>
}