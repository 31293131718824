import React from "react";
import _ from 'lodash';
import './style.css';
import { useModules, useCoupon } from 'services/home';
import { ChromePicker } from 'react-color';
import { Input, Select, Dropdown, Button } from "antd";
import { DownOutlined } from '@ant-design/icons';
const { Option } = Select;


function newDefaultItem() {
  return {
    id: '',
    type: 1,
    trigger: "",
    cut: "",
  };
}

function defaultCouponName(coupon) {
  if (coupon.name) {
    return coupon.name;
  }
  if (coupon.type < 100) {
    return '部分商品可用'
  } else {
    return '全部商品通用'
  }
}

const notFoundContent = <div>暂无优惠券，请到小商店管理后台进行创建</div>

export default function Coupon() {
  const { activeModule, changeModule } = useModules();
  const [coupons, updateCoupon] = useCoupon();

  if (!activeModule) {
    return null;
  }

  function addItem() {
    activeModule.formData.coupons.push(newDefaultItem());
    changeModule(activeModule);
  }

  function removeItem(index) {
    let items = activeModule.formData.coupons;
    items.splice(index, 1);
    if (items.length === 0) {
      items = [newDefaultItem()];
    }
    activeModule.formData.coupons = items;
    changeModule(activeModule);
  }

  function changeValue(path, value) {
    const formData = { ...activeModule.formData };
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  if (!activeModule) {
    return null;
  }

  return <div className="config-coupon">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-cards">
        <div className="config-cards-item">
          <div className="desc">基础样式</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className="config-cards-item disable">
          <div className="desc">更多样式即将开放</div>
        </div>
      </div>
    </div>
    {activeModule.formData.coupons.map((coupon, index) => {
      return <div className="common-config-block">
        <div className="header">
          <div className="label">添加优惠券</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removeItem(index);
          }}>移除</div>
        </div>
        <div className="config-item config-label-select-1">
          <div className="label">选择券</div>
          <Select
            value={coupon.id}
            style={{ width: "100%" }}
            notFoundContent={notFoundContent}
            onClick={updateCoupon}
            onChange={(id) => {
              const coupon = coupons.find((item) => {
                return item.id === id;
              });
              changeValue(`coupons[${index}].type`, coupon.type);
              changeValue(`coupons[${index}].name`, defaultCouponName(coupon));
              changeValue(
                `coupons[${index}].id`,
                id
              );
              const discountInfo = coupon.discount_info;
              if (discountInfo.cut) {
                changeValue(
                  `coupons[${index}].cut`,
                  discountInfo.cut
                );
              }
              if (discountInfo.trigger) {
                changeValue(
                  `coupons[${index}].trigger`,
                  discountInfo.trigger
                );
              }
            }}
          >
            {coupons.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="config-item config-label-select-1">
          <div className="label">券名称</div>
          <Input
            value={coupon.name}
            placeholder="填写优惠券名称"
            onChange={(e) => {
              changeValue(
                `coupons[${index}].name`,
                e.currentTarget.value
              );
            }}
          />
        </div>
        {[1, 101].indexOf(coupon.type) > -1 && <div className="config-item config-label-input-2">
          <div className="label">优惠详情</div>
          <Input
            type="number"
            min={2}
            prefix="满"
            suffix="件"
            value={coupon.trigger}
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseInt(v);
                if (isNaN(v)) {
                  v = "";
                }
              }
              changeValue(
                `coupons[${index}].trigger`,
                v
              );
            }}
          />
          <Input
            type="number"
            min={1}
            max={9.9}
            prefix="打"
            suffix="折"
            value={coupon.cut}
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseFloat(v);
                if (isNaN(v)) {
                  v = "";
                } else if (v > 9.9) {
                  v = 9.9;
                } else {
                  v = Math.floor(v * 10) / 10
                }
              }
              changeValue(`coupons[${index}].cut`, v);
            }}
          />
        </div>}
        {[2, 102].indexOf(coupon.type) > -1 && <div className="config-item config-label-input-2">
          <div className="label">优惠详情</div>
          <Input
            type="number"
            min={0}
            prefix="满"
            suffix="元"
            value={coupon.trigger}
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseInt(v);
                if (isNaN(v)) {
                  v = "";
                }
              }
              changeValue(
                `coupons[${index}].trigger`,
                v
              );
            }}
          />
          <Input
            type="number"
            min={0}
            prefix="减"
            suffix="元"
            value={coupon.cut}
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseInt(v);
                if (isNaN(v)) {
                  v = "";
                }
              }
              changeValue(`coupons[${index}].cut`, v);
            }}
          />
        </div>}
        {[4, 104].indexOf(coupon.type) > -1 && <div className="config-item config-label-select-1">
          <div className="label">优惠详情</div>
          <Input
            type="number"
            min={0}
            prefix="直减"
            suffix="元"
            value={coupon.cut}
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseInt(v);
                if (isNaN(v)) {
                  v = "";
                }
              }
              changeValue(`coupons[${index}].cut`, v);
            }}
          />
        </div>}
        {[3, 103].indexOf(coupon.type) > -1 && <div className="config-item config-label-select-1">
          <Input
            type="number"
            min={1}
            max={9.9}
            value={coupon.cut}
            suffix="折"
            onChange={(e) => {
              let v = e.currentTarget.value;
              if (v) {
                v = parseFloat(v);
                if (isNaN(v)) {
                  v = "";
                } else if (v > 9.9) {
                  v = 9.9;
                } else {
                  v = Math.floor(v * 10) / 10
                }
              }
              changeValue(`coupons[${index}].cut`, v);
            }}
          />
        </div>}
      </div>
    })}
    <div className="common-config-btn" onClick={addItem}>
      <img className="icn" src="/icn-add-big-green@3x.png" />
      <span className="desc">新增分类</span>
    </div>
    <div className="common-column-2">
      <div className="common-config-block" style={{
        marginTop: 30
      }}>
        <div className="header">
          <div className="label">优惠券颜色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }} />}>
            <Button>
              <span style={{ marginRight: 40 }}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-config-block" style={{
        marginTop: 30
      }}>
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.module_color} onChange={(color) => {
            changeValue(`style.module_color`, color.hex);
          }} />}>
            <Button>
              <span style={{ marginRight: 40 }}>{activeModule.formData.style.module_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}