
import React, { useState } from "react";
import classnames from 'classnames';
import {useTemplates, getTemplate, createTemplatePage} from 'services/home';
import {useSingleExec} from 'utils/functional';
import { useHistory } from "react-router-dom";
import {Spin} from 'antd';

import './style.css';

export default function Templates() {
  const templates = useTemplates();
  const history = useHistory();
  const [activeId, setActiveId] = useState('');

  const newPage = useSingleExec(async function(e) {
    const {id} = e.currentTarget.dataset;
    setActiveId(id);
    const pageData = {
      templates: [],
      publish: false
    }
    if (id) {
      pageData.templates = (await getTemplate(id)).data.data;
    }
    const page = createTemplatePage(pageData.templates)
      history.push(`/renovation/pages/${page.id}`)
  }, {
    onComplete: () => {
      setActiveId('');
    }
  })

  return <div className="renocation-templates">
    <div className="list">
      <div className="item new-item" onClick={newPage}>
        <div className="content">
          <div className="icn">
            <img src="/icn-add@3x.png"/>
          </div>
          <div className="desc">新建页面</div>
        </div>
      </div>
      {templates.map((template, index) => {
        return <div className={classnames("item template-item", {
          active: activeId === template.id
        })} key={index} data-id={template.id} onClick={newPage}>
          <div className="content">
            <img className="cover" src={template.image}/>
            <div className="block">
              <div className="title">{template.title || '未命名'}</div>
              <view className="btn">选用</view>
            </div>
          </div>
          <div className="loading">
            <Spin />
          </div>
        </div>
      })}
    </div>
  </div>
}