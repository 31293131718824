import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules, useCoupon } from 'services/home';
import { Input, Select, Upload, message, Dropdown, Button } from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import { ChromePicker } from 'react-color';

import './style.css';

const { Option } = Select;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  console.log('size', file.size)
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default function AdWechat() {
  const { activeModule, changeModule } = useModules();
  const [loading, setLoading] = useState(false);

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      setLoading(false);
      changeValue('contact.qrcode', url);
      changeValue('_method', 'qrcode');
    } else if (info.file.status === 'error') {
      setLoading(false);
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  return <div className="config-ad-wechat">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">添加方式</div>
      </div>
      <div className="config-item config-cards">
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 1
        })} onClick={() => {
          changeValue('type', 1);
          changeValue('desc', '店主想和你成为好友');
          changeValue('content', '加好友进群有专属福利呦');
          changeValue('contact.qrcode', '');
        }}>
          <div className="desc">复制微信号添加</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 2
        })} onClick={() => {
          changeValue('type', 2)
          changeValue('desc', '欢迎进群交流');
          changeValue('content', '更有不定时福利哟~');
          changeValue('_method', 'qrcode')
          changeValue('contact.qrcode', '');
        }}>
          <div className="desc">进微信群</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 3
        })} onClick={() => {
          changeValue('type', 3)
          changeValue('desc', '欢迎关注官方公众号');
          changeValue('content', '进入公众号与我们互动~');
          changeValue('contact.qrcode', '');
        }}>
          <div className="desc">关注公众号</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 4
        })} onClick={() => {
          changeValue('type', 4)
          changeValue('desc', '联系客服');
          changeValue('content', '欢迎随时咨询');
          changeValue('contact.qrcode', '');
        }}>
          <div className="desc">联系客服</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
      </div>
    </div>
    {/* 加微信好友 */}
    {activeModule.formData.type === 1 && <>
      <div className="common-config-block">
        <div className="header">
          <div className="label">添加方式</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData._method} onChange={(value) => {
            changeValue(`_method`, value)
          }}>
            <Option value={'text'}>复制微信号添加</Option>
            <Option value={'qrcode'}>扫二维码添加</Option>
          </Select>
        </div>
      </div>
      {activeModule.formData._method === 'text' && <div className="common-config-block">
        <div className="header">
          <div className="label">填写微信号</div>
        </div>
        <div className="config-item config-input-1">
          <Input placeholder="请输入微信号" value={activeModule.formData.contact.text} onChange={(e) => {
            changeValue('contact.text', e.currentTarget.value)
          }} />
        </div>
      </div>}
      {activeModule.formData._method === 'qrcode' && <div className="common-config-block">
        <div className="header">
          <div className="label">二维码图片</div>
        </div>
        <div className="config-upload-large">
          <Upload
            name="file"
            listType="picture-card"
            className="large-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={handleChange}
          >
            {activeModule.formData.contact.qrcode ? <img style={{
              width: '340px',
              height: 'auto'
            }} src={activeModule.formData.contact.qrcode} /> : <div>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                    <PlusOutlined />
                  )}
                <div style={{ marginTop: 7 }}>添加图片</div>
              </div>}
          </Upload>
        </div>
      </div>}
    </>}
    {/* 进微信群 */}
    {activeModule.formData.type === 2 && <>
      {<div className="common-config-block">
        <div className="header">
          <div className="label">二维码图片</div>
        </div>
        <div className="config-upload-large">
          <Upload
            name="file"
            listType="picture-card"
            className="large-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={handleChange}
          >
            {activeModule.formData.contact.qrcode ? <img style={{
              width: '340px',
              height: 'auto'
            }} src={activeModule.formData.contact.qrcode} /> : <div>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                    <PlusOutlined />
                  )}
                <div style={{ marginTop: 7 }}>添加图片</div>
              </div>}
          </Upload>
        </div>
      </div>}
    </>}
    {/* 关注公众号 */}
    {activeModule.formData.type === 3 && <>
      <div className="common-config-block">
        <div className="header">
          <div className="label">添加方式</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData._method} onChange={(value) => {
            changeValue(`_method`, value)
          }}>
            <Option value={'text'}>复制公众号添加</Option>
            <Option value={'qrcode'}>扫二维码添加</Option>
          </Select>
        </div>
      </div>
      {activeModule.formData._method === 'text' && <div className="common-config-block">
        <div className="header">
          <div className="label">填写公众号</div>
        </div>
        <div className="config-item config-input-1">
          <Input placeholder="请输入公众号" value={activeModule.formData.contact.text} onChange={(e) => {
            changeValue('contact.text', e.currentTarget.value)
          }} />
        </div>
      </div>}
      {activeModule.formData._method === 'qrcode' && <div className="common-config-block">
        <div className="header">
          <div className="label">二维码图片</div>
        </div>
        <div className="config-upload-large">
          <ImgCrop aspect={1} quality={0.6}>
            <Upload
              name="file"
              listType="picture-card"
              className="large-avatar-uploader"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              headers={{
                Authorization: "Bearer " + window.__token,
              }}
              onChange={handleChange}
            >
              {activeModule.formData.contact.qrcode ? <img style={{
                width: '340px',
                height: 'auto'
              }} src={activeModule.formData.contact.qrcode} /> : <div>
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                      <PlusOutlined />
                    )}
                  <div style={{ marginTop: 7 }}>添加图片</div>
                </div>}
            </Upload>
          </ImgCrop>
        </div>
      </div>}
    </>}
    {/* 联系客服 */}
    {activeModule.formData.type === 4 && <>
      {<div className="common-config-block">
        <div className="header">
          <div className="label">输入手机号</div>
        </div>
        <div className="config-item config-input-1">
          <Input placeholder="请输入手机号" type="tel" value={activeModule.formData.contact.text} onChange={(e) => {
            const { value } = e.target;
            const reg = /^\d*$/;
            if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
              changeValue('contact.text', e.currentTarget.value)
            }
          }} />
        </div>
      </div>}
    </>}
    <div className="common-config-block">
      <div className="header">
        <div className="label light">主引导文案</div>
      </div>
      <div className="config-item config-input-1">
        <Input placeholder="请输入微信号" value={activeModule.formData.desc} onChange={(e) => {
          changeValue('desc', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label light">次引导文案</div>
      </div>
      <div className="config-item config-input-1">
        <Input placeholder="请输入微信号" value={activeModule.formData.content} onChange={(e) => {
          changeValue('content', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-column-2">
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }} />}>
            <Button>
              <span style={{ marginRight: 40 }}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
