import React, {useEffect} from 'react';
import { useUserSession } from "services/user";
import { useHistory } from "react-router-dom";

function Logout() {
  const [session, setSession] = useUserSession();
  const history = useHistory();

  useEffect(() => {
    if (session) {
      setSession(null);
    } else {
      history.replace('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);
  return (
      <div>正在退出...</div>
  );
}

export default Logout;