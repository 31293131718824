import React from "react";
import classnames from 'classnames';
import { amountStr, isColorDark } from 'utils/tools';
import './style.css';

export default function Goods({ module }) {
  const formData = module.formData || {};
  const { style = {}, products = [] } = formData;
  const isDark = isColorDark(style.module_color)
  return <div className={classnames('preview-discount-goods', {
    dark: isDark
  })} style={{
    backgroundColor: style.module_color
  }}>
    <div className="header">
      <span className="label">限时抢购</span>
    </div>
    <div className="list">
      {products.length === 0 && <img style={{
        width: '336px'
      }} src="/icn-cutdown-default.svg"/>}
      {products.map((item, index) => {
        return <div className='item' key={index}>
          <div className="product">
            <img className="cover" src={item.image} />
            <div className="content">
              <div className="title">微信周边微信周边微信周边微信微信周边微信周边微信周边微信</div>
              <div className="countdown">
                <text>距抢购结束 </text>
                <text>00:00:00</text>
              </div>
              <div className="pending"></div>
              <div className="block">
                <div className="price">
                  <text className="current">￥{item.discount_price / 100}</text>
                  <text className="origin">￥{item.origin_price / 100}</text>
                </div>
                <div className="buy-btn">
                  <img src="/icn-flash-sale-small@3x.png" className="icn" />
                  <text>立即抢购</text>
                </div>
              </div>
            </div>
          </div>
        </div>
      })}
    </div>
  </div>
}
