import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useWindowSize } from 'react-use';
import classnames from 'classnames';
import _ from 'lodash';
import './style.css';
import Rodal from "rodal";
import { useDiscountGoods } from 'services/home';
import { Input, Button, Spin } from 'antd';
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import checkIcon from './icn-checked-popup@3x.png';
import { useSingleExec } from 'utils/functional';


export default function GoodsSelector({single = false, visible = false, onClose = () => { }, defaultGoods = [], onConfirm = () => {} }) {
  const [] = useState(false);
  const [keyword, setKeyword] = useState('');
  const searchRef = useRef(null);
  const { goods, loading, hasMore, page, nextGoods, prevGoods, updateGoods, searchGoods, cancelSearch, isSearch } = useDiscountGoods();
  const { width, height } = useWindowSize();
  const dialogWidth = Math.min(width * 0.8, 800);
  const dialogHeight = Math.min(height * 0.8, 600);
  const [selectGoods, setSelectGoods] = useState([]);

  const toggleGoods = useCallback((good) => {
    let _selectGoods = [...selectGoods];
    const index = _selectGoods.findIndex((item) => {
      return item.id === good.id;
    });
    if (index > -1) {
      _selectGoods.splice(index, 1)
    } else {
      if (single) {
        _selectGoods = [good];
      } else {
        _selectGoods.push(good);
      }
    }
    setSelectGoods(_selectGoods)
  }, [selectGoods, single])

  useEffect(() => {
    setSelectGoods(defaultGoods)
  }, [defaultGoods])

  useEffect(() => {
    if (visible) {
      updateGoods()
      setKeyword('');
    }
  }, [visible])

  const userSearchGoods = useSingleExec(async function() {
    if (keyword) {
      await searchGoods(keyword)
    }
  }, {}, [keyword])

  return <Rodal
    visible={visible}
    onClose={onClose}
    width={dialogWidth}
    height={dialogHeight}
    showCloseButton={false}
  >
    <div className='discount-goods-selector-dialog'>
      {loading && <div className="loading">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
      </div>}
      <div className='header'>
        <div className="name">选择要添加的限时抢购活动</div>
      </div>
      <div className="goods">
        {goods.map((item, index) => {
          const selected = !!selectGoods.find((selectItem) => {
            return selectItem && item && selectItem.id === item.id;
          })
          return <div className={classnames('item', {
            selected: selected
          })} key={index} onClick={() => {
            toggleGoods(item)
          }}>
            <img className="cover" src={item.image} />
            <div className="good-info">
              <div className="title">{item.title}</div>
              <div className="price">
                <span className="discount-price" style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.9)'
                }}>￥{(item.discount_price / 100).toFixed(2)}</span>
                <span className="origin-price" style={{
                  fontSize: 12,
                  color: 'rgba(0, 0, 0, 0.35)',
                  textDecoration: 'line-through',
                  marginTop: -1,
                  marginLeft: 4
                }}>￥{(item.origin_price / 100).toFixed(2)}</span>
              </div>
            </div>
            <img className='check' src={checkIcon} />
          </div>
        })}
      </div>
      <div style={{flex: '1 1 auto'}}> </div>
      <div className="footer">
        <div className="pag">
          <Button className="prev" disabled={page === 1} onClick={prevGoods}>上一页</Button>
          <div className="num">第 {page} 页</div>
          <Button className="next" disabled={!hasMore} onClick={nextGoods}>下一页</Button>
        </div>
        <div className="btns">
          <Button className="opt" onClick={onClose}>取消</Button>
          <Button className="opt primary" type="primary" onClick={() => {
            onConfirm(selectGoods)
          }}>确定</Button>
        </div>
      </div>
    </div>
  </Rodal>
}