import React, { useEffect } from "react";
import _ from "lodash";
import "moment/locale/zh-cn";
import "./App.less";
import { Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import { Result, Button, ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import BasicLayout from "layout/BasicLayout";

import RenovationHome from "pages/renovation/home";
import Manual from "pages/Manual";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Contact from 'pages/Contact';
import Templates from 'pages/Templates';
import RenovationPages from "pages/renovation/pages";
import RenovationPage from "pages/renovation/pages/detail";
import ActivityLotteryRecord from "pages/ActivityRecord/lottery";
import Member from "pages/Member/index";
import { useUserSession } from "services/user";
import { decodeQuery } from "utils/tools";
import request from "services/request";

function PrivateRoute({ children, ...rest }) {
  const [session, setSession] = useUserSession();
  const { search } = useLocation();
  const query = decodeQuery(search);
  const token = _.get(session, "token");
  if (token) {
    request.defaults.headers.common["Authorization"] = "Bearer " + token;
    window.__token = token;
  }
  useEffect(() => {
    if (query.code) {
      setSession(null);
    }
  }, [query.code, setSession]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (session && !query.code) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location, code: query.code },
              }}
            />
          );
        }
      }}
    />
  );
}

function App() {
  const { pathname, search } = useLocation();

  return (
    <ConfigProvider locale={zhCN}>
      <Switch>
        <Redirect
          exact
          from="/"
          to={{
            pathname: "/renovation/home",
            search,
          }}
        />
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route exact strict path="/login">
          <Login />
        </Route>
        <Route exact strict path="/logout">
          <Logout />
        </Route>
        <Route exact strict path="/404">
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Result
              status="404"
              title="404"
              subTitle="对不起，您访问的页面不存在."
              extra={
                <Button type="primary">
                  <Link to="/" replace>
                    回首页
                  </Link>
                </Button>
              }
            />
          </div>
        </Route>
        <Route>
          <BasicLayout>
            <Switch>
              <PrivateRoute exact strict path="/renovation/home">
                <RenovationHome />
              </PrivateRoute>
              <PrivateRoute exact strict path="/manual">
                <Manual />
              </PrivateRoute>
              <PrivateRoute exact strict path="/contact">
                <Contact />
              </PrivateRoute>
              <PrivateRoute exact strict path="/templates">
                <Templates />
              </PrivateRoute>
              <PrivateRoute exact strict path="/renovation/pages">
                <RenovationPages />
              </PrivateRoute>
              <PrivateRoute exact strict path="/renovation/pages/:id">
                <RenovationPage />
              </PrivateRoute>
              <PrivateRoute exact strict path="/activity/lottery">
                <ActivityLotteryRecord />
              </PrivateRoute>
              <PrivateRoute exact strict path="/member">
                <Member />
              </PrivateRoute>
              <Route>
                <Redirect to="/404" />
              </Route>
            </Switch>
          </BasicLayout>
        </Route>
      </Switch>
    </ConfigProvider>
  );
}

export default App;
