import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules } from 'services/home';
import { Input, Select, Dropdown, Button } from "antd";
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import GoodsSelector from 'components/GoodsSelector';

import './style.css';

const { Option } = Select;

const SortableItem = sortableElement(({ value, idx, type, onDelete, toggleGoodBig }) => {
  return <div className={classnames('config-product')}>
    <div className="main">
      <img className="cover" src={value.image} />
      <div className="name">{value.title}</div>
    </div>
    {type === 1 && <div className="sub">
      <div className={classnames('setbig', {
        'active': value.set_big
      })} onClick={() => {
        toggleGoodBig(value.id)
      }}>
        
      </div>
    </div>}
    <img className="del-icon highlight" src="/icn-delete-popup@3x.png" onClick={() => {
      onDelete(idx);
    }} />
  </div>
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default function Recommend() {
  const { activeModule, changeModule } = useModules();
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    let products = _.cloneDeep(activeModule.formData.products);
    products = arrayMove(products, oldIndex, newIndex)
    changeValue('products', products);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  function deleteProduct(index) {
    activeModule.formData.products.splice(index, 1);
    changeValue('products', activeModule.formData.products);
  }

  function toggleGoodBig(id) {
    const product = activeModule.formData.products.find((p) => {
      return p.id === id;
    })
    product.set_big = !product.set_big;
    changeValue('products', activeModule.formData.products);
  }


  return <div className="config-recommend">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-cards">
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 2
        })} onClick={() => {
          changeValue('type', 2)
        }}>
          <div className="label">列表展示</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.type !== 1
        })} onClick={() => {
          changeValue('type', 1)
        }}>
          <div className="label">宫格展示</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">模块标题</div>
      </div>
      <div className="config-item config-input-1">
        <Input placeholder="请输入模块标题" value={activeModule.formData.title} onChange={(e) => {
          changeValue('title', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label light">添加商品（拖动可排序）</div>
      </div>
      <div className='config-products'>
        <SortableContainer onSortEnd={onSortEnd} distance={1} >
          {activeModule.formData.products.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} idx={index} value={value} type={activeModule.formData.type} onDelete={deleteProduct} toggleGoodBig={toggleGoodBig}/>
          ))}
        </SortableContainer>
        <div className='add-product-btn highlight' onClick={() => {
          setProductsDialogVisible(true);
        }}>
          <img src="/icn-add-grey-small@2x.png" />
          <span>添加商品</span>
        </div>
      </div>
    </div>
    <div className="common-column-2">
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-config-block">
        <div className="header">
          <div className="label">购买按钮</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData.config.show_buy} onChange={(value) => {
            changeValue(`config.show_buy`, value)
          }}>
            <Option value={true}>显示</Option>
            <Option value={false}>不显示</Option>
          </Select>
        </div>
      </div>
    </div>
    {activeModule.formData.type === 2 && <div className="common-column-2">
      <div className="common-config-block">
        <div className="header">
          <div className="label">首页展示行数</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData.config.line_num} onChange={(value) => {
            changeValue(`config.line_num`, value)
          }}>
            <Option value={2}>展示 2 行</Option>
            <Option value={3}>展示 3 行</Option>
            <Option value={4}>展示 4 行</Option>
            <Option value={5}>展示 5 行</Option>
            <Option value={0}>全部展示</Option>
          </Select>
        </div>
      </div>
      <div className="common-config-block">
        <div className="header">
          <div className="label">排行标签</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData.config.show_rank} onChange={(value) => {
            changeValue(`config.show_rank`, value)
          }}>
            <Option value={true}>显示</Option>
            <Option value={false}>不显示</Option>
          </Select>
        </div>
      </div>
    </div>}
    {activeModule.formData.type === 1 && <div className="common-column-2">
      <div className="common-config-block">
        <div className="header">
          <div className="label">首页展示行数</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData.config.line_num} onChange={(value) => {
            changeValue(`config.line_num`, value)
          }}>
            <Option value={2}>展示 2 行</Option>
            <Option value={3}>展示 3 行</Option>
            <Option value={4}>展示 4 行</Option>
            <Option value={5}>展示 5 行</Option>
            <Option value={0}>全部展示</Option>
          </Select>
        </div>
      </div>
      <div className="common-config-block">
        <div className="header">
          <div className="label">每行展示数量</div>
        </div>
        <div className="config-item config-select-item">
          <Select value={activeModule.formData.config.per_line_num} onChange={(value) => {
            changeValue(`config.per_line_num`, value)
          }}>
            <Option value={2}>展示 2 个</Option>
            <Option value={3}>展示 3 个</Option>
          </Select>
        </div>
      </div>
    </div>}
    <GoodsSelector visible={isProductsDialogVisible} defaultGoods={activeModule.formData.products} onConfirm={(goods) => {
      changeValue(`products`, goods)
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}
