import React from "react";
import classnames from 'classnames';
import {colorHexToRgba} from 'utils/tools';

import './style.css';

export default function Coupon({ module }) {
  const formData = module.formData || {};
  const { coupons = [], style = {} } = formData;

  let type = 1;
  if (coupons.length === 1) {
    type = 1;
  } else if (coupons.length === 2 || coupons.length === 4) {
    type = 2;
  } else {
    type = 3;
  }

  return <div className="preview-tickets" style={{
    backgroundColor: style.module_color
  }}>
    <div className={classnames('list', `left-${coupons.length % 2}`, `type-${type}`)}>
      {coupons.map((item, index) => {
        return <div className="ticket" key={index}>
          <div className="content" style={{
            backgroundColor: colorHexToRgba(style.background_color, 0.1),
            color: style.background_color
          }}>
            <div className="name">{item.name}</div>
            <div className="cut">
              <span className="value">{item.cut}</span>
              {[1, 101, 3, 103].indexOf(item.type) > 0 ? <span className="unit">折</span> : <span className="unit">元</span>}
            </div>
            <div className="recv-btn" style={{
              backgroundColor: style.background_color,
            }}>领取</div>
          </div>
        </div>
      })}
    </div>
  </div>
}
