import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules, useRenovationPages, useCoupon } from 'services/home';
import { Input, Upload, message, Dropdown, Button, Select } from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined, DownOutlined } from "@ant-design/icons";
import GoodsSelector from 'components/GoodsSelector';
import { ChromePicker } from 'react-color';

import './style.css';

const Option = Select.Option;

function newDefaultCategory() {
  return {
    name: "",
    image: "",
    products: [],
    navigation: {
      type: 'products',
      data: ''
    }
  };
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

const SortableItem = sortableElement(({ value, idx, onDelete }) => {
  return <div className={classnames('config-product')}>
    <div className="main">
      <img className="cover" src={value.image} />
      <div className="name">{value.title}</div>
    </div>
    <img className="del-icon highlight" src="/icn-delete-popup@3x.png" onClick={() => {
      onDelete(idx);
    }} />
  </div>
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

export default function Category() {
  const { activeModule, changeModule } = useModules();
  const [loadings, setLoadings] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);
  const [dialogProducts, setDialogProducts] = useState([]);
  const { pages } = useRenovationPages();
  const [coupons, updateCoupon] = useCoupon();

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function removeCategory(index) {
    const formData = activeModule.formData;
    formData.data.categories.splice(index, 1);
    if (formData.data.categories.length === 0) {
      formData.data.categories = [newDefaultCategory()];
    }
    changeModule(activeModule);
  }

  function addCategory() {
    const formData = activeModule.formData;
    formData.data.categories.push(newDefaultCategory());
    changeModule(activeModule);
  }

  function onSortEnd({ oldIndex, newIndex, collection }) {
    console.log(collection)
    const formData = _.cloneDeep(activeModule.formData);
    let products = formData.data.categories[collection].products;
    products = arrayMove(products, oldIndex, newIndex)
    changeValue(`data.categories[${collection}].products`, products);
  }

  function deleteProduct(categoryIndex, productIndex) {
    activeModule.formData.data.categories[categoryIndex].products.splice(productIndex, 1);
    changeValue('data.categories', activeModule.formData.data.categories);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  function handleUploadChange(info, index) {
    if (info.file.status === "uploading") {
      loadings[index] = true;
      setLoadings([...loadings]);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      loadings[index] = false;
      setLoadings([...loadings]);
      const data = activeModule.formData.data;
      _.set(data, `categories[${index}].image`, url);
      changeValue('data', data);
    } else if (info.file.status === "error") {
      loadings[index] = false;
      setLoadings([...loadings]);
      const msg = _.get(info, "file.response.error.message");
      if (msg) {
        message.error(msg);
      }
    }
  }

  return <div className="config-category">
    <div className="common-config-block" style={{
      marginBottom: 10
    }}>
      <div className="header">
        <div className="label">选择样式</div>
      </div>
      <div className="config-item config-cards">
        <div className={classnames('config-cards-item', {
          disable: activeModule.formData.styleType !== 't1'
        })} onClick={() => {
          changeValue('styleType', 't1')
        }}>
          <div className="desc">宫格入口展示</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
        <div className={classnames('config-cards-item', 'disable')}>
          <div className="desc">更多模式即将开放</div>
          <img src="/icn-checked-popup-green@3x.png" className="check-icon" />
        </div>
      </div>
    </div>
    {activeModule.formData.data.categories.map((category, index) => {
      return <div className="common-config-block" key={index}>
        <div className="header">
          <div className="label">添加分类</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removeCategory(index);
          }}>移除</div>
        </div>
        <div className="config-item config-label-input-1">
          <div className="label">分类名称</div>
          <Input value={category.name} placeholder="填写分类名称" onChange={(e) => {
            changeValue(`data.categories[${index}].name`, e.currentTarget.value)
          }} />
        </div>
        {activeModule.formData.styleType === 't1' && <div className="config-item config-label-image">
          <div className="label">封面图片</div>
          <div className='upload'>
            <ImgCrop aspect={1} quality={1}>
              <Upload
                name="file"
                listType="picture-card"
                className="small-avatar-uploader"
                showUploadList={false}
                action="https://minastore.nocode.com/api/image"
                beforeUpload={beforeUpload}
                headers={{
                  Authorization: "Bearer " + window.__token,
                }}
                onChange={(info) => {
                  handleUploadChange(info, index);
                }}
              >
                {category.image ? <img style={{
                  width: '100%',
                  borderRadius: '8px'
                }} src={category.image} /> : <div>
                    {loadings[index] ? (
                      <LoadingOutlined />
                    ) : (
                        <PlusOutlined />
                      )}
                    <div style={{ marginTop: 7 }}>封面</div>
                  </div>}
              </Upload>
            </ImgCrop>
          </div>
        </div>}
        <div className="header" style={{
          marginTop: 20
        }}>
          <div className="label">跳转至</div>
        </div>
        <div className="config-item config-label-select-1 config-banner-nav">
          <Select
            style={{flex: '1 1 auto'}}
            value={category.navigation.type}
            onChange={(value) => {
              changeValue(`data.categories[${index}].navigation.data`, '');
              changeValue(`data.categories[${index}].navigation.type`, value)
              changeValue(`data.categories[${index}].products`, []);
            }}
          >
            <Option value="products">商品</Option>
            <Option value="live">直播</Option>
            <Option value="page">页面</Option>
            <Option value="none">无跳转</Option>
          </Select>
          {category.navigation.type === "coupon" && <Select
            value={category.navigation.data}
            placeholder="选择优惠券"
            onClick={updateCoupon}
            notFoundContent={<div>暂无优惠券，请到小商店管理后台进行创建</div>}
            onChange={(value) => {
              changeValue(`data.categories[${index}].navigation.data`, value)
            }}
          >
            {coupons.map((coupon, index) => {
              return (
                <Option key={index} value={coupon.id}>
                  {coupon.name}
                </Option>
              );
            })}
          </Select>}
          {category.navigation.type === 'page' && <Select
            value={category.navigation.data}
            placeholder="选择页面"
            notFoundContent={<div>暂无页面，请到模版中心进行创建</div>}
            onChange={(value) => {
              changeValue(`data.categories[${index}].navigation.data`, value)
            }}
          >
            {pages.filter((p) => {
              return p.state !== 1
            }).map((page, index) => {
              return (
                <Option key={index} value={page.id}>
                  {page.title}
                </Option>
              );
            })}
          </Select>}
        </div>
        {category.navigation.type === 'products' && <>
          <div className="config-item config-label-input-1">
            <div className="label">添加商品（拖动可排序）</div>
          </div>
          <div className='config-products'>
            <SortableContainer onSortEnd={onSortEnd} distance={1} >
              {category.products.map((value, productIndex) => (
                <SortableItem collection={index} key={`item-${value.id}`} index={productIndex} idx={productIndex} value={value} onDelete={() => {
                  deleteProduct(index, productIndex)
                }} />
              ))}
            </SortableContainer>
            <div className='add-product-btn highlight' onClick={() => {
              setActiveCategory(index);
              setDialogProducts(_.cloneDeep(activeModule.formData.data.categories[index].products));
              setProductsDialogVisible(true);
            }}>
              <img src="/icn-add-grey-small@2x.png" />
              <span>添加商品</span>
            </div>
          </div>
        </>}
      </div>
    })}
    <div className="common-config-btn" onClick={addCategory}>
      <img className="icn" src="/icn-add-big-green@3x.png" />
      <span className="desc">新增分类</span>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }} />}>
            <Button>
              <span style={{ marginRight: 40 }}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
    <GoodsSelector visible={isProductsDialogVisible} defaultGoods={dialogProducts} onConfirm={(goods) => {
      changeValue(`data.categories[${activeCategory}].products`, goods)
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}
