import {useState, useEffect} from 'react';
import {createGlobalState} from 'react-use';
import _ from 'lodash';
import url from "url";
import { useUserSession } from "./user";
import {message} from 'antd';
import request from './request';
import {newModule, MODULES, setActiveModule} from './module';
import {useSingleExec} from 'utils/functional';

const LOAD_GOODS_PAGE_SIZE = 10;

export async function getHome() {
  return request.get('/decoration/admin/page/home')
}

export async function getPage(id) {
  return request.get(`/decoration/admin/page/${id}`)
}

export async function managePage(id, data) {
  return request.post(`/decoration/admin/page/${id}/manage`, data);
}

export async function searchGoodsByKey(key, page = 1) {
  return request.post(`/admin/weixin/spu/search`, {
    page,
    page_size: LOAD_GOODS_PAGE_SIZE,
    keyword: key,
    source: 3
  })  
}

const LotteryRecordsPageSize = 10;

export async function getLotteryRecords(page, type) {
  return request.post(`/decoration/admin/lottery/record`, {
    page,
    type,
    page_size: LotteryRecordsPageSize
  });
}

export async function redeemLottery(data) {
  return request.post(`/decoration/admin/lottery/redeem`, data);
}

export async function updatePage(id, data) {
  return request.post(`/decoration/admin/page/${id}/update`, data);
}

export async function updatePageTitle(id, title) {
  return request.post(`/decoration/admin/page/${id}/update`, {
    title,
    publish: false
  });
}

let tempPageId = 1;

const tempPages = {
  
}

export function isTempPage(id) {
  return /^temp-\d+$/.test(id);
}

export function createTemplatePage(data, pid) {
  const id = pid || `temp-${tempPageId++}`;
  const template = {
    id,
    state: 0,
    templates: data,
    title: '未命名'
  }
  tempPages[id] = processPageData({
    data: {
      data: template
    }
  });
  return template;
}


export async function createPage(data) {
  return request.post(`/decoration/admin/page`, data);
}

export async function getTemplates() {
  return request.get('/decoration/admin/templates');
}

export async function getRenocvationPages(next) {
  return request.get(next || '/decoration/admin/page');
}


export async function getTemplate(id) {
  return request.get(`/decoration/admin/template/${id}`);
}

export async function getCouponList() {
  return request.post('/admin/weixin/coupon/get_list', {
    page: 1,
    page_size: 99
  }) 
}

export async function getGoodsList(data = {page: 1, page_size: 10}) {
  data.source = 3;
  return request.post('/admin/weixin/spu/get_list', data) 
}

export async function getDiscountGoodsList(data = {page: 1, page_size: 10}) {
  return request.get(`/decoration/admin/limited_discount?page=${data.page}`) 
}

export async function getShopCats() {
  return request.get('/admin/weixin/store/get_shopcat') 
}

const useGlobalModule = createGlobalState({
  id: null,
  modules: []
});

let isFetchingModules = false;

function processPageData(resp) {
  const template = {
    id: resp.data.data.id,
    title: resp.data.data.title,
    state: resp.data.data.state,
    modules: resp.data.data.templates.filter((template) => {
      return !!MODULES.find((m) => {
        return m.id === template.type;
      })
    }).map((template) => {
      const module = newModule(template.type);
      if (template.type === 'category') {
        if (_.get(template.data, 'data.categories[0].image')) {
          template.data.styleType = 't1'
        } else {
          template.data.styleType = 't2'
        }
      } else if (template.type === 'coupon') {
        if (!template.data.style) {
          template.data.style = {
            background_color: '#ec4b30'
          }
        }
      } else if (template.type === 'product_group') {
        if (!template.data.style) {
          template.data.style = {
            background_color: '#ffffff'
          }
        }
        if (!template.data.config) {
          template.data.config = {
            per_line_num: 2, // 每行展示数量
            line_num: 2, // 首页展示行数 0 为 无限制
            show_buy: false, // 展示购买按钮
            show_rank: true, // 展示排行标签
          }
        }
      } else if (template.type === 'add_wechat') {
        const contact = template.data.contact;
        if (contact.text) {
          template.data._method = 'text';
        } else if (contact.qrcode) {
          template.data._method = 'qrcode';
        }
      } else if (template.type === 'flash_cut') {
        template.data.products.forEach((product) => {
          product.max_cut = product.max_cut / 100;
        })
      }
      module.formData = _.assign(module.formData, template.data);
      module.mid = template.id;
      return module;
    })
  }
  return template;
}

export function usePageModule(id) {
  const [session] = useUserSession();
  const {setTemplate, modules} = useModules();
  const parts = url.parse(window.location.href, true);
  const code = _.get(parts, 'query.code');
  useEffect(() => {
    if (!session || code) {
      return;
    }
    if (id) {
      if (tempPages[id]) {
        setTemplate(tempPages[id])
        return;
      }
      getPage(id).then((resp) => {
        const template = processPageData(resp);
        setTemplate(template)
      }).catch((e) => {
        message.error(e.message);
        window.location.href = '/renovation/pages'
      })
    }
  }, [id, session, code])
  return {
    modules
  }
}

export function useActivePageModule() {
  const [id, setId] = useState(undefined);

  usePageModule(id);

  useEffect(() => {
    getHome().then((resp) => {
      const tid = resp.data.data.id;
      setId(tid || '');
    })
  }, []);

  return {
    pageId: id,
    setId
  }
}

export function useModules() {
  const [template, setTemplate] = useGlobalModule();

  function postProcessModules(m) {
    m = m || template.modules
    return m.map((module) => {
        const data = _.cloneDeep(module.formData);
        if (module.module.id === 'category') {
          const type = data.styleType;
          data.data.categories = data.data.categories.map((category) => {
            if (type === 't2') {
              delete category.image;
            }
            return category;
          })
        } else if (module.module.id === 'add_wechat') {
          if (data._method === 'text') {
            delete data.contact.qrcode;
          } else if (data._method === 'qrcode') {
            delete data.contact.text;
          }
        } else if (module.module.id === 'flash_cut') {
          data.products.forEach((product) => {
            product.max_cut = parseInt(product.max_cut * 100);
          })
        }
        const m = {
          type: module.module.id,
          data
        }
        if (module.mid) {
          m.id = module.mid
        }
        return m;
      })
  }

  async function saveTemplate(isPublish) {
    if (!template.id) {
      message.error('模版不存在');
      return;
    }
    const isTemp = isTempPage(template.id)
    let resp;
    if (isTemp) {
      resp = await createPage({
        publish: false,
        title: template.title,
        templates: postProcessModules(),
      })
    } else {
      resp = await updatePage(template.id, {
        templates: postProcessModules(),
        title: template.title,
        publish: isPublish
      })
    }
    const newtemplate = {
      id: resp.data.data.id,
      title: resp.data.data.title,
      state: resp.data.data.state,
      modules: resp.data.data.templates.map((template) => {
        const module = newModule(template.type);
        if (template.type === 'category') {
          if (_.get(template.data, 'data.categories[0].image')) {
            template.data.styleType = 't1'
          } else {
            template.data.styleType = 't2'
          }
        }
        module.formData = template.data;
        module.mid = template.id;
        return module;
      })
    }
    setTemplate(processPageData(resp));
    return resp;
  }

  function setModules(modules) {
    setTemplate({
      ...template,
      modules
    })
  }

  function changeModule(module) {
    let modules = _.cloneDeep(template.modules);
    const m = modules.find((m) => {
      return m.id === module.id;
    });
    _.assign(m, module);
    setModules(modules);
  }

  function deleteModule(id) {
    let modules = _.cloneDeep(template.modules);
    const index = modules.findIndex((module) => {
      return module.id === id;
    })
    modules.splice(index, 1);
    setModules(modules);
  }

  function addModule(id, pos) {
    let modules = _.cloneDeep(template.modules);
    const hasProductsModule = modules.findIndex((item) => {
      return item.module.id === 'products'
    }) !== -1;
    if (id === 'products') {
      const isExist = modules.find((item) => {
        return item.module.id === 'products'
      })
      if (isExist) {
        return message.warn('全部商品模块只能添加一个')
      }
    }
    if (id === 'live') {
      const isExist = modules.find((item) => {
        return item.module.id === 'live'
      })
      if (isExist) {
        return message.warn('直播模块只能添加一个')
      }
    }
    if (id === 'member') {
      const isExist = modules.find((item) => {
        return item.module.id === 'member'
      })
      if (isExist) {
        return message.warn('会员模块只能添加一个')
      }
    }
    if (id === 'share') {
      const isExist = modules.find((item) => {
        return item.module.id === 'share'
      })
      if (isExist) {
        return message.warn('分享模块只能添加一个')
      }
    }
    const m = newModule(id);
    if (typeof pos !== 'undefined') {
      modules.splice(pos, 0, m);
    } else if (hasProductsModule) {
      modules.splice(modules.length - 1, 0, m);
    } else {
      modules.push(m);
    }
    setActiveModule(modules, m.id);
    setModules(modules);
  }

  const activeModule = (template.modules || []).find((item) => {
    return item.active;
  });

  return {
    template,
    modules: template.modules,
    activeModule,
    setTemplate,
    addModule,
    setModules,
    changeModule,
    deleteModule,
    saveTemplate,
    postProcessModules
  }
}

let _coupons = [];

export function useCoupon() {
  const [coupons, setCoupons] = useState(_coupons);

  function updateCoupon() {
    getCouponList().then((resp) => {
      const datas = resp.data.data;
      _coupons = datas;
      setCoupons(datas)
    })
  }

  useEffect(() => {
    updateCoupon();
  }, []);

  return [coupons, updateCoupon];
}

export function useDiscountGoods() {
  const [goods, setGoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isSearch, setSearch] = useState(false);
  const [keyword, setKeyword] = useState('');

  const loadGoods = useSingleExec(async function (targetPage) {
    if (!hasMore && targetPage > page) {
      return;
    }
    setLoading(true);
    const resp = await getDiscountGoodsList({
      page: targetPage,
      page_size: LOAD_GOODS_PAGE_SIZE
    });
    setGoods(resp.data.data)
    setPage(targetPage);
    setHasMore(false);
    // if (resp.data.data.length === 0) {
    //   setHasMore(false);
    // } else {
    //   setHasMore(true);
    // }
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [hasMore, page, loading]);

  const _searchGoods = useSingleExec(async function (keyword, targetPage) {
    return;
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [hasMore, page, loading]);

  async function updateGoods() {
    await loadGoods(1);
  }

  const nextGoods = useSingleExec(async function () {
    if (keyword) {
      await _searchGoods(keyword, page + 1);
    } else {
      await loadGoods(page + 1);
    }
  }, {
   
  }, [page, hasMore, keyword]);

  const prevGoods = useSingleExec(async function () {
    if (page - 1 < 1) {
      return;
    }
    if (keyword) {
      await _searchGoods(keyword, page - 1);
    } else {
      await loadGoods(page - 1);
    }
  }, {
   
  }, [page, keyword]);

  const searchGoods = useSingleExec(async function(key) {
    setSearch(true)
    setLoading(true);
    setKeyword(key);
    await _searchGoods(key, 1)
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [])

  const cancelSearch = function () {
    setSearch(false);
    setKeyword('');
    updateGoods();
  }

  useEffect(() => {
    loadGoods(1);
  }, []);

  return {
    goods,
    loadGoods,
    nextGoods,
    prevGoods,
    updateGoods,
    searchGoods,
    cancelSearch,
    isSearch,
    loading,
    page,
    hasMore
  };
}

export function useGoods() {
  const [goods, setGoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isSearch, setSearch] = useState(false);
  const [keyword, setKeyword] = useState('');

  const loadGoods = useSingleExec(async function (targetPage) {
    if (!hasMore && targetPage > page) {
      return;
    }
    setLoading(true);
    const resp = await getGoodsList({
      page: targetPage,
      page_size: LOAD_GOODS_PAGE_SIZE
    });
    setGoods(resp.data.data)
    setPage(targetPage);
    if (resp.data.data.length < LOAD_GOODS_PAGE_SIZE) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [hasMore, page, loading]);

  const _searchGoods = useSingleExec(async function (keyword, targetPage) {
    if (!hasMore && targetPage > page) {
      return;
    }
    setLoading(true);
    const resp = await searchGoodsByKey(keyword, targetPage);
    setGoods(resp.data.data)
    setPage(targetPage);
    if (resp.data.data.length < LOAD_GOODS_PAGE_SIZE) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [hasMore, page, loading]);

  async function updateGoods() {
    await loadGoods(1);
  }

  const nextGoods = useSingleExec(async function () {
    if (keyword) {
      await _searchGoods(keyword, page + 1);
    } else {
      await loadGoods(page + 1);
    }
  }, {
   
  }, [page, hasMore, keyword]);

  const prevGoods = useSingleExec(async function () {
    if (page - 1 < 1) {
      return;
    }
    if (keyword) {
      await _searchGoods(keyword, page - 1);
    } else {
      await loadGoods(page - 1);
    }
  }, {
   
  }, [page, keyword]);

  const searchGoods = useSingleExec(async function(key) {
    setSearch(true)
    setLoading(true);
    setKeyword(key);
    await _searchGoods(key, 1)
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [])

  const cancelSearch = function () {
    setSearch(false);
    setKeyword('');
    updateGoods();
  }

  useEffect(() => {
    loadGoods(1);
  }, []);

  return {
    goods,
    loadGoods,
    nextGoods,
    prevGoods,
    updateGoods,
    searchGoods,
    cancelSearch,
    isSearch,
    loading,
    page,
    hasMore
  };
}

let _shopcats = [];

export function useShopCats() {
  const [cats, setCats] = useState(_shopcats);

  function updateCats() {
    getShopCats().then((resp) => {
      _shopcats = resp.data.data;
      setCats(resp.data.data)
    })
  }

  useEffect(() => {
    updateCats();
  }, []);

  return [cats, updateCats];
}

export function processShopCats(cats = []) {
  const rtn = [];
  function processCat(cat) {
    cat.label = cat.shopcat_name;
    cat.value = cat.shopcat_id;
    cat.children = null;
    return cat;
  }
  function findParent(cat) {
    const pid = cat.f_shopcat_id;
    return cats.find((c) => {
      return c.shopcat_id === pid;
    })
  }
  cats.forEach((cat) => {
    if (cat.cat_level === 1) {
      return rtn.push(processCat(cat));
    }
    const parent = findParent(cat);
    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(processCat(cat))
    }
  })
  return rtn;
}

export function useTemplates() {
  const [tempates, setTemplates] = useState([]);

  useEffect(() => {
    getTemplates().then((resp) => {
      setTemplates(resp.data.data)
    })
  }, []);

  return tempates;
}

export function useRenovationPages() {
  const [pages, setPages] = useState([]);
  const [next, setNext] = useState('');
  const [loading, setLoading] = useState(false);

  const loadMore = useSingleExec(async function () {
    if (!next) {
      return;
    }
    setLoading(true);
    const resp = await getRenocvationPages(next);
    setPages(pages.concat(_.get(resp.data, 'data', [])));
    setNext(_.get(resp.data, 'links.next', ''))
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [next]);

  useEffect(() => {
    getRenocvationPages().then((resp) => {
      setPages(_.get(resp.data, 'data', []));
      setNext(_.get(resp.data, 'links.next', ''))
    })
  }, []);

  return {
    pages,
    setPages,
    loadMore,
    hasMore: !!next,
    loading
  };
}

export function useLotteryRecords() {
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LotteryRecordsPageSize,
    position: ['none', 'bottomCenter']
  })
  const [statistics, setStatistics] = useState({
    win_count: 0,
    total_count: 0
  })
  const [type, setType] = useState('win');
  const [loading, setLoading] = useState(false);

  const loadPage = useSingleExec(async function (page, t) {
    setLoading(true);
    const resp = await getLotteryRecords(page, t || type);
    setRecords(_.get(resp.data, 'data.records', []));
    setPagination({
      ...pagination,
      current: _.get(resp.data, 'meta.page', []),
      total: _.get(resp.data, 'meta.total', 0),
    })
    setStatistics({
      total_count: _.get(resp.data, 'data.total_count', 0),
      win_count: _.get(resp.data, 'data.win_count', 0)
    })
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [type, pagination]);

  useEffect(() => {
    loadPage(1)
  }, []);

  const _redeemLottery = useSingleExec(async function (id) {
    const _records = [...records];
    const record = _records.find((r) => {
      return r.id === id;
    })
    if (!record) {
      return;
    }
    if (!record.redeem_info || record.redeem_info.state ===2) {
      return;
    }
    const resp = await redeemLottery({
      id,
      code: record.redeem_info.code
    });
    record.redeem_info.state = 2;
    setRecords(_records);
  }, {
  }, [records]);

  return {
    records,
    setType: function(t) {
      setType(t);
      loadPage(1, t)
    },
    loadPage,
    setRecords,
    redeemLottery: _redeemLottery,
    pagination,
    loading,
    statistics
  };
}
