import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules } from 'services/home';
import { Upload, message, Dropdown, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';

import './style.css';

function beforeUploadImage(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  console.log('file.size', file.size);
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "video/mp4";
  if (!isJpgOrPng) {
    message.error("只支持 MP4 视频!");
  }
  const isLt2M = file.size / 1024 / 1024 < 50;
  console.log('size', file.size)
  if (!isLt2M) {
    message.error("视频需小于 50MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default function Video() {
  const { activeModule, changeModule } = useModules();
  const [loading, setLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function handleUploadImageChange(info) {
    if (info.file.status === "uploading") {
      setCoverLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      setCoverLoading(false);
      changeValue('cover', url);
    } else if (info.file.status === 'error') {
      setCoverLoading(false);
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      setLoading(false);
      changeValue('url', url);
    } else if (info.file.status === 'error') {
      setLoading(false);
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  return <div className="config-video">
    <div className="common-config-block">
      <div className="header">
        <div className="label">上传视频</div>
      </div>
      <div className="config-upload-large">
        <Upload
          name="file"
          accept="video/mp4"
          listType="picture-card"
          className="large-avatar-uploader"
          showUploadList={false}
          data={{
            type: 2
          }}
          action="https://minastore.nocode.com/api/media"
          beforeUpload={beforeUpload}
          headers={{
            Authorization: "Bearer " + window.__token,
          }}
          onChange={handleChange}
        >
          {activeModule.formData.url ? <video style={{
            width: '340px',
            height: 'auto'
          }} >
            <source src={activeModule.formData.url} type="video/mp4"></source>
          </video> : <div>
              {loading ? (
                <LoadingOutlined />
              ) : (
                  <PlusOutlined />
                )}
              <div style={{ marginTop: 7 }}>添加视频</div>
            </div>}
        </Upload>
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">视频封面（可选）</div>
        <div className="pending"></div>
        <div className="opt" onClick={() => {
          changeValue('cover', '');
        }}>移除</div>
      </div>
      <div className="config-upload-large">
        <Upload
            name="file"
            listType="picture-card"
            className="large-avatar-uploader"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUploadImage}
            headers={{
              Authorization: "Bearer " + window.__token,
            }}
            onChange={(info) => {
              handleUploadImageChange(info);
            }}
          >
            {activeModule.formData.cover ? <img style={{
              width: '340px',
              height: 'auto'
            }} src={activeModule.formData.cover} /> : <div>
                {coverLoading ? (
                  <LoadingOutlined />
                ) : (
                    <PlusOutlined />
                  )}
                <div style={{ marginTop: 7 }}>添加图片</div>
              </div>}
          </Upload>
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }} />}>
            <Button>
              <span style={{ marginRight: 40 }}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
