import React, {useRef, useEffect, useState} from "react";
import classnames from 'classnames';
import {amountStr} from 'utils/tools';

import styles from './style.module.css';
import rankRedIcon from '../../images/icn-rank-red-bg@3x.png';


export default function ProductCard({product, per_line_num, show_buy}) {
  const ref = useRef(null);
  const [coverWidth, setCoverWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setCoverWidth(parseFloat(getComputedStyle(ref.current).width));
    }
  }, [ref, product.set_big, per_line_num])

  return <div className={styles.product}>
    <img src={product.image} className={styles.cover} ref={ref} style={{
      height: coverWidth
    }}/>
    <div className={styles.name}>{product.title}</div>
    <div className={styles.price}>￥{amountStr(product.price)}</div>
    {show_buy && <div className={styles.buyBtn}>立即购买</div>}
  </div>
}