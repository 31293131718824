import React, {useEffect, useState} from "react";
import _ from "lodash";
import url from "url";
import { useLocation, useHistory } from "react-router-dom";
import { useUserSession, authorizerToken } from "services/user";
import request from 'services/request';

import styles from './Login.module.css';
import loading from './loading.gif';

function Login() {
  const [session, setSession] = useUserSession();
  const location = useLocation();
  const history = useHistory();
  const [isLoginError, setLoginError] = useState(false);
  const [animateStep, setAnimateStep] = useState(1);

  useEffect(() => {
    if (session) {
      const parts = url.parse(_.get(location.state, 'from.pathname', '/'), true);
      if (parts.query && parts.query.code) {
        delete parts.query.code;
      }
      history.replace(url.format(parts));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, animateStep]);

  const code = _.get(location.state, 'code');

  useEffect(() => {
    if (code) {
      setLoginError(false)
      authorizerToken(code).then((resp) => {
        setSession(resp.data.data);
        window.__token = resp.data.data.token;
        request.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.data.token;
      }).catch((e) => {
        console.error(e);
        setLoginError(true)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  function onAnimationEnd() {
    setAnimateStep(animateStep + 1);
  }

  if (code || session) {
    // return <div className={classnames(styles.login, {
    //   [styles.hide]: animateStep > 1 && session
    // })} onTransitionEnd={onAnimationEnd}>
    //   <div className={classnames(styles.nocode, styles.show)} onAnimationEnd={onAnimationEnd}>
    //     <div className={classnames(styles.origin)}>
    //       <div className={styles.sponsor}>无码科技出品</div>
    //     </div>
    //     <div className={styles.faith}>万物之中，希望至美</div>
    //   </div>
    // </div>;
    return <div className={styles.loginPage}>
      <img src={loading} alt=""/>
    </div>
  } else {
    return <div className={styles.loginError}>
      <a className={styles.main} href="https://mp.weixin.qq.com" rel="noopener noreferrer">
        <div className={styles.desc}>
          <div>请在微信小商店</div>
          <div>服务商市场内使用</div>
        </div>
        <div className={styles.tip}>暂不支持外部访问</div>
      </a>
    </div>
  }
}

export default Login;
