import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import './header.css';

import moment from 'moment';
import Rodal from "rodal";
import { Link, useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { useServiceStatus } from "services/store";
import { useModules, isTempPage, getHome } from 'services/home';
import { checkFormDataError, setActiveModule } from 'services/module';
import { usePreviewQrcode } from 'services/store';

import { Button, message, Popconfirm, Input } from "antd";

const tabs = [{
  path: '/templates',
  name: '模版中心'
}, {
  path: '/renovation/home',
  name: '我的首页'
}, {
  path: '/renovation/pages',
  name: '页面管理'
}, {
  path: '/activity/lottery',
  name: '活动记录'
}, {
  path: '/member',
  name: '会员管理'
}, {
  path: '/contact',
  name: '建议与客服'
}]

function Header() {
  const location = useLocation();
  const history = useHistory();
  const pageRouteMatch = useRouteMatch("/renovation/pages/:pageId");
  const pageHomeRouteMatch = useRouteMatch("/renovation/home");
  const { pathname } = location;
  const { modules, activeModule, saveTemplate, setModules, template, setTemplate } = useModules();
  const [isPublishTipShow, changePublishTipShow] = useState(false);
  const previewQrcode = usePreviewQrcode();
  const [publishing, setPublishing] = useState(false);
  const [isQrcodeShow, setQrcodeShow] = useState(false);
  const { info, start, stop, status } = useServiceStatus();
  const serviceInfo = info && info.service;
  let expireDay = 0;
  if (serviceInfo) {
    expireDay = moment(serviceInfo.expire_time).diff(moment(), 'days');
  }
  
  function chooseModule(id) {
    setActiveModule(modules, id);
    setModules(modules);
  }

  useEffect(() => {
    if (pageRouteMatch) {
      const routeId = pageRouteMatch.params.pageId;
      if (template.id && isTempPage(routeId) && !isTempPage(template.id)) {
        setActiveModule(modules, '');
        setTimeout(() => {
          history.replace(`/renovation/pages/${template.id}`)
        }, 100)
      }
    }
  }, [template.id, pageRouteMatch])
  
  async function preview() {
    if (activeModule && window._settingChanged) {
      return message.error('你还有变更未保存');
    }
    if (modules.length === 0) {
      return message.error('请添加模块');
    }
    let hasProductsModule = false;
    for (let i = 0, len = modules.length; i < len; i++) {
      const error = checkFormDataError(modules[i].module.id, modules[i].formData);
      if (error) {
        chooseModule(modules[i].id)
        return message.error(error);
      }
      if (modules[i].module.id === 'lottery') {
        modules[i].formData.prizes.forEach((item, index) => {
          item.level = index + 1;
        })
      }
      if (modules[i].module.id === 'products') {
        hasProductsModule = true;
      }
    }
    previewQrcode.fetch(modules);
    setQrcodeShow(true);
  }

  async function publish(isPublish) {
    if (activeModule && window._settingChanged) {
      return message.error('你还有变更未保存');
    }
    if (modules.length === 0) {
      return message.error('请添加模块');
    }
    let hasProductsModule = false;
    for (let i = 0, len = modules.length; i < len; i++) {
      const error = checkFormDataError(modules[i].module.id, modules[i].formData);
      if (error) {
        chooseModule(modules[i].id)
        return message.error(error);
      }
      if (modules[i].module.id === 'lottery') {
        modules[i].formData.prizes.forEach((item, index) => {
          item.level = index + 1;
        })
      }
      if (modules[i].module.id === 'products') {
        hasProductsModule = true;
      }
    }
    if (!hasProductsModule && isPublish) {
      const resp = window.confirm('您还未添加全部商品模块，是否确认发布上线？');
      if (!resp) {
        return;
      }
    }
    setPublishing(true)
    try {
      const resp = await saveTemplate(isPublish)
      if (resp instanceof Error) {
        throw resp;
      }
      if (isPublish) {
        message.success('已发布上线');
        showPublishTip();
      } else {
        message.success('已保存');
      }
    } catch (e) {
      console.error(e);
      message.error(e.message);
    } finally {
      setPublishing(false)
    }
  }

  async function savePage() {
    const resp = await getHome();
    const hasHome = !_.get(resp, 'data.data.id');
    await publish(hasHome);
  }

  function showPublishTip() {
    if (status === -1) {
      changePublishTipShow(true);
    }
  }

  async function startService() {
    await start();
    changePublishTipShow(true);
  }

  function onChangePageTitle(e) {
    const newTemplate = {
      ...template,
      title: e.currentTarget.value
    }
    setTemplate(newTemplate)
  }

  if (pageRouteMatch) {
    return  <div className="app-header">
      <div className="renocation-page-header">
        <a className="back" onClick={() => {
          history.goBack();
        }}><img src="/icn-back@3x.png"/>返回</a>
        <div className="title">
          <Input className="value" value={template.title} id="pageTitle" onChange={onChangePageTitle}/>
          <label htmlFor="pageTitle"><img className="icn" src="/icn-edit@3x.png"/></label>
        </div>
      </div>
      <div className="opts">
        <Button style={{
          backgroundColor: 'rgb(51, 51, 51)',
          boxShadow: 'none',
          border: 'none'
        }} className="opt primary" type="primary" loading={publishing} onClick={savePage}>保存页面</Button>
      </div>
      <Rodal
        visible={isPublishTipShow}
        onClose={() => {
          changePublishTipShow(false)
        }}
        width={600}
        height={520}
      >
        <div className={'confirmDialog'}>
          <div className={'title'} style={{
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 0
          }}>还差一步，确认启用后生效</div>
          <div className={'tip'}>仅初次发布需要确认启用，后续更新时点发布上线即可生效</div>
          <img className={'guide'} src="/pic-launch@3x.png" />
        </div>
      </Rodal>
    </div>
  }

  return (
    <div className="app-header">
      <Link className="logo" to="/">
        <img className='icn' src='/logo.png' alt="" />
        <div className='name'>旺铺助手</div>
      </Link>
      <div className="tabs">
        {tabs.map((tab, index) => {
          return <Link key={index} className={classnames('tab', {
            active: tab.path === pathname
          })} to={tab.path}>{tab.name}</Link>
        })}
      </div>
      {serviceInfo && <div className="service">
        {serviceInfo.version === 'free' && <div className={classnames('tag', 'red')}>试用版</div>}
        {serviceInfo.version === 'professional' && <div className={classnames('tag', 'green')}>专业版</div>}
        <div className={'expire'}>剩余 {Math.max(expireDay, 0)} 天</div>
        {expireDay <= 5 && <a href="https://mp.weixin.qq.com" target="_blank" className={'renew'}>点此续费</a>}
      </div>}
      {info && <div className='decorate-status'>
        {status === 1 ? (
          <Popconfirm title="确认停用，请在手机端确认" okText="是" cancelText="否" onConfirm={(e) => {
            e.stopPropagation();
            stop()
          }}>
            <a className='stopService'>停用服务</a>
          </Popconfirm>
        ) : (
            <a onClick={startService} className='stopService'>
              启用服务
            </a>
          )}
      </div>}
      <div className="pending"></div>
      {pageHomeRouteMatch && <div className="opts">
        <Button className="opt" onClick={preview}>手机预览</Button>
        <Button className="opt primary" type="primary" loading={publishing} onClick={() => {
          publish(true)
        }}>发布上线</Button>
      </div>}
      <Rodal
        visible={isQrcodeShow}
        onClose={() => {
          setQrcodeShow(false)
        }}
        width={544}
        height={500}
      >
        <div className={'confirmDialog'}>
          <div className={'title'}>扫描二维码进行预览，预览后请记得发布上线</div>
          {previewQrcode.processing ? <div className={'loading'}>生成中...</div> : <img src={previewQrcode.image} className={'image'} />}
          <div className={'btns'}>
            <div className={'btn'} onClick={() => {
              setQrcodeShow(false)
              publish(true);
            }}>发布上线</div>
          </div>
        </div>
      </Rodal>
      <Rodal
        visible={isPublishTipShow}
        onClose={() => {
          changePublishTipShow(false)
        }}
        width={600}
        height={520}
      >
        <div className={'confirmDialog'}>
          <div className={'title'} style={{
            fontSize: 20,
            fontWeight: 'bold',
            marginTop: 0
          }}>还差一步，确认启用后生效</div>
          <div className={'tip'}>仅初次发布需要确认启用，后续更新时点发布上线即可生效</div>
          <img className={'guide'} src="/pic-launch@3x.png" />
        </div>
      </Rodal>
    </div>
  );
}

export default Header;