import React, { useState } from "react";
import moment from 'moment';
import {useRenovationPages, managePage, updatePageTitle} from 'services/home';
import {Button, Table, Input, Popconfirm} from "antd";
import { Link } from "react-router-dom";
import {useSingleExec} from 'utils/functional';

import './style.css';

function checkHome(page) {
  return page.state === 1;
}

export default function Pages() {
  const {pages, setPages, loadMore, hasMore, loading} = useRenovationPages();

  const setHomePage = useSingleExec(async function(id) {
    await managePage(id, {
      type: 1
    })
    pages.forEach((p) => {
      if (p.id === id) {
        p.state = 1;
      } else {
        p.state = 0;
      }
    })
    setPages([...pages]);
  }, {}, [pages])

  const changePageTitle = useSingleExec(async function(e) {
    const {id} = e.currentTarget.dataset;
    await updatePageTitle(id, e.currentTarget.value)
  });

  const columns = [{
    title: '页面名称',
    dataIndex: 'title',
    key: 'title',
    render: (title, page) => {
      title = title || '未命名';
      const isHome = checkHome(page);
      return <div className="title-block">
        {isHome && <div className="tag">首页</div>}
        <label htmlFor={page.id}><img className="edit" src="/icn-edit@3x.png"/></label>
        <Input className="value" defaultValue={title} id={page.id} data-id={page.id} onBlur={changePageTitle}/>
      </div>;
    }
  }, {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  }, {
    title: '修改时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: (date) => {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  }, {
    title: '操作',
    key: 'action',
    render: (text, page) => {
      const isHome = checkHome(page);
      return <div className="action-block">
        <Link to={`/renovation/pages/${page.id}`}>进入编辑</Link>
        {!isHome &&  <Popconfirm title="确认设为首页" okText="是" cancelText="否" onConfirm={(e) => {
          e.stopPropagation();
          setHomePage(page.id)
        }}><a data-id={page.id}>设为首页</a></Popconfirm>}
      </div>
    }
  }]

  return <div className="renocation-pages">
    <div className="content">
      <div className="actions">
        <Button className="btn primary" type="primary"><Link to="/templates">新建页面</Link></Button>
      </div>
      <div className="table">
        <Table columns={columns} dataSource={pages} pagination={false} />
      </div>
      {hasMore && <div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '20px'
      }}>
        <Button type="link" onClick={loadMore} loading={loading}>加载更多</Button>
      </div>}
    </div>
  </div>
}