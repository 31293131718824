import React from "react";
import _ from 'lodash';
import {amountStr, detectTextColor} from 'utils/tools';
import './style.css';

export default function Category({ module }) {
  const formData = module.formData || {};
  const { data, styleType, style = {} } = formData;
  const textColor = detectTextColor(style.background_color);
  const categories = _.get(data, 'categories', []);
  let type = 1;
  categories.forEach((cat) => {
    if (styleType === 't1') {
      if (categories.length <= 6 && categories.length !== 4) {
        type = 2
      } else {
        type = 3
      }
    }
  })

  if (type === 1) {
    return <div className="preview-category-type-1" style={{
      backgroundColor: style.background_color
    }}>
      {categories.map((item, index) => {
        return <div className="category" key={index}>
          <div className="header">{item.name || '分类名'}</div>
          <div className="list">
            {item.products.length === 0 && <img src="/icn-recommend-default-2@3x.png" style={{
              width: '100%',
              marginBottom: '20px'
            }}/>}
            {item.products.slice(0, 4).map((item, index) => {
              return <div className="item" key={index}>
                <img className="cover" src={item.image} />
                <div className="name">{item.title}</div>
                <div className="pending"></div>
                <div className="price">￥{amountStr(item.price)}</div>
              </div>
            })}
          </div>
        </div>
      })}
    </div>
  }

  if (categories.length === 1 && !categories[0].image && !categories[0].name) {
    return <div className='preview-category-type-3' style={{
      backgroundColor: style.background_color
    }}>
      <div className="item">
        <div className="cover"></div>
        <div className="name" style={{
          color: textColor
        }}>分类名</div>
      </div>
      <div className="item">
        <div className="cover"></div>
        <div className="name" style={{
          color: textColor
        }}>分类名</div>
      </div>
      <div className="item">
        <div className="cover"></div>
        <div className="name" style={{
          color: textColor
        }}>分类名</div>
      </div>
      <div className="item">
        <div className="cover"></div>
        <div className="name" style={{
          color: textColor
        }}>分类名</div>
      </div>
    </div>
  }

  if (type === 2) {
    return <div className='preview-category-type-2' style={{
      backgroundColor: style.background_color
    }}>
      {categories.map((item, index) => {
        return <div className="item" key={index}>
          {!item.image ? <div className="cover"></div> : <img src={item.image} className="cover" />}
          <div className="name" style={{
            color: textColor
          }}>{item.name}</div>
        </div>
      })}
    </div>
  }

  if (type === 3) {
    return <div className='preview-category-type-3' style={{
      backgroundColor: style.background_color
    }}>
      {categories.map((item, index) => {
        return <div className="item" key={index}>
          {!item.image ? <div className="cover"></div> : <img src={item.image} className="cover" />}
          <div className="name" style={{
            color: textColor
          }}>{item.name}</div>
        </div>
      })}
    </div>
  }

  return null;
}
