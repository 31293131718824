import React, { useState } from "react";
import _ from 'lodash';
import classnames from 'classnames';
import { useModules } from 'services/home';
import { Upload, message, Input, Image, Button } from "antd";
import ImgCrop from "antd-img-crop";
import {previewShare} from 'services/store';

import './style.css';
import { useSingleExec } from "utils/functional";

const { TextArea } = Input;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持 JPG/PNG 图片!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  console.log('size', file.size)
  if (!isLt2M) {
    message.error("图片需小于 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default function Share() {
  const { activeModule, changeModule } = useModules();
  const [previewImage, setPreviewImage] = useState('');
  const [isProcess, setProcess] = useState(false);

  const previewShareImage =  useSingleExec(async function () {
    if (!activeModule.formData.share_cover) {
      return message.warn('请先添加分享图封面');
    }
    setProcess(true);
    const url = (await previewShare({
      type: 'timeline',
      cover: activeModule.formData.share_cover,
      desc: activeModule.formData.desc
    })).data.data.url;
    setPreviewImage(url);
  }, {
    onComplete: function() {
      setProcess(false);
    }
  }, [activeModule]);

  const previewCardImage =  useSingleExec(async function () {
    if (!activeModule.formData.card_cover) {
      return message.warn('请先添加聊天分享卡片封面');
    }
    setProcess(true);
    const url = (await previewShare({
      type: 'card',
      cover: activeModule.formData.card_cover,
      desc: activeModule.formData.desc
    })).data.data.url;
    setPreviewImage(url);
  }, {
    onComplete: function() {
      setProcess(false);
    }
  }, [activeModule]);

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function handleShareCoverChange(info) {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      changeValue('share_cover', url);
    } else if (info.file.status === 'error') {
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  function handleCardCoverChange(info) {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.data.url;
      changeValue('card_cover', url);
    } else if (info.file.status === 'error') {
      const msg = _.get(info, 'file.response.error.message');
      if (msg) {
        message.error(msg);
      }
    }
  }

  return <div className="config-share">
    <div className="common-config-block" >
      <div className="header">
        <div className="label">分享引导文案</div>
      </div>
      <div className="config-item config-textarea">
        <TextArea style={{ maxWidth: 360 }} maxLength={200} allowClear value={activeModule.formData.desc} autoSize={{
          minRows: 4
        }} onChange={(e) => {
          changeValue('desc', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">分享图封面</div>
      </div>
      <div className="config-share-cover">
        {activeModule.formData.share_cover ? <img className="cover" src={activeModule.formData.share_cover} /> : <div className="default-cover">

        </div>}
        <div>
          <Upload
            name="file"
            style={{ width: 300 }}
            listType="picture-card"
            className="uploader-btn"
            showUploadList={false}
            action="https://minastore.nocode.com/api/image"
            beforeUpload={beforeUpload}
            onChange={handleShareCoverChange}
            headers={{
              Authorization: 'Bearer ' + window.__token
            }}
          >
            <div>更换封面</div>
          </Upload>
          <Button className="preview-btn" loading={isProcess} onClick={previewShareImage}>效果预览</Button>
        </div>
      </div>
    </div>
    {/* <div className="common-config-block">
      <div className="header">
        <div className="label">聊天分享卡片封面</div>
      </div>
      <div className="config-card-cover">
        {activeModule.formData.card_cover ? <img className="cover" src={activeModule.formData.card_cover} /> : <div className="default-cover">

        </div>}
        <div>
          <ImgCrop aspect={2} quality={0.6}>
            <Upload
              name="file"
              style={{ width: 300 }}
              listType="picture-card"
              className="uploader-btn"
              showUploadList={false}
              action="https://minastore.nocode.com/api/image"
              beforeUpload={beforeUpload}
              onChange={handleCardCoverChange}
              headers={{
                Authorization: 'Bearer ' + window.__token
              }}
            >
              <div>更换封面</div>
            </Upload>
          </ImgCrop>
          <Button className="preview-btn" loading={isProcess} onClick={previewCardImage}>效果预览</Button>
        </div>
      </div>
    </div> */}
    <Image style={{
      display: 'none'
    }} src={previewImage} preview={{
      src: previewImage,
      visible: !!previewImage,
      onVisibleChange: (visible) => {
        if (!visible) {
          setPreviewImage('');
        }
      }
    }} />
  </div>
}
