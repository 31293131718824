import {useEffect, useState} from "react";
import {message} from 'antd';
import url from "url";
import _ from "lodash";
import request from './request';
import {useUserSession} from './user';
import {useModules} from "./home";

export async function changeServiceStutus(status = 0) {
  return request.post(`/decoration/admin/service`, {
    status: status
  });
}

export async function getAuthInfo() {
  return request.get('/authorizer/info')
}

export async function getPreviewQrcode(data) {
  return request.post('/decoration/admin/qrcode', data);
}

export async function previewShare(data) {
  return request.post('/decoration/admin/share/preview', data);
}

export function useServiceStatus() {
  const [status, setStatus] = useState(1);
  const [processing, setProcess] = useState(false);
  const [session] = useUserSession();
  const [info, setInfo] = useState(null);
  const parts = url.parse(window.location.href, true);
  const code = _.get(parts, 'query.code');

  useEffect(() => {
    if (session && !code) {
      getAuthInfo().then((res) => {
        const {decoration_status} = res.data.data;
        setStatus(decoration_status);
        setInfo(res.data.data);
      }).catch((e) => {
        console.error(e);
        message.error('服务异常')
      })
    }
  }, [session, code])

  function changeStatus(status) {
    return async function () {
      try {
        if (processing) {
          return;
        }
        setProcess(true)
        const resp = await changeServiceStutus(status);
        if (resp instanceof Error) {
          throw resp;
        }
        setStatus(status);
      } catch (e) {
        message.error(e.message);
      } finally {
        setProcess(false)
      }
    }
  }

  return {
    status,
    info,
    start: changeStatus(1),
    stop: changeStatus(0)
  }
}

export function usePreviewQrcode() {
  const [image, setImage] = useState('');
  const [processing, setProcess] = useState(false);
  const {modules, setModules, saveTemplate, postProcessModules} = useModules();

  async function fetch(m) {
    try {
      if (processing) {
        return;
      }
      setProcess(true)
      const resp = await getPreviewQrcode({
        templates: postProcessModules(m)
      });
      if (resp instanceof Error) {
        throw resp;
      }
      setImage(resp.data.data.qrcode);
    } catch (e) {
      message.error(e.message);
    } finally {
      setProcess(false)
    }
  }

  return {
    image,
    processing,
    fetch
  }
}