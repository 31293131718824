import {useState, useEffect} from 'react';
import {useLocalStorage} from 'react-use';
import _ from "lodash";
import request from './request';

export function useUserSession() {
  const [refreshing, setRefreshing] = useState(false);
  const [value, setSession] = useLocalStorage('wpzs_session', null);
  useEffect(() => {
    if (value) {
      const isExpired = new Date(value.expires_in).getTime() < Date.now() - 1000 * 60 * 30;
      if (!isExpired || refreshing) {
        return;
      }
      setRefreshing(true);
      refreshToken(value.refresh_token).then((resp) => {
        const data = resp.data.data;
        const session = {
          ...value,
          ...data
        }
        setSession(session);
      })
    }
  }, [value, refreshing, setSession])

  return [value, setSession];
}

export function useShopInfo() {
  const [session] = useUserSession();
  return _.get(session, 'authorizer', {});
}

export async function authorizerToken(code) {
  return request.post('/authorizer/token', {
    code
  })
}

export async function refreshToken(token) {
  return request.post('/token/refresh', {
    refresh_token: token
  })
}