import React from "react";
import classnames from 'classnames';

import './style.css';

export default function AdWechat({ module }) {
  const formData = module.formData || {};
  const { content, desc, type, style = {} } = formData;

  let wechatIcon;
  let addIcon;
  let btnText;

  switch (type) {
    case 1:
      wechatIcon = '/contact/icon-wechat-friend@3x.png'
      addIcon = '/contact/icn-add@3x.png'
      btnText = '加好友';
      break;
    case 2:
      wechatIcon = '/contact/icon-wechat-group@3x.png'
      addIcon = '/contact/icn-add@3x.png'
      btnText = '进群';
      break;
    case 3:
      wechatIcon = '/contact/icon-mp@3x.png'
      addIcon = '/contact/icn-add-blue@3x.png'
      btnText = '加关注';
      break;
    case 4:
      wechatIcon = '/contact/icon-service@3x.png'
      addIcon = '/contact/icn-call@3x.png'
      btnText = '打电话';
      break;
    default:
      wechatIcon = '/contact/icon-wechat-friend@3x.png'
      addIcon = '/contact/icn-add@3x.png'
      btnText = '加好友';
      break;
  }

  return <div className={classnames('preview-add-wechat', `type-${type}`)} style={{
    backgroundColor: style.background_color
  }}>
    <div className="content">
      <img className="wechat-icon" src={wechatIcon} />
      <div className="block">
        <div className="title">{desc}</div>
        <div className="desc">{content}</div>
      </div>
      <div className="btn">
        <img className="icn" src={addIcon} />
        <span>{btnText}</span>
      </div>
    </div>
  </div>
}
