import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useWindowSize } from 'react-use';
import classnames from 'classnames';
import _ from 'lodash';
import './style.css';
import Rodal from "rodal";
import { useGoods } from 'services/home';
import { Input, Button, Spin, message } from 'antd';
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import checkIcon from './icn-checked-popup@3x.png';
import { useSingleExec } from 'utils/functional';


export default function GoodsSelector({source=[1,2], single = false, visible = false, onClose = () => { }, defaultGoods = [], onConfirm = () => {} }) {
  const [] = useState(false);
  const [keyword, setKeyword] = useState('');
  const searchRef = useRef(null);
  const { goods, loading, hasMore, page, nextGoods, prevGoods, updateGoods, searchGoods, cancelSearch, isSearch } = useGoods();
  const { width, height } = useWindowSize();
  const dialogWidth = Math.min(width * 0.8, 800);
  const dialogHeight = Math.min(height * 0.8, 600);
  const [selectGoods, setSelectGoods] = useState([]);

  const toggleGoods = useCallback((good) => {
    let _selectGoods = [...selectGoods];
    const index = _selectGoods.findIndex((item) => {
      return item.id === good.id;
    });
    if (index > -1) {
      _selectGoods.splice(index, 1)
    } else {
      if (single) {
        _selectGoods = [good];
      } else {
        _selectGoods.push(good);
      }
    }
    setSelectGoods(_selectGoods)
  }, [selectGoods, single])

  useEffect(() => {
    setSelectGoods(defaultGoods)
  }, [defaultGoods])

  useEffect(() => {
    if (visible) {
      updateGoods()
      setKeyword('');
    }
  }, [visible])

  const userSearchGoods = useSingleExec(async function() {
    if (keyword) {
      await searchGoods(keyword)
    }
  }, {}, [keyword])

  return <Rodal
    visible={visible}
    onClose={onClose}
    width={dialogWidth}
    height={dialogHeight}
    showCloseButton={false}
  >
    <div className='goods-selector-dialog'>
      {loading && <div className="loading">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
      </div>}
      <div className='header'>
        <div className="name">选择要添加的商品</div>
        <div className="pending"></div>
        <Input value={keyword} placeholder="搜索商品" ref={searchRef} onPressEnter={userSearchGoods} onChange={(e) => {
          setKeyword(e.currentTarget.value)
        }}></Input>
        {!isSearch ? <div className="search-icn" onClick={userSearchGoods}><SearchOutlined /></div> : <a className="cancel-search" onClick={() => {
          cancelSearch();
          setKeyword('')
        }}>取消</a>}
      </div>
      <div className="goods">
        {goods.map((item, index) => {
          const selected = !!selectGoods.find((selectItem) => {
            return selectItem && item && selectItem.id === item.id;
          })
          const isDisable = source.indexOf(item.source) === -1;
          return <div className={classnames('item', {
            selected: selected,
            disable: isDisable
          })} key={index} onClick={() => {
            if (isDisable) {
              let title = '当前模块不支持添加当前商品';
              if (item.source === 2) {
                title = '当前模块不支持添加带货商品'
              }
              message.warn(title)
              return;
            }
            toggleGoods(item)
          }}>
            <img className="cover" src={item.image} />
            <div className="title">{item.title}</div>
            <img className='check' src={checkIcon} />
          </div>
        })}
      </div>
      <div style={{flex: '1 1 auto'}}> </div>
      <div className="footer">
        <div className="pag">
          <Button className="prev" disabled={page === 1} onClick={prevGoods}>上一页</Button>
          <div className="num">第 {page} 页</div>
          <Button className="next" disabled={!hasMore} onClick={nextGoods}>下一页</Button>
        </div>
        <div className="btns">
          <Button className="opt" onClick={onClose}>取消</Button>
          <Button className="opt primary" type="primary" onClick={() => {
            onConfirm(selectGoods)
          }}>确定</Button>
        </div>
      </div>
    </div>
  </Rodal>
}