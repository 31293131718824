import React from "react";
import _ from 'lodash';
import classnames from 'classnames';

import { toZhDigit, isColorDark } from 'utils/tools';
import lotteryIcon from '../images/icn-lottery@3x.png';

import './style.css';

export default function Lottery({ module }) {
  const formData = module.formData || {};
  const {style = {}} = formData;
  const isDark = isColorDark(style.background_color)
  const prizes = _.get(formData, 'prizes', []).map((item, index) => {
    return {
      levelDesc: `${toZhDigit(index + 1)}等奖`,
      name: item.name
    }
  })

  const countdown = {
    hour: '00',
    minute: '00',
    second: '00'
  }

  return <div className={classnames('preview-lottery', {
    dark: isDark
  })} style={{
    backgroundColor: style.background_color
  }}>
    <div className="header">
      <div className="label">福利抽奖</div>
      <div className="countdown">
        <div className="text">距结束</div>
        <div className="value">{ countdown.hour }</div>
        <div className="text">时</div>
        <div className="value">{ countdown.minute }</div>
        <div className="text">分</div>
        <div className="value">{ countdown.second }</div>
        <div className="text">秒</div>
      </div>
    </div>
    <div className="body">
      <img className="cover" src={formData.image} />
      <div className="block">
        <div className="prizes">
          {prizes.map((item, index) => {
            return <div className="item" key={index}>
              <div className="index">{item.levelDesc}：</div>
              <div className="name">{item.name}</div>
            </div>
          })}
        </div>
        <div className="join-btn">
          <img className="icn" src={lotteryIcon} />
          <span>参与抽奖</span>
        </div>
      </div>
    </div>
  </div>
}
