import React from "react";
import _ from 'lodash';
import classnames from 'classnames';

import './style.css';

export default function Live() {
  return <div className="config-live">
    <div className="not-need-config">
      <img className="icon" src="/icn-non-config@3x.png"/>
      <div className="desc">此模块无需配置，添加即可出现</div>
      <div className="tip">请确认您在创建直播时，已开启「官方收录」</div>
    </div>
  </div>
}
