
import React, { useEffect, useState } from "react";
import { useParams, Prompt } from "react-router-dom";
import { useModules, usePageModule } from 'services/home';
import FeedbackBar from 'components/FeedbackBar'
import {useServiceStatus} from 'services/store';

import ModuleSelector from 'pages/renovation/home/components/ModuleSelector';
import ModulePreview from 'pages/renovation/home/components/ModulePreview';
import ModuleConfig from 'pages/renovation/home/components/ModuleConfig';

import './style.css';


export default function PageDetail() {
  let { id } = useParams();
  const { activeModule, setTemplate} = useModules();
  const { info } = useServiceStatus();

  usePageModule(id);

  useEffect(() => {
    return () => {
      setTemplate({
        id: null,
        modules: []
      })
    }
  }, [])

  return <div className="renocation-page">
    <Prompt when={!!activeModule} message={() =>
      `离开当前页面将丢弃未发布的修改，确认离开？`
    }/>
    <div className="main-block">
      <div className="module-selector">
        <ModuleSelector />
      </div>
      <div className="module-preview">
        {info && info.feedback_tip ? <FeedbackBar /> : null}
        <ModulePreview inEditPage={true}/>
      </div>
    </div>
    <div className="config-block">
      <ModuleConfig />
    </div>
  </div>
}