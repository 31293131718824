import React, {useEffect, useState, useRef} from "react";
import classnames from 'classnames';
import { VideoCameraOutlined } from "@ant-design/icons";

import './style.css';

export default function Video({ module }) {
  const formData = module.formData || {};
  const { style = {} } = formData;
  const [videoStyle, setVideoStyle] = useState({})
  const containerRef = useRef(null)

  useEffect(() => {
    if (formData.cover) {
      const img = new Image();
      img.src = formData.cover;
      img.onload = function(e) {
        const {width, height} = e.target;
        const containerWidth = parseFloat(getComputedStyle(containerRef.current).width) - 20;
        const containerHeight = containerWidth * height / width;
        setVideoStyle({
          width: containerWidth,
          height: containerHeight
        })
      }
    } else {
      setVideoStyle({})
    }
  }, [formData.cover])

  return <div className="preview-video" ref={containerRef} style={{
    backgroundColor: style.background_color
  }}>
    {formData.url ? [<video style={videoStyle} poster={formData.cover || ''}>
      <source src={formData.url} type="video/mp4"></source>
    </video>, <img src="/icn-play@3x.png" className="icn-play"/>] : <div className="empty"><VideoCameraOutlined /></div>}
  </div>
}
