import React from "react";
import classnames from 'classnames';
import { useGoods } from 'services/home';
import {amountStr, isColorDark} from 'utils/tools';
import './style.css';

export default function Goods({ module }) {
  const formData = module.formData || {};
  const {style = {}} = formData;
  const isDark = isColorDark(style.background_color)
  const {goods} = useGoods();
  // return <div className='preview-goods'>
  //   <img src={module.module.icn}/>
  //   <div>{module.module.name}（无需配置）</div>
  // </div>
  return <div className={classnames('preview-goods-list', {
    dark: isDark
  })} style={{
    backgroundColor: style.background_color
  }}>
      <div className="header">
        <span className="label">全部商品</span>
      </div>
      <div className="list">
        {goods.slice(0, 30).map((item, index) => {
          return <div className='item' key={index}>
            <div className="cover">
              <img src={item.image}/>
            </div>
            <div className="name">{item.title}</div>
            <div className="pending"></div>
            <div className="price">￥{amountStr(item.price)}</div>
          </div>
        })}
      </div>
    </div>
}
