
import React, { useState, useRef } from "react";
import classnames from 'classnames';

import moment from 'moment';
import { Table, Popconfirm, InputNumber, Tooltip, Select } from "antd";
import { MODULES } from 'services/module';
import { useMembers, useMemberCoupons, rankMap } from 'services/member';
import Coupon from './coupon';
import CouponTask from './task';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CouponManageDialog from 'components/CouponManageDialog'
import './index.css';

const { Option } = Select;

const couponModule = MODULES.find((m) => {
  return m.id === 'coupon';
})

const lotteryModule = MODULES.find((m) => {
  return m.id === 'lottery';
})

const levelToopTip = `会员等级可用来帮您对会员进行分组管理；

您可以选择将一部分客户设置为某个等级，在进行会员权益发放时选择某个等级，则仅有此等级的客户会看到此权益，以此实现权益等级区分；

等级当前暂开放三档，默认为普卡，可手动调整；

此会员等级对客户无感，不会显示在客户页面中，仅用来对您的管理提供便利。`

export default function Member() {
  const [isCouponShow, setCouponVisiable] = useState(false);
  const [isCouponTaskShow, setCouponTaskVisiable] = useState(false);
  const [couponManageDialogShow, setCouponManageDialogShow] = useState(false);
  const members = useMembers();
  const memberCoupon = useMemberCoupons();
  const inputRef = useRef(null);
  const columns = [{
    title: '昵称',
    dataIndex: 'nickname',
    key: 'nickname',
    render: (nickname, record, r) => {
      return <div className="user">
        <img className="avatar" src={record.avatar}/>
        <span className="name">{nickname}</span>
      </div>;
    }
  }, {
    title: '加入时间',
    dataIndex: 'join_at',
    key: 'join_at',
    render: (date) => {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : ''
    }
  }, {
    title: '联系方式',
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => {
      return phone;
    }
  }, {
    title: () => {
      return <Tooltip placement="bottom" title={levelToopTip} color="#ffffff" overlayClassName="member-level-tooltip">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}>
          <span style={{
            marginRight: 4
          }}>等级</span>
          <ExclamationCircleOutlined style={{
            fontSize: 12,
            color: 'rgba(0,0,0,0.5)'
          }}/>
        </div>
      </Tooltip>
    },
    dataIndex: 'rank_key',
    key: 'rank_key',
    render: (__, record) => {
      return <Select defaultValue="LEVEL1" value={record.rank_key} bordered={false} onChange={(level) => {
        members.changeLevel(record.id, level)
      }}>
        <Option value="LEVEL1">{rankMap.LEVEL1}</Option>
        <Option value="LEVEL2">{rankMap.LEVEL2}</Option>
        <Option value="LEVEL3">{rankMap.LEVEL3}</Option>
      </Select>
    }
  }, {
    title: '剩余有效期',
    dataIndex: 'expired_at',
    key: 'expired_at',
    render: (expired_at) => {
      if (!expired_at) {
        return '';
      }
      if (moment(expired_at) < moment()) {
        return '已过期'
      }
      return moment(expired_at).toNow(true);
    }
  }, {
    title: '操作',
    key: 'action',
    render: (text, record) => {
      if (record.state === 0) {
        return <div className="action-block">
          <a className="green" onClick={() => {
            members.agreenApply(record.id)
          }}>通过申请</a>
          <a className="gray" onClick={() => {
            members.ignoreApply(record.id)
          }}>忽略</a>
        </div>
      }
      return <div className="action-block">
        <Popconfirm title="确认移除" okText="是" cancelText="否" onConfirm={(e) => {
          e.stopPropagation();
          members.removeMember(record.id)
        }}><a>移除会员</a></Popconfirm>
        <Popconfirm title="" icon={<div className="member-renew-input">
          <div className="label">增加</div>
          <InputNumber ref={inputRef} min={1} bordered={false} placeholder="输入天数"/>
        </div>} okText="是" cancelText="否" onConfirm={(e) => {
          e.stopPropagation();
          if (inputRef.current.value) {
            members.renew(record.id, +inputRef.current.value)
          }
        }}><a>续期</a></Popconfirm>
      </div>
    }
  }]

  return <div className="renocation-member">
    <div className="content">
      <div className="interests">
        <div className="header">
          <div className="label">会员专属权益</div>
          <div className="desc">更多权益陆续开放中，当前已有会员 {members.pagination.total} 人</div>
        </div>
        <div className="items">
          <div className="item">
            <div className="interest">
              <img className="icn" src={couponModule.icn} />
              <div className="name">优惠券</div>
            </div>
            <div className="opts">
              <div className="opt" onClick={() => {
                setCouponVisiable(true)
              }}>发放优惠券</div>
              {memberCoupon.coupons.length === 0 ? <div className="opt" onClick={() => {
                setCouponTaskVisiable(true)
              }}>创建定时发放任务</div> : <div className="opt" onClick={() => {
                setCouponTaskVisiable(true)
              }}><img className='icn' src="/icn-checked-popup-green@3x.png"/>定时发放生效中<img className="arrow" src="/icn-arrow-down@3x.png"/></div>}
            </div>
            <div className="pending"></div>
            <div className="opts">
              <div className="opt orange" onClick={() => {
                setCouponManageDialogShow(true)
              }}>已发放的优惠券<img className="arrow" style={{
                rotate: '-90deg'
              }} src="/icn_back_white@3x.png"/></div>
            </div>
          </div>
          <div className="item">
            <div className="interest">
              <img className="icn" src={lotteryModule.icn} />
              <div className="name">福利抽奖</div>
            </div>
            <div className="opts">
              <div className={classnames('opt', 'disable')}>会员抽奖(待开放)</div>
            </div>
          </div>
        </div>
      </div>
      <div className="records">
      <Table columns={columns} loading={members.loading} dataSource={members.records} pagination={members.pagination} onChange={(p) => {
          members.loadPage(p.current)
        }}/>
      </div>
    </div>
    <Coupon isShow={isCouponShow} onChange={(isShow) => {
      setCouponVisiable(isShow);
    }}/>
    <CouponTask isShow={isCouponTaskShow} onChange={(isShow) => {
      setCouponTaskVisiable(isShow);
    }}/>
    <CouponManageDialog visible={couponManageDialogShow} onClose={() => {
      setCouponManageDialogShow(false)
    }}/>
  </div>
}