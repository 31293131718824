import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash';
import { useModules } from 'services/home';
import {
  Input,
  Select,
  DatePicker,
  message,
  InputNumber,
  Collapse,
  Dropdown, Button
} from "antd";
import GoodsSelector from 'components/GoodsSelector';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';

import './style.css';

const { Option } = Select;
const { Panel } = Collapse;

function newDefaultProduct() {
  return {
    max_cut: 0
  };
}

export default function FlashCut() {
  const { activeModule, changeModule } = useModules();
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  const shareParCntList = [2]

  if (!activeModule) {
    return null;
  }

  function addProduct() {
    const formData = activeModule.formData;
    formData.products.push(newDefaultProduct());
    changeModule(activeModule);
  }

  function removeProduct(index) {
    const formData = activeModule.formData;
    formData.products.splice(index, 1);
    if (formData.products.length === 0) {
      formData.products = [newDefaultProduct()];
    }
    changeModule(activeModule);
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }


  return <div className="config-flash-cut">
    <Collapse
      bordered={false}
      className="site-collapse-custom-collapse"
    >
      <Panel header="查看使用介绍" key="1" className="site-collapse-custom-panel">
        <p>活动开始后后，客户在 15 秒内持续点击按钮进行砍价，每次点击会「杀掉 」一定金额。15 秒结束后，客户将商品杀至某一个价格，会自动生成优惠券进行下单抵扣。</p>
        <p>在开启「邀请好友裂变」后，当前允许 2 位好友进行助力，每人点击砍价 1 次，会额外再降低一定金额，之后生成优惠券进行下单抵扣。（注意：开启邀请并不会突破您设置的最多可砍价金额）</p>
      </Panel>
    </Collapse>
    <div className="common-config-block">
      <div className="header">
        <div className="label">模块标题</div>
      </div>
      <div className="config-item config-input-1">
        <Input placeholder="请输入模块标题" value={activeModule.formData.title} onChange={(e) => {
          changeValue('title', e.currentTarget.value)
        }} />
      </div>
    </div>
    {activeModule.formData.products.map((product, index) => {
      return [<div className="common-config-block" style={{
        marginBottom: 20
      }} key={'product' + product.id}>
        <div className="header">
          <div className="label">添加商品</div>
          <div className="pending"></div>
          <div className="opt" onClick={() => {
            removeProduct(index);
          }}>移除</div>
        </div>
        <div className='config-products'>
          {product.id ? <div className='config-product' onClick={() => {
            setProductIndex(index);
            setTimeout(() => {
              setProductsDialogVisible(true);
            }, 50)
          }}>
            <div className="main">
              <img className="cover" src={product.image} />
              <div>
                <div className="name">{product.title}</div>
                <div>￥{(product.price / 100).toFixed(2)}</div>
              </div>
            </div>
          </div> : <div className='add-product-btn highlight' onClick={() => {
            setProductIndex(index);
            setTimeout(() => {
              setProductsDialogVisible(true);
            }, 50)
          }}>
              <img src="/icn-add-grey-small@2x.png" />
              <span>添加商品</span>
            </div>}
        </div>
        <div></div>
      </div>, <div className="common-config-block" style={{
        marginTop: 20
      }} key={'cut-' + product.id}>
        <div className="header">
          <div className="label light">此商品最多可砍掉的金额</div>
        </div>
        <div className="config-item config-input-1">
          <InputNumber placeholder="请输入金额" formatter={(value) => {
            if (!value) {
              return '¥';
            }
            value = '' + value;
            const dotIndex = value.indexOf('.');
            if (dotIndex > -1) {
              value = value.slice(0, dotIndex + 3)
            }
            return `¥ ${value}`
          }} parser={(value) => {
            return value.replace(/¥\s?|(,*)/g, '');
          }} defaultValue={activeModule.formData.products[index].max_cut} onChange={(value) => {
            value = '' + value;
            const dotIndex = value.indexOf('.');
            if (dotIndex > -1) {
              value = value.slice(0, dotIndex + 3)
            }
            changeValue(`products[${index}].max_cut`, value)
          }} />
        </div>
      </div>]
    })}
    {<div className="common-config-btn" onClick={addProduct}>
      <img className="icn" src="/icn-add-big-green@3x.png" />
      <span className="desc">新增商品</span>
    </div>}
    <div className="common-config-block" style={{
      marginTop: 40
    }}>
      <div className="header">
        <div className="label">邀请好友裂变</div>
        <div className="pending"></div>
        <div className="tip">开启邀请不会影响最大砍价金额</div>
      </div>
      <div className="config-item config-label-select-1">
        <div className="label">分享裂变</div>
        <Select value={activeModule.formData.share_cnt} onChange={(value) => {
          changeValue(`share_cnt`, value)
        }}>
          <Option value={0}>不开启</Option>
          <Option value={activeModule.formData.share_cnt || 2}>开启邀请好友帮助砍价</Option>
        </Select>
      </div>
      {activeModule.formData.share_cnt !== 0 && <div className="config-item config-label-select-1">
        <div className="label">邀请人数</div>
        <Select value={activeModule.formData.share_cnt} onChange={(value) => {
          changeValue(`share_cnt`, value)
        }}>
          {shareParCntList.map((v, index) => {
            return <Option key={index} value={v}>最多可邀请 {v} 位好友</Option>
          })}
        </Select>
      </div>}
    </div>
    <div className="common-config-block" style={{
      marginTop: '40px'
    }}>
      <div className="header">
        <div className="label">活动结束时间</div>
      </div>
      <div className="config-item config-datetime">
        <DatePicker format="YYYY年MM月DD日 HH:mm" value={activeModule.formData.end_at ? moment(activeModule.formData.end_at) : ''} showTime onChange={(e) => {
          changeValue('end_at', e.format('YYYY-MM-DD HH:mm:ss'))
        }} />
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
    <GoodsSelector source={[1]} single={true} visible={isProductsDialogVisible} defaultGoods={[activeModule.formData.products[productIndex]]} onConfirm={(goods) => {
      if (goods && goods[0]) {
        const good = activeModule.formData.products[productIndex] || {};
        const isExist = _.find(activeModule.formData.products, (p, index) => {
          if (index !== productIndex && goods[0].id === p.id) {
            return true;
          }
          return false;
        })
        if (isExist) {
          message.error('不能添加重复的商品');
          return;
        }
        _.assign(good, goods[0])
        changeValue(`products[${productIndex}]`, good);
      }
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}
