import React, { useState } from "react";
import { useModules } from 'services/home';
import { Modal } from 'antd';

import './style.css';

import Banner from './Banner';
import AdWechat from './AdWechat';
import Notice from './Notice';
import Category from './Category';
import Goods from './Goods';
import Recommend from './Recommend';
import Lottery from './Lottery';
import Live from './Live';
import Coupon from './Coupon';
import ProductGroup from './ProductGroup';
import Video from './Video';
import Share from './Share';
import FlashCut from './FlashCut';
import Member from './Member';
import Discount from './Discount';

function renderModule(moduleCategory) {
  if (moduleCategory === 'banner') {
    return <Banner />
  } else if (moduleCategory === 'add_wechat') {
    return <AdWechat />
  } else if (moduleCategory === 'notice') {
    return <Notice />
  } else if (moduleCategory === 'category') {
    return <Category />
  } else if (moduleCategory === 'products') {
    return <Goods />;
  } else if (moduleCategory === 'recommend') {
    return <Recommend />
  } else if (moduleCategory === 'lottery') {
    return <Lottery />
  } else if (moduleCategory === 'live') {
    return <Live />;
  } else if (moduleCategory === 'coupon') {
    return <Coupon />
  } else if (moduleCategory === 'product_group') {
    return <ProductGroup />
  } else if (moduleCategory === 'video') {
    return <Video />
  } else if (moduleCategory === 'share') {
    return <Share />
  } else if (moduleCategory === 'flash_cut') {
    return <FlashCut />
  } else if (moduleCategory === 'member') {
    return <Member />
  } else if (moduleCategory === 'discount') {
    return <Discount />
  }
  return null;
}

export default function ModuleConfig() {
  const { activeModule, deleteModule, modules } = useModules();
  const [isDelModalVisible, setDelModalVisible] = useState(false);
  return <>
    {activeModule && <div className="module-config-panel">
      <div className="header">
        <img className="icon" src={activeModule.module.icn} />
        <span className="name">{activeModule.module.name}</span>
        <span className="pending"></span>
        <span className="del-btn" onClick={() => {
          setDelModalVisible(true);
        }}><img src="/icn-module-delete@3x.png"/>移除此模块</span>
      </div>
      <div className="body">
        {renderModule(activeModule.module.id)}
      </div>
    </div>}
    {modules.length === 0 && <div className="module-config-empty">
        <div className="desc">请从先从左侧添加模块后再进行配置</div>
        <img src="/icn-module-empty@3x.png"/>
    </div>}
    {modules.length !== 0 && !activeModule && <div className="module-config-empty">
        <div className="desc">请点击左侧的模块后再进行配置</div>
        <img src="/icn-module-empty@3x.png"/>
    </div>}
    <Modal title="操作提示" visible={isDelModalVisible} onOk={() => {
      deleteModule(activeModule.id)
      setDelModalVisible(false)
    }} onCancel={() => {
      setDelModalVisible(false)
    }}>
      <p>确认移除此模块？</p>
    </Modal>
  </>
}
