
import React, { useState } from "react";
import classnames from 'classnames';
import { Button, Select, Input, DatePicker, message } from 'antd'
import moment from 'moment';
import GoodsSelector from 'components/GoodsSelector';
import _ from 'lodash';
import { checkFormError, sendCoupon, rankMap } from 'services/member';


import './coupon.css';

const { Option } = Select;

const defaultFormData = {
  member_rank: '',
  coupon: {
    type: 2,
    name: '',
    discount_info: {

    },
    invalid_at: '',
    total_num: '',
    limit: 1
  }
};

export default function MemberCoupon({ isShow = false, onChange = () => { } }) {
  const [publishing, setPublish] = useState(false);
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);
  const [formData, setFormData] = useState(_.cloneDeep(defaultFormData))

  function changeForm(path, value) {
    const form = { ...formData };
    _.set(form, path, value);
    setFormData(form);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  function deleteProduct(productIndex) {
    const products = _.cloneDeep(formData.coupon.discount_info.products);
    products.splice(productIndex, 1);
    changeForm('coupon.discount_info.products', products);
  }

  async function publish(isPublish) {
    if (publishing) {
      return;
    }
    const data = {
      type: 1,
      data: _.cloneDeep(formData)
    }
    const error = checkFormError(data);
    if (error) {
      return message.error(error);
    }
    setPublish(true)
    try {
      delete data.data.coupon.limit;
      if (data.data.coupon.type === 3) {
        data.data.coupon.discount_info.cut = data.data.coupon.discount_info.cut / 10;
      }
      await sendCoupon(data);
      message.success('优惠券已发放');
      setFormData(_.cloneDeep(defaultFormData));
      onChange(false);
    } catch (e) {
      console.error(e);
      message.error(e.message);
    } finally {
      setPublish(false)
    }
  }

  return <div className={classnames('member-coupon', {
    'show': isShow
  })}>
    <div className="cover" onClick={() => {
      onChange(false);
    }}></div>
    <div className="config-block">
      <div className="module-config-panel">
        <div className="common-config-block">
          <div className="header">
            <div className="label">优惠券名称</div>
          </div>
          <div className="config-item config-input-1">
            <Input placeholder="输入名称" maxLength={10} value={formData.coupon.name} onChange={(e) => {
              changeForm('coupon.name', e.currentTarget.value)
            }} />
          </div>
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">优惠券类型</div>
          </div>
          <div className="config-item  config-label-select-1">
            <Select value={formData.coupon.type} onChange={(value) => {
              changeForm(`coupon.discount_info.cut`, undefined);
              changeForm(`coupon.discount_info.trigger`, undefined);
              changeForm('coupon.type', value);
            }}>
              <Option value={2}>满减</Option>
              <Option value={3}>折扣券</Option>
              <Option value={4}>直减券</Option>
            </Select>
          </div>
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">优惠详情</div>
          </div>
          {formData.coupon.type === 3 && <div className="config-item config-input-1">
            <Input
              type="number"
              min={1}
              max={9.9}
              prefix="打"
              suffix="折"
              value={formData.coupon.discount_info.cut}
              onChange={(e) => {
                let v = e.currentTarget.value;
                if (v) {
                  v = parseFloat(v);
                  if (isNaN(v)) {
                    v = "";
                  } else if (v > 9.9) {
                    v = 9.9;
                  } else {
                    v = Math.floor(v * 10) / 10
                  }
                }
                changeForm(`coupon.discount_info.cut`, v);
              }}
            />
          </div>}
          {formData.coupon.type === 2 && <div className="config-item config-input-2">
            <Input
              type="number"
              min={0}
              prefix="满"
              suffix="元"
              value={formData.coupon.discount_info.trigger}
              onChange={(e) => {
                let v = e.currentTarget.value;
                if (v) {
                  v = parseInt(v);
                  if (isNaN(v)) {
                    v = "";
                  }
                }
                changeForm(
                  `coupon.discount_info.trigger`,
                  v
                );
              }}
            />
            <Input
              type="number"
              min={0}
              prefix="减"
              suffix="元"
              value={formData.coupon.discount_info.cut}
              onChange={(e) => {
                let v = e.currentTarget.value;
                if (v) {
                  v = parseInt(v);
                  if (isNaN(v)) {
                    v = "";
                  }
                }
                changeForm(`coupon.discount_info.cut`, v);
              }}
            />
          </div>}
          {formData.coupon.type === 4 && <div className="config-item config-label-select-1">
            <Input
              type="number"
              min={0}
              prefix="直减"
              suffix="元"
              value={formData.coupon.discount_info.cut}
              onChange={(e) => {
                let v = e.currentTarget.value;
                if (v) {
                  v = parseInt(v);
                  if (isNaN(v)) {
                    v = "";
                  }
                }
                changeForm(`coupon.discount_info.cut`, v);
              }}
            />
          </div>}
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">发放总量</div>
          </div>
          <div className="config-item config-input-1">
            <Input type="number" placeholder="" maxLength={10} value={formData.coupon.total_num} suffix="张" onChange={(e) => {
              changeForm('coupon.total_num', parseInt(e.currentTarget.value))
            }} />
          </div>
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">优惠券失效日期</div>
          </div>
          <div className="config-item config-datetime">
            <DatePicker format="YYYY年MM月DD日 HH:mm" value={formData.coupon.invalid_at ? moment(formData.coupon.invalid_at) : ''} showTime onChange={(e) => {
              changeForm('coupon.invalid_at', e.format('YYYY-MM-DD HH:mm:ss'))
            }} />
          </div>
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">使用限制</div>
          </div>
          <div className="config-item  config-label-select-1">
            <Select value={formData.coupon.limit} onChange={(value) => {
              changeForm('coupon.limit', value);
              if (value === 1) {
                changeForm(`coupon.discount_info.products`, undefined);
              } else {
                changeForm(`coupon.discount_info.products`, []);
              }
            }}>
              <Option value={1}>全部商品可用</Option>
              <Option value={2}>指定商品可用</Option>
            </Select>
          </div>
        </div>
        <div className="common-config-block">
          <div className="header">
            <div className="label">选择要发送的分组</div>
          </div>
          <div className="config-item  config-label-select-1">
            <Select value={formData.member_rank || ''} defaultValue="" onChange={(value) => {
              changeForm(`member_rank`, value);
            }}>
              <Option value="">全部</Option>
              <Option value="LEVEL1">{rankMap.LEVEL1}</Option>
              <Option value="LEVEL2">{rankMap.LEVEL2}</Option>
              <Option value="LEVEL3">{rankMap.LEVEL3}</Option>
            </Select>
          </div>
        </div>
        {formData.coupon.limit === 2 && <div className="common-config-block">
          <div className="header">
            <div className="label">绑定可用的商品</div>
          </div>
          <div className='config-products'>
            {formData.coupon.discount_info.products.map((value, productIndex) => (
              <div className={classnames('config-product')}>
                <div className="main">
                  <img className="cover" src={value.image} />
                  <div className="name">{value.title}</div>
                </div>
                <img className="del-icon highlight" src="/icn-delete-popup@3x.png" onClick={() => {
                  deleteProduct(productIndex)
                }} />
              </div>
            ))}
            <div className='add-product-btn highlight' onClick={() => {
              setProductsDialogVisible(true);
            }}>
              <img src="/icn-add-grey-small@2x.png" />
              <span>添加商品</span>
            </div>
          </div>
        </div>}
      </div>
      <div className="footer">
        <Button className="opt" onClick={() => {
          onChange(false)
        }}>取消</Button>
        <Button className="opt primary" type="primary" loading={publishing} onClick={publish}>确认发放</Button>
      </div>
    </div>
    <GoodsSelector visible={isProductsDialogVisible} defaultGoods={_.get(formData, 'coupon.discount_info.products', [])} onConfirm={(goods) => {
      changeForm(`coupon.discount_info.products`, goods)
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}