import {useCallback} from "react";
import { message } from "antd";

export function singleExec (func, hooks = {}) {
  let calling = false;
  return async function call(...args) {
    if (calling) {
      return;
    }
    calling = true;
    try {
      const resp = await func.apply(this, args);
      hooks.onSuccess && hooks.onSuccess.call(this);
      return resp;
    } catch (e) {
      console.error(e);
      message.error(e.message);
      hooks.onError && hooks.onError.call(this, e);
    } finally {
      calling = false;
      hooks.onComplete && hooks.onComplete.call(this);
    }
  }
}


export function useSingleExec(func, hooks, deep = []) {
  return useCallback(singleExec(func, hooks), deep);
}