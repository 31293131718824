import React from "react";
import classnames from 'classnames';

import './style.css';
import liveIcon from '../images/icn-live@3x.png';

export default function Live({ module, onClick }) {
  return <div className="preview-live" onClick={onClick}>
    <img className="img" src={liveIcon}/>
    <div className={classnames('preview-active-tag', {
      show: module.active
    })}>
      <div className="content">配置中</div>
      <div className="arrow"></div>
    </div>
  </div>
}
