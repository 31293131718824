import React from "react";
import classnames from 'classnames';
import {amountStr} from 'utils/tools';

import './style.css';
import recommendIcon from '../images/icn-recommend@3x.png'
import cartIcon from '../images/icn-toolbar-cart@3x.png';
import rankRedIcon from '../images/icn-rank-red-bg@3x.png';
import rankGreenIcon from '../images/icn-rank-grey-bg@3x.png';


export default function Recommend({ module }) {
  const formData = module.formData || {};
  const { type, title = '热销推荐', products = [], desc = '超值商品，精选推荐', hide_icon = false, style = {} } = formData;

  if (type === 1) {
    return <div className="preview-recommend" style={{
      backgroundColor: style.background_color
    }}>
      <div className="header">
        <span className="label">{title}</span>
        {/* <span className="desc">{desc}</span> */}
      </div>
      <div className="list">
        {products.length === 0 && <img src="/icn-recommend-default-2@3x.png" style={{
          width: '355px'
        }}/>}
        {products.map((item, index) => {
          return <div className={classnames('item', {
            big: item.set_big
          })} key={index}>
            <img className="cover" src={item.image} />
            <div className="name">{item.title}</div>
            <div className="pending"></div>
            <div className="price">￥{amountStr(item.price)}</div>
            {!hide_icon && <img src={recommendIcon} className="recommend-icn" />}
          </div>
        })}
      </div>
    </div>
  }

  if (type === 2) {
    return <div className="preview-recommend-rank" style={{
      backgroundColor: style.background_color
    }}>
      <div className="header">
        <span className="label">{title}</span>
        {/* <span className="desc">{desc}</span> */}
      </div>
      <div className="list">
        {products.length === 0 && <img style={{
          width: '336px'
        }} src="/icn-recommend-default-1@3x.png"/>}
        {products.map((item, index) => {
          return <>
            <div className="item" key={`prod-${index}`}>
              <img className="cover" src={item.image} mode="aspectFill" />
              <div className="content">
                <div className="name">{item.title}</div>
                {item.tags && item.tags.length > 0 && <div className="tags">
                  {item.tags.map((item, index) => {
                    return <div className="tag" key={index}>{item}</div>
                  })}
                </div>}
                <div className="pending"></div>
                <div className="p">
                  <div className="price">￥{amountStr(item.price)}</div>
                  <div className="pending"></div>
                  <img className="cart-icn" src={cartIcon} />
                </div>
              </div>
              {index < 10 && <div className="rank">
                {index < 3 ? <img className="icn" src={rankRedIcon} /> : <img className="icn" src={rankGreenIcon} />}
                <div className="value">{index + 1}</div>
              </div>}
            </div>
            <div className="split" key={`split-${index}`}></div>
          </>
        })}
      </div>
    </div>
  }

  return 'not support';
}
