import React, { useState } from "react";
import moment from 'moment';
import { Table, Popconfirm, Select } from "antd";
import { useLotteryRecords } from 'services/home';

import './lottery.css';

import analyseIcon from './icn-lottery-statistics.svg';

const { Option } = Select;

export default function ActivityLotteryRecord() {
  const lotteryRecords = useLotteryRecords();

  const columns = [{
    title: '参与时间',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => {
      return moment(date).format('YYYY-MM-DD HH:mm')
    }
  }, {
    title: '昵称',
    dataIndex: 'nickname',
    key: 'nickname',
    render: (nickname, record, r) => {
      return <div className="user">
        <img className="avatar" src={record.avatar}/>
        <span className="name">{nickname}</span>
      </div>;
    }
  }, {
    title: '奖品名称',
    dataIndex: 'prize',
    key: 'prize',
    render: (prize) => {
      return prize ? prize.name : '';
    }
  }, {
    title: '兑奖方式',
    dataIndex: 'redeem_info',
    key: 'redeem_info',
    render: (redeem_info, record) => {
      if (!record.prize) {
        return '';
      }
      if (redeem_info) {
        return `兑换码 ${redeem_info.code}`
      }
      return '发放优惠券'
    }
  }, {
    title: '操作',
    key: 'action',
    render: (text, record) => {
      if (!record.redeem_info) {
        return '';
      }
      return <div className="action-block">
        {record.redeem_info.state === 1 ? <Popconfirm title="确认兑换" okText="是" cancelText="否" onConfirm={(e) => {
          e.stopPropagation();
          lotteryRecords.redeemLottery(record.id)
        }}><a>标为已兑换</a></Popconfirm> : <span style={{color: 'rgba(0,0,0,0.66)'}}>已兑换</span>}
      </div>
    }
  }]

  return <div className="activity-lottery-record">
    <div className="content">
      <div className="overview">
        <img src={analyseIcon} className='analyse-icn'/>
        <h2 className="">抽奖统计</h2>
        <div className="count total-count">
          <span className="label">累计参与抽奖</span>
          <span className="value">{lotteryRecords.statistics.total_count}</span>
        </div>
        <div className="count win-count">
          <span className="label">累计中奖</span>
          <span className="value">{lotteryRecords.statistics.win_count}</span>
        </div>
        <span className="pending"></span>
        <div className="type-select">
          <Select defaultValue="win"  bordered={false} onChange={(v) => {
            lotteryRecords.setType(v);
          }}>
            <Option value="win">仅显示中奖用户</Option>
            <Option value="all">显示全部参与用户</Option>
          </Select>
        </div>
      </div>
      <div className="records">
        <Table columns={columns} loading={lotteryRecords.loading} dataSource={lotteryRecords.records} pagination={lotteryRecords.pagination} onChange={(p) => {
          lotteryRecords.loadPage(p.current)
        }}/>
      </div>
    </div>
  </div>
}