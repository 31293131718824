import React from 'react';
import './layout.css';

import Header from 'components/Header';

function BasicLayout(props) {
    const { children } = props;
    return (
        <div className="basic-layout">
            <div className="basic-layout-header">
                <Header />
            </div>
            <div className="basic-layout-body">
                {children}
            </div>
        </div>
    );
}


export default BasicLayout;