import React, { useState } from "react";
import _ from 'lodash';
import { useModules } from 'services/home';
import classnames from 'classnames';
import { Input, Select, Dropdown, Button } from 'antd';
import './style.css';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import GoodsSelector from 'components/DiscountGoodsSelector';

const { Option } = Select;
const { TextArea } = Input;

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

const SortableItem = sortableElement(({ value, idx, type, onDelete, toggleGoodBig }) => {
  return <div className={classnames('config-product')}>
    <div className="main">
      <img className="cover" src={value.image} />
      <div className="good-info">
        <div className="name">{value.title}</div>
        <div className="price">
          <span className="discount-price" style={{
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.9)'
          }}>￥{(value.discount_price / 100).toFixed(2)}</span>
          <span className="origin-price" style={{
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.35)',
            textDecoration: 'line-through',
            marginTop: -1,
            marginLeft: 4
          }}>￥{(value.origin_price / 100).toFixed(2)}</span>
        </div>
      </div>
    </div>
    <img className="del-icon highlight" src="/icn-delete-popup@3x.png" onClick={() => {
      onDelete(idx);
    }} />
  </div>
});


export default function Discount() {
  const { activeModule, changeModule } = useModules();
  const [isProductsDialogVisible, setProductsDialogVisible] = useState(false);

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    let products = _.cloneDeep(activeModule.formData.products);
    products = arrayMove(products, oldIndex, newIndex)
    changeValue('products', products);
  }

  function deleteProduct(index) {
    activeModule.formData.products.splice(index, 1);
    changeValue('products', activeModule.formData.products);
  }

  function closeProductsDialog() {
    setProductsDialogVisible(false);
  }

  return <div className="config-discount">
    <a className="config-discount-desc" target="_blank" href="https://mp.weixin.qq.com">此处仅做展示，可前往小商店后台「限时抢购」进行管理</a>
    <div className="common-config-block" style={{
      marginTop: 30
    }}>
      <div className="header">
        <div className="label light">添加商品（拖动可排序）</div>
      </div>
      <div className='config-products'>
        <SortableContainer onSortEnd={onSortEnd} distance={1} >
          {activeModule.formData.products.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} idx={index} value={value}  onDelete={deleteProduct}/>
          ))}
        </SortableContainer>
        <div className='add-product-btn highlight' onClick={() => {
          setProductsDialogVisible(true);
        }}>
          <img src="/icn-add-grey-small@2x.png" />
          <span>添加商品</span>
        </div>
      </div>
    </div>
    <div className="common-column-2" style={{
      marginTop: 30
    }}>
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.module_color} onChange={(color) => {
            changeValue(`style.module_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.module_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
    <GoodsSelector visible={isProductsDialogVisible} defaultGoods={activeModule.formData.products} onConfirm={(goods) => {
      changeValue(`products`, goods)
      closeProductsDialog();
    }} onClose={closeProductsDialog}></GoodsSelector>
  </div>
}
