import {useState, useEffect, useCallback} from 'react';
import _, { findIndex } from 'lodash';
import request from './request';
import {useSingleExec} from 'utils/functional';
import moment from 'moment';

const MemberPageSize = 10;

export async function getMembers(page) {
  return request.post(`/decoration/admin/member/get_list`, {
    page,
    page_size: MemberPageSize
  });
}

export async function manageMember(id, data) {
  return request.post(`/decoration/admin/member/${id}/manage`, data);
}

export async function sendCoupon(data) {
  return request.post(`/decoration/admin/member/coupon`, data);
}

export async function invalidCoupon(id) {
  return request.post(`/decoration/admin/member_right/coupon/invalid`, {
    id
  });
}

export async function getMemberCoupons() {
  return request.get('/decoration/admin/member/coupon').then((resp) => {
    resp.data.data.forEach((item) => {
      if (item.type === 3) {
        item.cut = (item.cut * 10).toFixed(1);
      }
    })
    return resp;
  });
}

export async function removeMemberCouponTask(id) {
  return request.post(`/decoration/admin/member/coupon/${id}/del`);
}

export async function getMemberCouponTask(id) {
  return request.get(`/decoration/admin/member/coupon/${id}`).then((resp) => {
    if (_.get(resp.data, 'data.data.coupon.discount_info.products', []).length === 0) {
      _.set(resp.data, 'data.data.coupon.limit', 1)
    } else {
      _.set(resp.data, 'data.data.coupon.limit', 2)
    }
    if (_.get(resp.data, 'data.data.coupon.type') === 3) {
      resp.data.data.data.coupon.discount_info.cut = (resp.data.data.data.coupon.discount_info.cut * 10).toFixed(1);
    }
    return resp;
  });
}

export async function updateMemberCouponTask(id, data) {
  return request.post(`/decoration/admin/member/coupon/${id}/update`, data);
}

export const rankMap = {
  LEVEL1: "普卡",
  LEVEL2: "银卡",
  LEVEL3: "金卡"
}

export function useMembers() {
  const [records, setRecords] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: MemberPageSize,
    position: ['none', 'bottomCenter'],
    total: 0
  })
  const [loading, setLoading] = useState(false);

  const loadPage = useSingleExec(async function (page) {
    setLoading(true);
    const resp = await getMembers(page);
    setRecords(_.get(resp.data, 'data', []));
    setPagination({
      ...pagination,
      current: _.get(resp.data, 'meta.page', []),
      total: _.get(resp.data, 'meta.total', 0),
    })
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [pagination]);

  useEffect(() => {
    loadPage(1)
  }, []);

  const agreenApply = useSingleExec(async function (id) {
    const _records = [...records];
    const record = _records.find((r) => {
      return r.id === id;
    })
    if (!record) {
      return;
    }
    if (record.state === 1) {
      return;
    }
    await manageMember(id, {
      type: 1
    });
    record.state = 1;
    setRecords(_records);
  }, {
  }, [records]);

  const ignoreApply = useSingleExec(async function (id) {
    const _records = [...records];
    const recordIndex = _records.findIndex((r) => {
      return r.id === id;
    })
    const record = _records[recordIndex];
    if (!record) {
      return;
    }
    if (record.state === 1) {
      return;
    }
    await manageMember(id, {
      type: 2
    });
    _records.splice(recordIndex, 1);
    setRecords(_records);
  }, {
  }, [records]);

  const removeMember = useSingleExec(async function (id) {
    const _records = [...records];
    const recordIndex = _records.findIndex((r) => {
      return r.id === id;
    })
    const record = _records[recordIndex];
    if (!record) {
      return;
    }
    if (record.state === 0) {
      return;
    }
    await manageMember(id, {
      type: 4
    });
    _records.splice(recordIndex, 1);
    setRecords(_records);
  }, {
  }, [records]);

  const renew = useSingleExec(async function (id, days) {
    const _records = [...records];
    const record = _records.find((r) => {
      return r.id === id;
    })
    if (!record) {
      return;
    }
    if (record.state === 0) {
      return;
    }
    await manageMember(id, {
      type: 3,
      data: days
    });
    record.expired_at = moment(record.expired_at).add(days, 'd').toISOString();
    setRecords(_records);
  }, {
  }, [records]);

  const changeLevel = useSingleExec(async function (id, level) {
    const _records = [...records];
    const record = _records.find((r) => {
      return r.id === id;
    })
    if (!record) {
      return;
    }
    await manageMember(id, {
      type: 5,
      data: level
    });
    record.rank_key = level;
    record.rank_name = rankMap[level];
    setRecords(_records);
  }, {
  }, [records]);

  return {
    records,
    loadPage,
    setRecords,
    agreenApply,
    ignoreApply,
    removeMember,
    changeLevel,
    renew,
    pagination,
    loading,
  };
}

export function checkFormError(form) {
  let error = '';
  if (form.type === 1) {
    if (!form.data.coupon.total_num) {
      error = '请输入优惠券发放总量';
    }
    if (!form.data.coupon.invalid_at) {
      error = '请选择优惠券失效日期';
    } else if (moment(form.data.coupon.invalid_at) <= moment()) {
      error = '优惠券失效日期需大于当前时间';
    }
  }
  if (form.type === 2) {
    if (!form.data.coupon.total_num) {
      error = '请输入每次发放总量';
    }
    if (!form.data.start_at) {
      error = '请选择初次发放日期';
    }
    if (!form.data.end_at) {
      error = '请选择截止发放日期';
    } else if (moment(form.data.end_at) <= moment()) {
      error = '截止发放日期需大于当前时间';
    }
    if (form.data.start_at && form.data.end_at) {
      if (moment(form.data.start_at) >= moment(form.data.end_at)) {
        error = '截止发放日期需大于初次发放日期';
      }
    }
  }
  if (form.data.coupon.limit === 2 && form.data.coupon.discount_info.products.length === 0) {
    error = '请添加商品';
  }
  if (!form.data.coupon.name) {
    error = '请输入优惠券名称';
  }
  if (form.data.coupon.type === 3) {
    if (!form.data.coupon.discount_info.cut) {
      error = '请设置 * 折'
    }
  }
  if (form.data.coupon.type === 2) {
    if (!form.data.coupon.discount_info.trigger) {
      error = '请设置满 * 元';
    }
    if (!form.data.coupon.discount_info.cut) {
      error = '请设置减 * 元'
    }
  }
  if (form.data.coupon.type === 4) {
    if (!form.data.coupon.discount_info.cut) {
      error = '请设置直减 * 元'
    }
  }
  return error;
}

export function useMemberCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [activeId, setActiveId] = useState('');
  const [coupon, setCoupon] = useState(null);
  const [isFetch, setFetch] = useState(false);

  const load = useSingleExec(async function (page) {
    const resp = await getMemberCoupons();
    setCoupons(_.get(resp.data, 'data', []));
    return resp.data.data;
  }, {

  }, []);

  useEffect(() => {
    load().then((list) => {
      if (list[0]) {
        changeActiveId(list[0].id);
      }
    })
  }, []);

  const changeActiveId = useSingleExec(async (id) => {
    if (!id) {
      setActiveId('');
      setCoupon(null);
      return;
    }
    setFetch(true)
    setActiveId(id);
    try {
      const coupon = (await getMemberCouponTask(id)).data.data;
      setCoupon(coupon);
    } catch (e) {

    } finally {
      setFetch(false)
    }
  }, {}, [activeId])

  return {
    coupons,
    load,
    setCoupons,
    changeActiveId,
    activeId,
    setActiveId,
    coupon,
    isFetch
  }
}

const LOAD_COUPONS_PAGE_SIZE = 8;

export async function getSendedCouponsList(query) {
  return request.post(`/decoration/admin/member_right/coupon/get_list`, query)
}

export function useSendedCoupons() {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const loadCoupons = useSingleExec(async function (targetPage) {
    if (!hasMore && targetPage > page) {
      return;
    }
    setLoading(true);
    const resp = await getSendedCouponsList({
      page: targetPage,
      page_size: LOAD_COUPONS_PAGE_SIZE
    });
    setCoupons(resp.data.data)
    setPage(targetPage);
    if (resp.data.data.length < LOAD_COUPONS_PAGE_SIZE) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, {
    onComplete: () => {
      setLoading(false);
    }
  }, [hasMore, page, loading]);

  async function updateCoupons() {
    await loadCoupons(1);
  }

  const nextCoupons = useSingleExec(async function () {
    await loadCoupons(page + 1);
  }, {
   
  }, [page, hasMore]);

  const prevCoupons = useSingleExec(async function () {
    if (page - 1 < 1) {
      return;
    }
    await loadCoupons(page - 1);
  }, {
   
  }, [page]);

  useEffect(() => {
    loadCoupons(1);
  }, []);

  const invalid = useSingleExec(async function (id) {
    const _coupons = [...coupons];
    const coupon = _coupons.find((r) => {
      return r.id === id;
    })
    if (!coupon) {
      return;
    }
    await invalidCoupon(id);
    coupon.state = -1;
    setCoupons(_coupons);
  }, {
  }, [coupons]);

  return {
    coupons,
    loadCoupons,
    nextCoupons,
    prevCoupons,
    updateCoupons,
    invalid,
    loading,
    page,
    hasMore
  };
}