import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { useBeforeUnload } from 'react-use';
import {Link, useHistory} from "react-router-dom";
import _ from 'lodash';
import arrayMove from 'array-move';
import './style.css';

import { Button, message } from 'antd';
import { setActiveModule } from "services/module";
import { useModules } from 'services/home';
import { useShopInfo } from "services/user";
import {useServiceStatus} from 'services/store';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import Banner from './Banner';
import AdWechat from './AdWechat';
import Notice from './Notice';
import Category from './Category';
import Goods from './Goods';
import Recommend from './Recommend';
import Lottery from './Lottery';
import Live from './Live';
import Coupon from './Coupon';
import ProductGroup from './ProductGroup';
import Video from './Video';
import Share from './Share';
import FlashCut from './FlashCut';
import Member from './Member';
import Discount from './Discount';

const SortableItem = sortableElement(({ value, idx, onClick, addModule }) => {
  const [dragTarget, setDragTarget] = useState('');
  const moduleCategory = value.module.id;

  function renderModule() {
    if (moduleCategory === 'banner') {
      return <Banner module={value} />
    } else if (moduleCategory === 'add_wechat') {
      return <AdWechat module={value} />
    } else if (moduleCategory === 'notice') {
      return <Notice module={value} />
    } else if (moduleCategory === 'category') {
      return <Category module={value} />
    } else if (moduleCategory === 'products') {
      return <Goods module={value} />;
    } else if (moduleCategory === 'recommend') {
      return <Recommend module={value} />
    } else if (moduleCategory === 'lottery') {
      return <Lottery module={value} />
    } else if (moduleCategory === 'live') {
      return null;
    } else if (moduleCategory === 'coupon') {
      return <Coupon module={value} />
    } else if (moduleCategory === 'product_group') {
      return <ProductGroup module={value}/>
    } else if (moduleCategory === 'video') {
      return <Video module={value}/>
    } else if (moduleCategory === 'share') {
      return null;
    } else if (moduleCategory === 'flash_cut') {
      return <FlashCut module={value}/>
    } else if (moduleCategory === 'member') {
      return <Member module={value}/>
    } else if (moduleCategory === 'discount') {
      return <Discount module={value}/>
    }
    return moduleCategory;
  }

  function onDragEnter(ev) {
    ev.preventDefault();
    const { target } = ev.currentTarget.dataset;
    if (target !== dragTarget) {
      setDragTarget(target);
    }
  }

  function onDragLeave(ev) {
    ev.preventDefault();
    setDragTarget('');
  }

  function onDrop(ev) {
    if (moduleCategory === 'products' && dragTarget === 'after') {
      ev.preventDefault();
      setDragTarget('');
      message.warn('全部商品模块必须置底');
      return;
    }
    let dragModule = ev.dataTransfer.getData("drag_module");
    if (dragModule) {
      dragModule = JSON.parse(dragModule);
      let addIndex = -1;
      if (dragTarget === 'before') {
        addIndex = idx;
      } else {
        addIndex = idx + 1;
      }
      addModule(dragModule.id, Math.max(addIndex, 0));
    }
    setDragTarget('');
  }

  return <div tabIndex={idx} className={classnames('module', `module-${value.id}`, {
    active: value.active,
    activeModule: value.active
  })} onClick={() => {
    onClick(idx)
  }}>
    {renderModule()}
    <div className={classnames('drop-before', {
      active: dragTarget === 'before'
    })} data-target="before" onDragOver={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
    </div>
    <div className={classnames('drop-after', {
      active: dragTarget === 'after'
    })} data-target="after" onDragOver={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
    </div>
  </div>
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});


export default function ModulePreview({inEditPage}) {
  const history = useHistory();
  const shop = useShopInfo();
  const { modules, setModules, activeModule, addModule, template } = useModules();
  const [ addModuleTipVisiable, setAddModuleTipVisiable] = useState(false);
  const activeTagRef = useRef(null);
  const { info } = useServiceStatus();
  const isEmpty = modules.length === 0;
  const showFeedback = _.get(info, 'feedback_tip', false);

  const hasProductsModule = modules.findIndex((item) => {
    return item.module.id === 'products'
  }) !== -1;

  const liveModule = modules.find((item) => {
    return item.module.id === 'live'
  });

  const shareModule = modules.find((item) => {
    return item.module.id === 'share'
  })

  useEffect(() => {
    if (activeModule) {
      scrollToModule(activeModule.id)
    }
  }, [
    _.get(activeModule, 'id', '_')
  ])

  useBeforeUnload(!!activeModule && window._settingChanged, '你还有变更未保存并发布，确认关闭？');

  function chooseModule(id) {
    setActiveModule(modules, id);
    setModules(modules);
    setActiveTag(id)
  }

  function onSortEnd({ oldIndex, newIndex }) {
    if (hasProductsModule) {
      if (oldIndex !== modules.length - 1 && newIndex === modules.length - 1) {
        return message.warn('全部商品模块必须置底');
      }
      if (oldIndex === modules.length - 1 && newIndex !== oldIndex) {
        return message.warn('全部商品模块必须置底');
      }
    }
    let _modules = _.cloneDeep(modules);
    _modules = arrayMove(_modules, oldIndex, newIndex)
    setModules(_modules);
    setActiveTag(_.get(activeModule, 'id'), true)
  }

  function onPhoneScroll(e) {
    if (!activeModule) {
      return;
    }

    const activeModuleEle = document.getElementsByClassName('activeModule')[0];
    if (!activeModuleEle) {
      return;
    }
    const scrollTop = e.currentTarget.scrollTop;
    const containerHeight = parseFloat(window.getComputedStyle(e.currentTarget).height)
    let tagOffsetTop = Math.min(Math.max(activeModuleEle.offsetTop - scrollTop, 50), containerHeight + 60);
    if (!showFeedback) {
      tagOffsetTop = Math.min(Math.max(activeModuleEle.offsetTop - scrollTop, 0), containerHeight);
    }
    activeTagRef.current.style.top = `${tagOffsetTop}px`;
  }

  function setActiveTag(id, disableScroll) {
    if (!id) {
      return;
    }
    const activeModuleEle = document.getElementsByClassName(`module-${id}`)[0];
    const scrollTop = document.getElementsByClassName('app-module-preview')[0].scrollTop;
    const tagOffsetTop = activeModuleEle.offsetTop - scrollTop;
    activeTagRef.current.style.top = `${tagOffsetTop}px`;
    if (!disableScroll) {
      scrollToModule(id);
    }
  }

  function scrollToModule(id) {
    const module = modules.find((m) => {
      return m.id === id;
    })
    if (module && (module.module.id === 'live' || module.module.id === 'share')) {
      return;
    }
    const activeModuleEle = document.getElementsByClassName(`module-${id}`)[0];
    const prevEle = document.getElementsByClassName('app-module-preview')[0];
    if (prevEle.scroll) {
      prevEle.scroll({
        top: activeModuleEle.offsetTop - (showFeedback ? 62 : 0),
        behavior: 'smooth'
      })
    } else {
      prevEle.scrollTop = activeModuleEle.offsetTop;
    }
  }

  function showAddModuleTip() {
    setAddModuleTipVisiable(true);
  }

  return <>
    <div className={classnames("app-module-preview", {
      empty: !template.id,
      'show-feedback': showFeedback
    })} onScroll={onPhoneScroll}>
      {inEditPage && <div className="header">
        <div className='shop'>
          <span>{template.title}</span>
        </div>
      </div>}
      {!inEditPage && <div className="header">
        <div className='shop'>
          <img alt="" src={shop.avatar} />
          <span>{shop.nickname}</span>
        </div>
        <div className='pending'></div>
        {!isEmpty && <div className="tip">拖拽模块可排序</div>}
      </div>}
      {!template.id && <>
        <div className="create-tip">请先从模板中心创建一个页面</div>
        <div className='addBtn' style={{
          marginTop: 30
        }} onClick={() => {
          history.push('/templates')
        }}>
          <div
            className='block'
          >
            <img className='addIcn' src="/icn-add-grey@3x.png" />
            <div className='desc'>去创建</div>
          </div>
        </div>
      </>}
      {isEmpty && template.id && <div className="steps">
        <h2>装修流程</h2>
        <img src="/steps@2x.png" />
      </div>}
      <div className='modules'>
        <SortableContainer onSortEnd={onSortEnd} distance={1} axis="y" lockAxis="y">
          {modules.map((value, index) => (
            <SortableItem key={value.id} index={index} idx={index} total={modules.length} value={value} addModule={addModule} onClick={() => {
              chooseModule(value.id)
            }} />
          ))}
        </SortableContainer>
      </div>
      {template.id && modules.length === 0 && <div className='addBtn' onClick={showAddModuleTip}>
        <div
          className='block'
        >
          <img className='addIcn' src="/icn-add-grey@3x.png" />
          <div className='desc'>添加模块</div>
          <div className='tip'>
            <img src="/icn-guide-line@3x.png" />
            <div className='tipDesc'>点此开始添加</div>
          </div>
        </div>
      </div>}
      <div style={{
        height: 100
      }}></div>
    </div>
    {liveModule && <Live module={liveModule} onClick={() => {
      chooseModule(liveModule.id)
    }} />}
    {shareModule && <Share  module={shareModule} onClick={() => {
      chooseModule(shareModule.id)
    }}/>}
    <div ref={activeTagRef} onClick={() => {
      scrollToModule(activeModule.id);
    }} className={classnames('preview-active-tag', {
      show: !!activeModule && activeModule.module.id !== 'live' && activeModule.module.id !== 'share'
    })}>
      <div className="content">配置中</div>
      <div className="arrow"></div>
    </div>
    {addModuleTipVisiable && modules.length === 0 && ReactDOM.createPortal(<div className='add-module-tip headShake'>
      <div className="content">点击或拖拽模块至右侧</div>
      <div className="arrow"></div>
    </div>, document.getElementsByClassName('app-module-selector')[0])}
  </>;
}
