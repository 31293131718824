import React, { useRef } from "react";
import classnames from 'classnames';
import styled from 'styled-components'

import './style.css';
import notice1Icon from '../images/icn-notice-1@3x.png';
import noticeIcon from '../images/icn-notice@3x.png';

export default function Notice({ module }) {
  const scollContainerRef = useRef(null)
  const scrollContentRef = useRef(null);
  const formData = module.formData || {};
  const { content, style, module_style = {} } = formData;

  let containerWidth = 370;
  let contentWidth = 370;
  if (scollContainerRef.current) {
    containerWidth = parseFloat(getComputedStyle(scollContainerRef.current).width);
  }
  if (scrollContentRef.current) {
    contentWidth = Math.max(parseFloat(getComputedStyle(scrollContentRef.current).width), containerWidth);
  }
  const moveLen = contentWidth + contentWidth + 20;
  const duration = moveLen / 120;
  const ScrollContainer = styled.div`
      padding: 0px  20px;
      white-space: nowrap;
      position: relative;
      left: 100%;
      animation-fill-mode: forwards;
      animation: scrollAnimate ${duration}s linear infinite;
      @keyframes scrollAnimate {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-${moveLen}px);
        }
    } 
    `;

  if (style === 'style1') {
    return <div className="preview-notice-1" style={{
      backgroundColor: module_style.background_color
    }}>
      <div className="item">
        <div className="content">
          <img className="icn" src={notice1Icon} />
          <div className="desc">{content}</div>
        </div>
      </div>
    </div>
  }

  if (style === 'scroll') {
    return <div className="preview-notice-scroll"  style={{
      backgroundColor: module_style.background_color
    }}>
      <div className="content" ref={scollContainerRef}>
        <ScrollContainer><div className="desc" ref={scrollContentRef}>{content}</div></ScrollContainer>
      </div>
    </div>
  }

  return <div className="preview-notice" style={{
    backgroundColor: module_style.background_color
  }}>
    <div className="item">
      <div className="content">
        <img className="icn" src={noticeIcon} />
        <div className="desc">{content}</div>
      </div>
    </div>
  </div>
}
