import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash';
import { useModules } from 'services/home';
import {
  Input,
  Select,
  DatePicker,
  message,
  InputNumber,
  Collapse,
  Dropdown, Button
} from "antd";
import GoodsSelector from 'components/GoodsSelector';
import { DownOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';

import './style.css';

const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;

function newDefaultProduct() {
  return {
    max_cut: 0
  };
}

export default function Member() {
  const { activeModule, changeModule } = useModules();

  const shareParCntList = [2]

  if (!activeModule) {
    return null;
  }

  function changeValue(path, value) {
    const formData = activeModule.formData;
    _.set(formData, path, value);
    changeModule(activeModule);
  }

  return <div className="config-member">
    <Collapse
      bordered={false}
      className="site-collapse-custom-collapse"
    >
      <Panel header="查看使用介绍" key="1" className="site-collapse-custom-panel">
        <p>开启会员模块后，用户可以申请并成为会员，您可以在「会员管理」页面创建会员权益，当前支持：定期或手动发放优惠券，后面会持续开发更多能力。</p>
        <p>权益发放之后，用户可在会员中心查看并领取对应权益。</p>
      </Panel>
    </Collapse>
    <div className="common-config-block">
      <div className="header">
        <div className="label">引导文案</div>
      </div>
      <div className="config-item config-input-1">
        <Input placeholder="请输入引导文案" value={activeModule.formData.title} onChange={(e) => {
          changeValue('title', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-config-block" >
      <div className="header">
        <div className="label">权益描述</div>
      </div>
      <div className="config-item config-textarea">
        <TextArea style={{ maxWidth: 360 }} placeholder="· 描述权益内容，多个权益可用回车区分" allowClear value={activeModule.formData.desc} autoSize={{
          minRows: 4
        }} onChange={(e) => {
          changeValue('desc', e.currentTarget.value)
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">会员有效期</div>
      </div>
      <div className="config-item config-input-1">
        <InputNumber placeholder="输入天数" precision={0} min={1} value={activeModule.formData.valid_day} onChange={(v) => {
          changeValue('valid_day', v)
        }} />
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">会员申请是否需要确认</div>
      </div>
      <div className="config-item config-select-item">
        <Select value={activeModule.formData.auto_pass} onChange={(value) => {
          changeValue(`auto_pass`, value)
        }}>
          <Option value={true}>无需确认，自动通过</Option>
          <Option value={false}>需要我确认后才会通过</Option>
        </Select>
      </div>
    </div>
    <div className="common-config-block">
      <div className="header">
        <div className="label">权益管理</div>
      </div>
      <div className="config-item">
        <a className="config-member-mamage-item" target="_blank" href="/member">会员权益与管理</a>
      </div>
    </div>
    <div className="common-column-2">
      <div className="common-config-block">
        <div className="header">
          <div className="label">模块背景色</div>
        </div>
        <div className="config-item">
          <Dropdown overlay={<ChromePicker color={activeModule.formData.style.background_color} onChange={(color) => {
            changeValue(`style.background_color`, color.hex);
          }}/>}>
            <Button>
              <span style={{marginRight: 40}}>{activeModule.formData.style.background_color}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
}
